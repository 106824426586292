import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Card,
  Col,
  Input,
  List,
  Row,
  Skeleton,
  Switch,
  Tag,
} from 'antd';
import { useState } from 'react';
import Text from 'antd/es/typography/Text';
import CurrentPlan from './currentPlan';

export default function PlanTab() {
  const [seats, setSeats] = useState(1);
  const [buttonLoading, setButtonLoading] = useState(false);

  const getPrices = async () => {
    const response = await api.get('/api/admin/stripe/prices');
    return response.data;
  };

  const { data: prices, isLoading } = useQuery({
    queryKey: ['prices'],
    queryFn: getPrices,
  });

  const {
    data: planData,
    isLoading: planDataLoading,
    refetch,
  } = useQuery({
    queryKey: ['plan', 'admin'],
    queryFn: async () => {
      const response = await api.get('/api/admin/plan');
      setSeats(response.data.seats);
      return response.data;
    },
  });

  const onBuySeats = async (priceId: string) => {
    setButtonLoading(true);
    const response = await api.post('/api/admin/stripe/checkout', {
      priceId,
      quantity: seats,
    });

    window.open(response.data, '_blank');
    setButtonLoading(false);
  };

  if (isLoading) {
    return <Skeleton active />;
  }

  const standardPrices = prices.filter(
    (price: any) => price.label === 'Standard'
  );
  const premiumPrices = prices.filter((price: any) => price.label === 'Pro');

  return (
    <div>
      <CurrentPlan data={planData} isLoading={isLoading} />
      <br />
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div>
          <Text>Seats</Text>
          <Input
            placeholder='Seats'
            defaultValue={1}
            value={seats}
            type='number'
            min={1}
            onChange={(e) => setSeats(Number(e.target.value))}
          />
        </div>
      </div>
      <br />

      <div style={{ display: 'flex', gap: '1rem' }}>
        <Card title={'Standard'} style={{ width: '50%' }}>
          <div style={{ display: 'flex', gap: '2rem' }}>
            <List size='small' style={{ width: '50%' }}>
              <List.Item>✅ Unlimited projects</List.Item>
              <List.Item>✅ Unlimited boards</List.Item>
              <List.Item>✅ Unlimited tasks</List.Item>
              <List.Item>✅ Tasks bulk edit</List.Item>
              <List.Item>
                ✅ 1,000 automations{' '}
                <Text type='secondary'>
                  <small>(per month)</small>
                </Text>
              </List.Item>
              <List.Item>✅ Gamification</List.Item>
            </List>
            <List size='small' style={{ width: '50%' }}>
              <List.Item>✅ Quest timeline</List.Item>
              <List.Item>✅ Timesheets</List.Item>
              <List.Item>✅ Action log 7 days</List.Item>
              <List.Item>✅ 250GB file storage</List.Item>
              <List.Item>✅ 24/7 Support</List.Item>
              <List.Item>... and more</List.Item>
            </List>
          </div>
          <br />
          <div style={{ display: 'flex', gap: '2rem' }}>
            {standardPrices.map((price: any) => {
              return (
                <Card
                  style={{ width: '50%', textAlign: 'center' }}
                  title={
                    <div>
                      <Text>
                        Billed{' '}
                        {price.charge_type === 'year' ? 'Yearly' : 'Monthly'}
                      </Text>
                      <br />
                      {new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format((price.amount * seats) / 100)}
                      {price.charge_type === 'year' ? (
                        <Text type='secondary'>
                          {' '}
                          (-
                          {new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(
                            price.amount * seats - (price.amount - 2) * seats
                          )}
                          )
                        </Text>
                      ) : (
                        ''
                      )}
                    </div>
                  }
                >
                  <div style={{ margin: '.5rem 0' }}>
                    <Button
                      loading={buttonLoading}
                      disabled={
                        planData?.price === price.label &&
                        planData?.chargeType === price.charge_type
                      }
                      onClick={() => {
                        onBuySeats(price.priceId);
                      }}
                      type='primary'
                    >
                      Buy {seats} Seats
                    </Button>
                  </div>
                </Card>
              );
            })}
          </div>
        </Card>
        <Card title='Pro' style={{ width: '50%' }}>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <List size='small' style={{ width: '100%' }}>
              <List.Item>✅ All in Standard</List.Item>
              <List.Item>✅ Departments</List.Item>
              <List.Item>✅ Action log 30 days</List.Item>

              <List.Item>✅ 24/7 *Priority* Support</List.Item>
              <List.Item>✅ 500GB file storage</List.Item>
              <List.Item>... and more</List.Item>
            </List>
            <List size='small' style={{ width: '100%' }}>
              <List.Item>
                ✅ 10,000 automations{' '}
                <Text type='secondary'>
                  <small>(per month)</small>
                </Text>
              </List.Item>
            </List>
          </div>
          <br />
          <div style={{ display: 'flex', gap: '2rem' }}>
            {premiumPrices.map((price: any) => {
              return (
                <Card
                  style={{ width: '50%', textAlign: 'center' }}
                  title={
                    <div>
                      <Text>
                        Billed{' '}
                        {price.charge_type === 'year' ? 'Yearly' : 'Monthly'}
                      </Text>{' '}
                      <Text type='success'>
                        {price.charge_type === 'year' ? '(Best Value)' : ''}
                      </Text>
                      <br />
                      {new Intl.NumberFormat('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      }).format((price.amount * seats) / 100)}
                      {price.charge_type === 'year' ? (
                        <Text type='secondary'>
                          {' '}
                          (-
                          {new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(
                            price.amount * seats - (price.amount - 2) * seats
                          )}
                          )
                        </Text>
                      ) : (
                        ''
                      )}
                    </div>
                  }
                >
                  <div style={{ margin: '.5rem 0' }}>
                    <Button
                      loading={buttonLoading}
                      disabled={
                        planData?.price === price.label &&
                        planData?.chargeType === price.charge_type
                      }
                      onClick={() => {
                        onBuySeats(price.priceId);
                      }}
                      type='primary'
                    >
                      Buy {seats} Seats
                    </Button>
                  </div>
                </Card>
              );
            })}
          </div>
        </Card>
      </div>
    </div>
  );
}
