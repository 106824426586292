import * as React from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { api } from '../../api';
// import { Button } from '@mui/material';
import { Button, Input, Space } from 'antd';
import { AxiosError } from 'axios';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

export default function Register() {
  // const userStore = useUserStore();
  const navigate = useNavigate();

  const [loginInProgress, setLoginInProgress] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoginInProgress(true);
    const data = new FormData(event.currentTarget);

    const email = data.get('email') as string;
    const password = data.get('password') as string;
    const confirm_password = data.get('confirm_password') as string;
    const full_name = data.get('full_name') as string;
    const company_name = data.get('company_name') as string;

    if (password !== confirm_password) {
      // snack.enqueueSnackbar('Passwords do not match', {
      //   variant: 'error',
      // });
      setLoginInProgress(false);

      return;
    }

    try {
      const token = await api.post(
        '/api/register',
        { email, password, fullName: full_name, companyName: company_name },
        { withCredentials: true }
      );

      // localStorage.setItem('token', token.data);
      // return navigate('/');
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        // snack.enqueueSnackbar(error.response?.data.message, {
        //   variant: 'error',
        // });
      }
    }
    setLoginInProgress(false);
  };

  React.useEffect(() => {
    // if (pb.authStore.isValid) {
    //   return navigate('/');
    // }
  }, []);

  return (
    <Space
      style={{
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Title level={1}>Taskiro</Title>
      <div style={{ marginTop: '1rem' }}></div>

      <Title level={3}>Register</Title>
      <form onSubmit={handleSubmit}>
        <Input name='name' required placeholder='Name' />
        <div style={{ marginTop: '1rem' }}></div>

        <Input name='email' required placeholder='Email' />
        <div style={{ marginTop: '1rem' }}></div>

        <Input
          name='password'
          required
          placeholder='Password'
          autoComplete='password'
        />
        <div style={{ marginTop: '1rem' }}></div>

        <Input name='confirmPassword' required placeholder='Confirm Password' />
        <div style={{ marginTop: '1rem' }}></div>

        <Input name='companyName' required placeholder='Company Name' />
        <div style={{ marginTop: '1rem' }}></div>
        <div style={{ textAlign: 'center' }}>
          <Button
            loading={loginInProgress}
            htmlType='submit'
            type='primary'
            disabled={loginInProgress}
          >
            Register
          </Button>

          <div style={{ marginTop: '2rem' }}></div>
          <div>
            <Text>Already a member?</Text>
            <Link to='/login'>
              <Button disabled={loginInProgress} type='link'>
                Login
              </Button>
            </Link>
          </div>
          <div style={{ marginTop: '1rem' }}></div>
          <div>
            <Text>Forgot password?</Text>
            <Button disabled={loginInProgress} href='#' type='link'>
              Reset
            </Button>
          </div>
        </div>
      </form>
    </Space>
  );
}
