import Assigned from './assigned';
import Pointlog from './pointlog';
import Watched from './watched';

function MeTwo() {
  return (
    <div style={{ height: '80vh' }}>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          flexDirection: 'column',
          // justifyContent: 'space-between',
        }}
      >
        <div>
          <Assigned />
        </div>
        <div>
          <Watched />
        </div>
      </div>
    </div>
  );
}

export default MeTwo;
