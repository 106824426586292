import React, { useEffect, useState } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  Button,
  ConfigProvider,
  Dropdown,
  Input,
  Layout,
  MenuProps,
  Modal,
  Tag,
  theme,
  FloatButton,
  Progress,
  Drawer,
  Divider,
  Space,
} from 'antd';
import { Avatar } from 'antd';
import BoardSelector from './BoardWrapper/BoardSelector';
import ProjectSelector from '../components/projectSelector';
import {
  AppstoreAddOutlined,
  ArrowLeftOutlined,
  CarryOutOutlined,
  FieldTimeOutlined,
  FontSizeOutlined,
  InfoCircleFilled,
  LockOutlined,
  LogoutOutlined,
  PlusOutlined,
  ProductOutlined,
  SettingOutlined,
  SunOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Title from 'antd/es/typography/Title';

import { api } from '../api';
import { useThemeModeStore } from '../state/themeState';
import { useQuery } from '@tanstack/react-query';
import TaskDetailsModal2 from '../modals/taskModal';
import AccountSettings from '../modals/AccountSettings';
import UserAvatar from '../components/UserAvatar';
import CreateTaskModalGlobal from '../modals/CreateTaskGlobal';
import { useProjectStore } from '@/state/projectState';
import NotificationDropdown from '@/components/NotificationsMenu';
import { useUserStore } from '@/state/userState';
import LevelMap from '@/components/LevelMap';
import { AliasToken } from 'antd/es/theme/internal';
import { getPermissions } from '@/permissions';
import TaskDetailsMain from '@/components/TaskDetailsMain';
import { useOptionStore } from '@/state/options';
import { Icon } from '@iconify/react';
import { CustomIcon } from '@/components/Icon';
import SearchModal from '@/modals/Search';

const { Header, Sider, Content } = Layout;

const Wrapper2: React.FC = () => {
  const navigate = useNavigate();
  const currentTheme = useThemeModeStore();

  const { pathname } = useLocation();

  const { projectKey, taskId } = useParams();

  const [searchModalOpen, setSearchModalOpen] = useState(false);

  const [shortcutLabel, setShortcutLabel] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  const [imageKey, setImageKey] = useState(null);

  const [showCreateTaskGlobal, setShowCreateTaskGlobal] = useState(false);
  const [showTaskDetailsModal, setShowTaskDetailsModal] = useState(false);
  const [userSettingsModalOpen, setUserSettingsModalOpen] = useState(false);
  const [taskKey, setTaskKey]: any = useState(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [showLevelInfoModal, setShowLevelInfoModal] = useState(false);

  const projectStore = useProjectStore();
  const userStore = useUserStore();

  const getMe = async () => {
    try {
      const response = await api.get(`/api/me`);
      userStore.setUser(response.data);
      setImageKey(response.data.avatar);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: userData,
    isLoading: isLoadingUser,
    refetch,
    error,
  } = useQuery({
    queryKey: ['me'],
    queryFn: getMe,
  });

  useEffect(() => {
    if (searchParams.get('openTask')) {
      setTaskKey(searchParams.get('openTask'));
      if (optionStore.options.openTaskInDrawer) {
        optionStore.setTaskOpen(true);
      }
      setShowTaskDetailsModal(true);
    }
  }, [searchParams]);

  let themeToSelect = theme.defaultAlgorithm;

  switch (currentTheme.theme) {
    case 'dark': {
      themeToSelect = theme.darkAlgorithm;

      break;
    }
    default: {
      themeToSelect = theme.defaultAlgorithm;
    }
  }

  const handleLogout = async () => {
    // Logout logic
    await api.post('/api/logout', {}, { withCredentials: true });
    localStorage.removeItem('token');
    navigate('/login');
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      // Check if "cmd/ctrl + /" keys are pressed together
      if (event.key === '/' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        // Add your logic here
        setSearchModalOpen(true);
      }
    };

    if (navigator.userAgent.includes('Mac')) {
      setShortcutLabel('⌘ + /');
    } else {
      setShortcutLabel('Ctrl + /');
    }

    // Add event listener for keydown
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!taskId && optionStore.options.openTaskInDrawer) {
      optionStore.setTaskOpen(false);
    }
  }, [taskId]);

  const optionStore = useOptionStore();

  useEffect(() => {
    if (localStorage.getItem('openTaskInDrawer')) {
      optionStore.setOption({
        ...optionStore.options,
        openTaskInDrawer: localStorage.getItem('openTaskInDrawer') === 'true',
      });
    }

    if (localStorage.getItem('fontSize')) {
      optionStore.setOption({
        ...optionStore.options,
        fontSize: parseInt(localStorage.getItem('fontSize') as string),
      });
    }
  }, []);

  const items: MenuProps['items'] = [
    {
      label: `Account`,
      key: '0',
      icon: <UserOutlined />,
      onClick: () => {
        setUserSettingsModalOpen(true);
      },
    },
    {
      type: 'divider',
    },
    {
      label: `Theme: ${currentTheme.theme}`,
      key: 'theme',
      icon: <SunOutlined />,
      onClick: (e) => {
        e.domEvent.preventDefault();
        e.domEvent.stopPropagation();
        currentTheme.toggleThemeMode();
      },
    },
    // {
    //   label: `Open Task: ${
    //     optionStore.options.openTaskInDrawer ? 'Drawer' : 'Modal'
    //   }`,
    //   key: 'taskMode',
    //   icon: <SunOutlined />,
    //   onClick: () => {
    //     localStorage.setItem(
    //       'openTaskInDrawer',
    //       `${!optionStore.options.openTaskInDrawer}`
    //     );
    //     optionStore.setOption({
    //       openTaskInDrawer: !optionStore.options.openTaskInDrawer,
    //     });
    //   },
    // },
    {
      label: (
        <div>
          Font Size: {optionStore.options.fontSize}{' '}
          <Button
            type='text'
            onClick={() => {
              if (optionStore.options.fontSize <= 10) return;
              localStorage.setItem(
                'fontSize',
                (optionStore.options.fontSize - 1).toString()
              );
              optionStore.setOption({
                ...optionStore.options,
                fontSize: optionStore.options.fontSize - 1,
              });
            }}
            size='small'
          >
            -
          </Button>{' '}
          <Button
            type='text'
            onClick={() => {
              if (optionStore.options.fontSize >= 25) return;
              localStorage.setItem(
                'fontSize',
                (optionStore.options.fontSize + 1).toString()
              );
              optionStore.setOption({
                ...optionStore.options,
                fontSize: optionStore.options.fontSize + 1,
              });
            }}
            size='small'
          >
            +
          </Button>{' '}
        </div>
      ),
      key: 'fontSize',
      icon: <FontSizeOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: 'Logout',
      key: 'logout',
      onClick: handleLogout,
      icon: <LogoutOutlined />,
    },
    {
      type: 'divider',
    },
    {
      label: 'Suggest a feature',
      key: 'feature',
      icon: <AppstoreAddOutlined />,
      onClick: () => {
        window.open('https://questiro.features.vote/board', '_blank');
      },
    },
  ];

  const global = {
    colorPrimary: '#24a0ed',
    colorLink: '#24a0ed',
    colorLinkHover: '#1890ff',
    fontSize: optionStore.options.fontSize ?? 14,
    colorSuccess: '#52c41a',
    colorWarning: '#faad14',
    colorError: '#f5222d',
    colorInfo: '#1890ff',
    motion: false,
  };

  const darkThemeTokens = {
    colorBgBase: '#111111',
    ...global,
  };
  const lightThemeTokens: Partial<AliasToken> = {
    colorBgBase: '#FFF',
    ...global,
  };

  const siderAndHeaderBackground =
    currentTheme.theme === 'dark' ? '#181818' : 'white';

  const currentThemeTokens =
    currentTheme.theme === 'dark' ? darkThemeTokens : lightThemeTokens;

  const permissions = getPermissions(userData?.tierKey);

  return (
    <ConfigProvider
      theme={{
        algorithm: [themeToSelect, theme.compactAlgorithm],
        token: currentThemeTokens,
      }}
    >
      <Layout>
        <Sider
          theme={currentTheme.theme}
          width={220}
          style={{
            minHeight: '100vh',
            backgroundColor: siderAndHeaderBackground,
          }}
        >
          <div style={{ margin: '.5rem' }}>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Title level={3}>Questiro</Title>
            </div>
            {pathname.includes('/task') && (
              <div>
                <Button
                  style={{ width: '100%', justifyContent: 'left' }}
                  size='middle'
                  type={'primary'}
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  <ArrowLeftOutlined />
                  Back to workspace
                </Button>
              </div>
            )}
            {!pathname.includes('/me') &&
              !pathname.includes('/admin') &&
              !pathname.includes('/task') && (
                <div>
                  <ProjectSelector />
                  {projectKey && <Divider style={{ margin: '.5rem 0' }} />}
                  {projectKey && <BoardSelector />}
                </div>
              )}
            {pathname.includes('/me') && (
              <div>
                <div>
                  <Button
                    style={{ width: '100%', justifyContent: 'left' }}
                    size='middle'
                    type={'primary'}
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    <ArrowLeftOutlined />
                    Back to workspace
                  </Button>
                </div>
                <Divider style={{ margin: '.5rem 0' }} />
                <Space direction='vertical' style={{ width: '100%' }}>
                  <div>
                    <Button
                      style={{ width: '100%', justifyContent: 'left' }}
                      type={pathname.endsWith('/me') ? 'default' : 'text'}
                      onClick={() => {
                        navigate('/me');
                      }}
                    >
                      <UnorderedListOutlined />
                      Tasks
                    </Button>
                  </div>

                  {permissions?.timesheets && (
                    <div>
                      <Button
                        style={{ width: '100%', justifyContent: 'left' }}
                        type={
                          pathname.endsWith('/me/timesheet')
                            ? 'default'
                            : 'text'
                        }
                        onClick={() => {
                          navigate('/me/timesheet');
                        }}
                      >
                        <FieldTimeOutlined />
                        Timesheet
                      </Button>
                    </div>
                  )}

                  {/* <div>
                  <Button
                    style={{ width: '100%', justifyContent: 'left' }}
                    type={pathname.endsWith('/me/points') ? 'default' : 'text'}
                    onClick={() => {
                      navigate('/me/points');
                    }}
                  >
                    <Icon icon='mdi:archive' width='1rem' height='1rem' />
                    Pointlog
                  </Button>
                </div> */}
                </Space>
              </div>
            )}
            {pathname.includes('/admin') && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Button
                  style={{ width: '100%', justifyContent: 'left' }}
                  size='middle'
                  type={'primary'}
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  <ArrowLeftOutlined />
                  Back to workspace
                </Button>
                <Divider style={{ margin: '.5rem 0' }} />
                <Space direction='vertical' style={{ width: '100%' }}>
                  <Button
                    style={{ width: '100%', justifyContent: 'left' }}
                    size='middle'
                    type={
                      pathname.endsWith('/admin/projects') ? 'default' : 'text'
                    }
                    onClick={() => {
                      navigate('/admin/projects');
                    }}
                  >
                    <ProductOutlined />
                    Projects
                  </Button>

                  {permissions?.timesheets && (
                    <Button
                      style={{ width: '100%', justifyContent: 'left' }}
                      size='middle'
                      type={
                        pathname.endsWith('/admin/timesheets')
                          ? 'default'
                          : 'text'
                      }
                      onClick={() => {
                        navigate('/admin/timesheets');
                      }}
                    >
                      <FieldTimeOutlined />
                      Timesheets
                    </Button>
                  )}
                  {userData?.isAdmin && (
                    <Button
                      style={{ width: '100%', justifyContent: 'left' }}
                      size='middle'
                      type={pathname.endsWith('/admin') ? 'default' : 'text'}
                      onClick={() => {
                        navigate('/admin');
                      }}
                    >
                      <SettingOutlined />
                      Settings
                    </Button>
                  )}
                </Space>
              </div>
            )}
          </div>
        </Sider>
        <Layout>
          <Header
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: 0,
              backgroundColor: siderAndHeaderBackground,
            }}
          >
            {/* // left side */}
            <div style={{ display: 'flex', gap: '1rem' }}>
              {/* {pathname.includes('/me') && (
                <div>
                  <Button
                    type='link'
                    style={{ width: '100%' }}
                    size='small'
                    onClick={() => {
                      navigate('/');
                    }}
                  >
                    <CustomIcon
                      size={20}
                      icon='material-symbols:work-outline'
                    />
                    Work
                  </Button>
                </div>
              )} */}
              {!pathname.includes('/me') && (
                <div>
                  <Button
                    type='text'
                    style={{ width: '100%' }}
                    size='small'
                    onClick={() => {
                      navigate('/me');
                    }}
                  >
                    <CarryOutOutlined />
                    My Work
                  </Button>
                </div>
              )}
              {(userData?.isAdmin || userStore.user?.isManager) &&
                !pathname.includes('/admin') && (
                  <div>
                    <Button
                      type='text'
                      style={{ width: '100%' }}
                      size='small'
                      onClick={() => {
                        navigate('/admin/projects');
                      }}
                    >
                      <LockOutlined />
                      Admin
                    </Button>
                  </div>
                )}
            </div>
            {/* // right side */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              {/* <div
                className='level-info-and-points'
                style={{
                  display: 'flex',
                  gap: '1rem',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  onClick={() => {
                    setShowLevelInfoModal(true);
                  }}
                  type='text'
                  size='small'
                >
                  <InfoCircleFilled />
                </Button>
                <Tag>{userData?.level.label}</Tag>
                <div style={{ width: 150 }}>
                  <Progress
                    showInfo={false}
                    percent={userData?.level.percent}
                    type='line'
                  />
                </div>
                <div>
                  <span>
                    <Tag bordered={false}>
                      {userData?.level.points} / {userData?.level.maxPoints} XP
                    </Tag>
                  </span>
                </div>
              </div> */}
              <div>
                {projectStore.hasProjects && (
                  <Button
                    onClick={() => {
                      setShowCreateTaskGlobal(true);
                    }}
                    type='text'
                    size='small'
                  >
                    <PlusOutlined /> Create Task
                  </Button>
                )}
              </div>
              <div style={{ width: 150 }}>
                <Input
                  placeholder={`Search ( ${shortcutLabel} )`}
                  onClick={() => {
                    setSearchModalOpen(true);
                  }}
                />
              </div>
              <NotificationDropdown
                updatePoints={() => {
                  refetch();
                }}
              />
              <Dropdown
                open={dropdownOpen}
                onOpenChange={(open, info) => {
                  if (info.source === 'trigger' || open) {
                    setDropdownOpen(open);
                  }
                }}
                trigger={['click']}
                menu={{
                  items,
                  onClick: (e) => {
                    if (['fontSize', 'increase', 'theme'].includes(e.key)) {
                      return;
                    }
                    setDropdownOpen(false);
                  },
                }}
              >
                {imageKey ? (
                  <div>
                    <UserAvatar token={imageKey} size={40} />
                  </div>
                ) : (
                  <Avatar size={40} icon={<UserOutlined />} />
                )}
              </Dropdown>
              {/* <Dropdown menu={{ items }} trigger={['click']}>
                <Avatar size={40} icon={<UserOutlined />} />
              </Dropdown> */}
            </div>
          </Header>
          <Content
            style={{
              padding: 10,
              width: `calc(100% - ${optionStore.taskOpen ? 800 : 0}px)`,
              overflow: 'auto',
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>

      {showCreateTaskGlobal && (
        <CreateTaskModalGlobal
          defaultProjectKey={projectKey ?? ''}
          setShowNewTaskForm={setShowCreateTaskGlobal}
          showNewTaskForm={showCreateTaskGlobal}
          onCreateTask={() => {}}
        />
      )}

      {searchModalOpen && (
        <SearchModal
          searchModalOpen={searchModalOpen}
          setSearchModalOpen={setSearchModalOpen}
          setShowCreateTaskGlobal={setShowCreateTaskGlobal}
        />
      )}

      {optionStore.options.openTaskInDrawer && (
        <Drawer
          width={'800px'}
          open={showTaskDetailsModal}
          mask={false}
          onClose={() => {
            setShowTaskDetailsModal(false);
            optionStore.setTaskOpen(false);
            setSearchParams();
          }}
        >
          <TaskDetailsMain taskKey={taskKey} onUpdateTask={() => {}} />
        </Drawer>
      )}

      {showTaskDetailsModal && !optionStore.options.openTaskInDrawer && (
        <TaskDetailsModal2
          taskKey={taskKey}
          showTaskDetailsModal={showTaskDetailsModal}
          setShowTaskDetailsModal={setShowTaskDetailsModal}
          onUpdateTask={async () => {}}
          onCancel={() => {
            setShowTaskDetailsModal(false);
            setSearchParams();
          }}
        />
      )}

      {userSettingsModalOpen && (
        <Modal
          style={{ top: 20 }}
          open={userSettingsModalOpen}
          onCancel={() => setUserSettingsModalOpen(false)}
          destroyOnClose
          width={'50vw'}
          footer={null}
        >
          <AccountSettings />
        </Modal>
      )}
      {showLevelInfoModal && (
        <Modal
          style={{ top: 20 }}
          open={showLevelInfoModal}
          onCancel={() => setShowLevelInfoModal(false)}
          destroyOnClose
          width={'50vw'}
          footer={null}
        >
          <LevelMap />
        </Modal>
      )}
    </ConfigProvider>
  );
};

export default Wrapper2;
