import { api } from '@/api';
import Editor from '@/components/Editor/index3';
import { ArrowRightOutlined, SyncOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Card,
  Collapse,
  CollapseProps,
  Skeleton,
  Tag,
  Timeline,
} from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { RelativeTime } from '@/components/RelativeTime';

export const Activity = ({
  projectId,
  projectKey,
}: {
  projectId: string;
  projectKey: string;
}) => {
  const [limit, setLimit] = useState(10);

  const getActivity = async () => {
    const response = await api.get(
      `/api/projects/${projectId}/overview/activity?limit=${limit}`
    );
    return response.data;
  };

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['activity', projectId],
    queryFn: getActivity,
  });

  useEffect(() => {
    refetch();
  }, [limit]);

  if (isLoading) return <Skeleton active />;

  const getFieldChangeTag = (
    field:
      | 'label'
      | 'description'
      | 'type'
      | 'priority'
      | 'assignee'
      | 'status',
    from: any,
    to: any
  ) => {
    switch (field) {
      case 'label':
        return (
          <div>
            <Text strong>{from}</Text> <ArrowRightOutlined size={15} />{' '}
            <Text strong>{to}</Text>
          </div>
        );
      case 'description': {
        // const items: CollapseProps['items'] = [
        //   {
        //     key: '1',
        //     label: 'Show Changes',
        //     children: (
        //       <div style={{ display: 'flex', gap: '.3rem' }}>
        //         <div
        //           style={{
        //             border: '1px solid gray',
        //             borderRadius: 8,
        //             padding: 5,
        //             minWidth: '45%',
        //           }}
        //         >
        //           <Editor
        //             defaultContent={from}
        //             editable={false}
        //             projectKey={projectKey}
        //           />
        //         </div>
        //         <ArrowRightOutlined size={15} />{' '}
        //         <div
        //           style={{
        //             border: '1px solid gray',
        //             borderRadius: 8,
        //             padding: 5,
        //             minWidth: '45%',
        //           }}
        //         >
        //           <Editor
        //             defaultContent={to}
        //             editable={false}
        //             projectKey={projectKey}
        //           />
        //         </div>
        //       </div>
        //     ),
        //   },
        // ];

        // return (
        //   <Collapse
        //     size='small'
        //     items={items}
        //     style={{ marginTop: '.5rem' }}
        //     bordered={false}
        //   />
        // );

        return '';
      }
      default:
        return (
          <div>
            <Tag color='gray' style={{ marginRight: 3 }} bordered={false}>
              {from}
            </Tag>
            <ArrowRightOutlined size={20} />
            <Tag color='blue' style={{ marginLeft: 3 }} bordered={false}>
              {to}
            </Tag>
          </div>
        );
    }
  };

  const groupedByDate = data?.reduce((acc: any, item: any) => {
    const date = dayjs(item.createdAt).format('DD.MM.YYYY');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});

  const items = Object.keys(groupedByDate).map((date) => {
    const children = groupedByDate[date].map((item: any) => {
      return (
        <Card size='small' style={{ marginBottom: '.3rem', minWidth: '450px' }}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Text style={{ textDecoration: 'underline' }}>
                  {item.userName}
                </Text>
              </div>
              <div>
                <Text type='secondary'>
                  <RelativeTime date={item.createdAt} showHoursAndMinutes />
                </Text>
              </div>
            </div>
            <Text strong>{item.action}</Text>
            <Link to={`?openTask=${item.key}`}>
              {' '}
              {`[${item.key}]`} {item.label}
            </Link>{' '}
            <Text strong>{item.field && item.field} </Text>
          </div>
          <div>
            {item.from && item.to && (
              <div style={{ margin: '.5rem 0' }}>
                {getFieldChangeTag(item.field, item.from, item.to)}
              </div>
            )}
          </div>
        </Card>
      );
    });

    return {
      children: (
        <div>
          {/* <Text>{date}</Text> */}
          {/* <hr /> */}
          <div style={{ marginBottom: '.3rem' }}>
            <Text type='secondary'>
              {/* <RelativeTime value={date} titleFormat='DD/MM/YYYY' /> */}
              {date}
            </Text>
          </div>
          {children}
        </div>
      ),
    };
  });

  // const items = data.map((item: any) => {
  //   return {
  //     children: (
  //       <div>
  //         <Text>
  //           {item.userName} {item.action}{' '}
  //           <Link to={`?openTask=${item.key}`}>
  //             {' '}
  //             {`[${item.key}]`} {item.label}
  //           </Link>{' '}
  //           {item.field && <Text>{item.field}</Text>}{' '}
  //         </Text>
  //         <br />
  //         {item.from && item.to && (
  //           <div style={{ margin: '.3rem 0' }}>
  //             {getFieldChangeTag(item.field, item.from, item.to)}
  //           </div>
  //         )}
  //         <Text type='secondary'>
  //           <RelativeTime
  //             value={item.createdAt}
  //             titleFormat='DD/MM/YYYY HH:mm'
  //           />
  //         </Text>
  //       </div>
  //     ),
  //   };
  // });

  return (
    <Card size='small'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text>Recent Activity</Text>
        <Button
          onClick={() => {
            refetch();
          }}
          title='Refresh'
          size='small'
        >
          <SyncOutlined />
        </Button>
      </div>
      <div
        style={{
          marginTop: '1rem',
          height: '70vh',
          overflow: 'auto',
          padding: '5px 0',
        }}
      >
        <Timeline mode='left' items={items} />
        <div style={{ textAlign: 'center' }}>
          <Button
            type='text'
            size='small'
            loading={isFetching}
            onClick={() => {
              setLimit(limit + 10);
            }}
          >
            Load More
          </Button>
        </div>
      </div>
    </Card>
  );
};
