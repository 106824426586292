import {
  Button,
  Input,
  Upload,
  UploadFile,
  UploadProps,
  List,
  Typography,
  message,
  Space,
  Select,
} from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { useState } from 'react';
import Papa from 'papaparse';
import { api } from '../../../../api';

const SelectRole = ({ handleChange }: any) => {
  return (
    <Space wrap>
      <Select
        defaultValue='user'
        style={{ width: 120 }}
        onChange={handleChange}
        options={[
          { value: 'user', label: 'User' },
          { value: 'admin', label: 'Admin' },
          { value: 'manager', label: 'Manager' },
        ]}
      />
    </Space>
  );
};

export default function InviteUsersTab() {
  const [messageApi, contextHolder] = message.useMessage();
  const [fileListCsv, setFileListCsv] = useState<UploadFile[]>([]);

  const [uploading, setUploading] = useState(false);

  const [usersCSV, setUsersCSV] = useState<{ email: string }[]>([]);

  const propsCSV: UploadProps = {
    onRemove: (file) => {
      const index = fileListCsv.indexOf(file);
      const newFileList = fileListCsv.slice();
      newFileList.splice(index, 1);
      setFileListCsv(newFileList);
    },
    beforeUpload: (file) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          setUsersCSV(
            results.data.map((user: any) => ({
              email: user.email,
              role: 'user',
            }))
          );
        },
      });
      setFileListCsv([file]);

      return false;
    },
    fileList: fileListCsv,
    accept: '.csv',
    maxCount: 1,
  };

  return (
    <div>
      {contextHolder}
      <Title level={5}>Invite users via file import</Title>
      <Text>File must have column: email</Text>
      <div style={{ marginTop: '1rem' }}></div>
      <Upload {...propsCSV}>
        <Button type='primary'>Import CSV</Button>
        <br />
        <Text type='secondary'>Max. 100 emails per file</Text>
      </Upload>
      <div style={{ marginTop: '1rem' }}></div>
      {usersCSV.length > 0 && (
        <List
          style={{ marginTop: '1rem' }}
          bordered
          dataSource={usersCSV}
          renderItem={(item) => (
            <List.Item
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div>
                <Typography.Text>{item.email}</Typography.Text>
              </div>
              <div>
                <SelectRole
                  handleChange={(role: any) => {
                    // handle change
                    setUsersCSV(
                      usersCSV.map((user) =>
                        user.email === item.email ? { ...user, role } : user
                      )
                    );
                  }}
                />
              </div>
            </List.Item>
          )}
        />
      )}
      <div style={{ marginTop: '1rem' }}></div>
      {usersCSV.length > 0 && (
        <Button
          type='primary'
          loading={uploading}
          onClick={async () => {
            setUploading(true);
            try {
              await api.post('/api/admin/users/invite', {
                invites: usersCSV,
              });
              messageApi.success('Invites sent');
              setUsersCSV([]);
            } catch (error: any) {
              messageApi.error(error.response.data.messages[0]);
            }
            setUploading(false);
          }}
        >
          Send {usersCSV.length} Invites
        </Button>
      )}
    </div>
  );
}
