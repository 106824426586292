import { api } from '../../api';

import {
  Alert,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Select,
  Skeleton,
  Slider,
  Spin,
  Tabs,
  TabsProps,
  Tag,
  Tooltip,
  message,
} from 'antd';
import Text from 'antd/es/typography/Text';
import { useEffect, useRef, useState } from 'react';
import TiptapEditor from '../../components/Editor/index3';
import TaskComments from '../../components/TaskComments';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  DatabaseOutlined,
  EditOutlined,
  EyeOutlined,
  LinkOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import QuickChangeAssignee from '@/components/QuickChangeAssignee';
import { Subtasks } from './subtasks';
import { useNavigate } from 'react-router-dom';
import Title from 'antd/es/typography/Title';

import TaskActivity from './history';
import { mutateContent } from '@/utils/uploadUtils';
import { RelativeTime } from '../RelativeTime';
import dayjs from 'dayjs';
import { getTaskPriorityIcon, getTaskTypeIcon } from '@/utils';
import { sseClient } from '@/api/realtime';

export default function TaskDetailsMain({
  taskKey,
  // setShowTaskDetailsModal,
  // showTaskDetailsModal,
  onUpdateTask,
}: // onCancel,
{
  taskKey: string;
  // setShowTaskDetailsModal: any;
  // showTaskDetailsModal: any;
  onUpdateTask: any;
  // onCancel: any;
}) {
  const [messageApi, contextHolder] = message.useMessage();
  const [taskDescription, setTaskDescription]: any = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [taskPoints, setTaskPoints] = useState(0);
  const [taskId, setTaskId] = useState(null);

  const [eventSource, setEventSource] = useState<EventSource | null>(null);

  const navigate = useNavigate();

  const getTask = async () => {
    const responseTask = await api.get(`/api/tasks/${taskKey}`);
    const task = responseTask.data;

    const responseColumns = await api.get(
      `/api/projects/${task.projectKey}/boards/${task.boardKey}/columns?withTasks=false`
    );

    const responseTaskTypes = await api.get(`/api/task-types`);
    const priorities = await api.get(`/api/priority`);
    const quests = await api.get(
      `/api/projects/${task.projectKey}/quests?filter=all`
    );

    setTaskDescription(responseTask.data.description);

    setTaskPoints(responseTask.data.points);

    setTaskId(responseTask.data.id);

    return {
      taskToEdit: responseTask.data,
      columns: responseColumns.data.map((column: any) => ({
        id: column.id,
        label: column.label,
      })),

      taskTypes: responseTaskTypes.data.map((taskType: any) => ({
        id: taskType.id,
        label: taskType.label,
        description: taskType.description,
      })),
      priorities: priorities.data.map((priority: any) => ({
        id: priority.id,
        label: priority.label,
      })),
      quests: quests.data.map((quest: any) => ({
        id: quest.id,
        label: quest.label,
      })),
      isWatching: responseTask.data.isWatching,
      columnId: responseTask.data.columnId,
      assigneeId: responseTask.data.assigneeId,
      assigneeFullName: responseTask.data.assigneeFullName,
      points: responseTask.data.points,
      taskTypeId: responseTask.data.taskTypeId,
      priorityId: responseTask.data.priorityId,
      questId: responseTask.data.questId,
      dueDate: responseTask.data.dueDate,
    };
  };

  const { data, isLoading, isSuccess, refetch }: any = useQuery({
    queryKey: ['task', taskKey],
    queryFn: getTask,
  });

  const queryClient = useQueryClient();

  const [editable, setEditable] = useState(false);

  const handleTaskUpdated = (event: any) => {
    const newEvent = JSON.parse(JSON.parse(event.data));
    const taskKey = newEvent.payload.key;
    queryClient.setQueryData(['task', taskKey], (oldData: any) => ({
      ...oldData,
      taskToEdit: {
        ...oldData.taskToEdit,
        ...newEvent.payload,
      },
      ...newEvent.payload,
    }));
    if (newEvent.payload.description) {
      setTaskDescription(newEvent.payload.description);
    }
  };

  useEffect(() => {
    if (!taskId) {
      return;
    }

    sseClient.addEventListener('task_updated', handleTaskUpdated);

    return () => {
      sseClient.removeEventListener('task_updated', handleTaskUpdated);
    };
  }, [taskId]);

  if (isLoading || !isSuccess) {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </>
    );
  }

  const {
    taskToEdit,
    columns,
    taskTypes,
    priorities,
    users,
    columnId,
    taskTypeId,
    priorityId,
    boardKey,
    projectKey,
    createdAt,
    assigneeId,
    updatedAt,
    assigneeFullName,
    quests,
    questId,
  } = data;

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Details',
      children: (
        <div>
          <Card>
            <Text>Description</Text>
            <Divider style={{ margin: '.5rem 0' }} />
            <Spin spinning={isUpdating}>
              <TiptapEditor
                defaultContent={taskDescription}
                projectKey={taskToEdit.projectKey}
                editable={editable && !taskToEdit.archivedAt}
                onCancel={(e: any) => {
                  e.stopPropagation();
                  setEditable(false);
                }}
                onSave={async (content: any) => {
                  try {
                    setIsUpdating(true);
                    const ct = await mutateContent(content);
                    await api.put(`/api/tasks/${taskToEdit.id}`, {
                      description: ct,
                    });
                    setTaskDescription(ct);
                    messageApi.success('Task description updated');
                    if (onUpdateTask) {
                      onUpdateTask();
                    }
                    setEditable(false);
                    setIsUpdating(false);
                  } catch (error) {
                    console.log(error, 'ha?');
                    setIsUpdating(false);
                    messageApi.error('Error updating task description');
                  }
                }}
              />
            </Spin>
            {!editable && (
              <div style={{ textAlign: 'right' }}>
                <Button
                  disabled={!!taskToEdit.archivedAt || !!taskToEdit.completedAt}
                  onClick={() => {
                    setEditable(true);
                  }}
                  type='text'
                >
                  Edit
                </Button>
              </div>
            )}
          </Card>
          <br />
          {taskToEdit.parentId === null && (
            <Card>
              <Subtasks
                disabled={!!taskToEdit.archivedAt || !!taskToEdit.completedAt}
                task={taskToEdit}
                columnId={columnId}
                columns={columns}
                priorities={priorities}
                messageApi={messageApi}
              />
              {/* <Divider /> */}
            </Card>
          )}
          {taskToEdit.parentId !== null && (
            <Card>
              <Text>
                Subtask of
                <Button
                  onClick={() => {
                    navigate(`?openTask=${taskToEdit.parentTaskKey}`);
                  }}
                  type='link'
                >
                  {taskToEdit.parentTaskKey} - {taskToEdit.parentTaskLabel}
                </Button>
              </Text>
              <div style={{ textAlign: 'right' }}>
                <Popconfirm
                  title='Are you sure you want to detach this subtask?'
                  okText='Yes'
                  cancelText='No'
                  onConfirm={async () => {
                    try {
                      await api.delete(`/api/tasks/${taskToEdit.id}/detach`);
                      messageApi.success('Subtask detached');
                      // refetch();
                    } catch (error) {
                      messageApi.error('Error detaching subtask');
                    }
                  }}
                >
                  <Button danger type='text'>
                    Detach
                  </Button>
                </Popconfirm>
              </div>
            </Card>
          )}
          <br />
          <Card>
            <Text>Comments</Text>
            <Divider style={{ margin: '.5rem 0' }} />
            <TaskComments
              projectKey={taskToEdit.projectKey}
              taskId={taskToEdit.id}
            />
          </Card>
        </div>
      ),
    },

    {
      key: '2',
      label: 'History',
      children: (
        <div>
          <TaskActivity taskId={taskToEdit.id} projectKey={projectKey} />
        </div>
      ),
      destroyInactiveTabPane: true,
    },
  ];

  const inputVariant = 'filled';

  return (
    <div style={{ display: 'flex', gap: '1rem' }}>
      {contextHolder}

      <div
        className='left'
        style={{
          flex: 3,
          height: '89vh',
          overflow: 'auto',
          paddingRight: '.5rem',
        }}
      >
        <div>
          {/* <Text>Label</Text> */}
          {/* <br /> */}
          <div style={{ marginBottom: '1rem' }}>
            <Tag> {taskToEdit.projectLabel}</Tag>
            <Tag>{taskToEdit.boardLabel}</Tag>
            <Tag>{taskToEdit.key}</Tag>
          </div>
          {/* <Divider /> */}
          {taskToEdit.archivedAt && (
            <Alert
              message="This task is Archived and can't be edited"
              type='info'
              showIcon
            />
          )}

          {taskToEdit.completedAt && (
            <Alert
              message="This task is Completed and can't be edited"
              type='success'
              showIcon
            />
          )}

          <div style={{ marginBottom: '1rem' }}>
            <Text
              style={{
                fontSize: '1.6rem',
                // width: '100%',
              }}
              editable={
                !taskToEdit.archivedAt &&
                !taskToEdit.completedAt && {
                  async onChange(value) {
                    if (value.trim() === taskToEdit.label.trim()) {
                      return;
                    }
                    try {
                      await api.put(`/api/tasks/${taskToEdit.id}`, {
                        label: value,
                      });
                      messageApi.success('Task label updated');
                      if (onUpdateTask) {
                        onUpdateTask();
                      }
                      queryClient.setQueryData(
                        ['task', taskKey],
                        (oldData: any) => ({
                          ...oldData,
                          taskToEdit: {
                            ...oldData.taskToEdit,
                            label: value,
                          },
                        })
                      );
                    } catch (error) {
                      messageApi.error('Error updating task label');
                    }
                  },
                }
              }
            >
              {taskToEdit.label}
            </Text>
          </div>
          {/* <Divider /> */}
        </div>
        <div>
          <Tabs defaultActiveKey='1' items={items} />
        </div>
      </div>
      <div className='right' style={{ flex: 1 }}>
        {/* <div style={{ marginTop: '3rem' }}></div> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
          }}
        >
          <div>
            <Tooltip title={data.isWatching ? 'Watching' : 'Not Watching'}>
              <Button
                type={data.isWatching ? 'primary' : 'default'}
                size='small'
                onClick={async () => {
                  try {
                    await api.put(`/api/tasks/${taskToEdit.id}/watch`);
                    queryClient.setQueryData(
                      ['task', taskKey],
                      (oldData: any) => ({
                        ...oldData,
                        isWatching: !oldData.isWatching,
                      })
                    );
                    messageApi.success('Task watching status updated');
                  } catch (error) {
                    messageApi.error('Error updating task watching status');
                  }
                }}
                icon={<EyeOutlined />}
              />
            </Tooltip>{' '}
            <Tooltip
              title={taskToEdit.archivedAt ? 'Archived' : 'Not Archived'}
            >
              <Popconfirm
                title='Are you sure you want to archive this task?'
                onConfirm={async () => {
                  try {
                    await api.put(
                      `/api/tasks/${taskToEdit.id}/toggle-archived`
                    );

                    // await refetch();
                    messageApi.success(
                      taskToEdit.archivedAt
                        ? 'Task unarchived'
                        : 'Task archived'
                    );
                    if (onUpdateTask) {
                      onUpdateTask();
                    }
                  } catch (error) {
                    messageApi.error('Error updating task watching status');
                  }
                }}
              >
                <Button
                  size='small'
                  type={taskToEdit.archivedAt ? 'primary' : 'default'}
                  disabled={!!taskToEdit.completedAt}
                  icon={<DatabaseOutlined />}
                />
              </Popconfirm>
            </Tooltip>{' '}
            <Tooltip title='Copy link'>
              <Button
                size='small'
                icon={<LinkOutlined />}
                onClick={() => {
                  const currentUrl = window.location.origin;
                  navigator.clipboard.writeText(
                    `${currentUrl}/task/${taskKey}`
                  );
                }}
              />
            </Tooltip>
          </div>
        </div>

        {/* <Title type='warning' level={4}>
          {taskToEdit.archivedAt ? 'ARCHIVED' : ''}
        </Title> */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            // marginTop: '1rem',
          }}
        >
          <div>
            <Text>Status</Text>
            <Select
              title='Status'
              placeholder=' Status'
              disabled={!!taskToEdit.archivedAt || !!taskToEdit.completedAt}
              style={{ width: '100%' }}
              variant={inputVariant}
              value={columnId}
              onChange={async (value) => {
                if (value === columnId) {
                  return;
                }
                try {
                  const dataToSend = {
                    newColumnId: value,
                    beforeId: 'first',
                  };
                  await api.post(
                    `/api/tasks/${taskToEdit.id}/move`,
                    dataToSend
                  );

                  queryClient.setQueryData(
                    ['task', taskKey],
                    (oldData: any) => ({
                      ...oldData,
                      columnId: value,
                    })
                  );
                  messageApi.success('Task status updated');
                  if (onUpdateTask) {
                    onUpdateTask();
                  }
                } catch (error) {
                  messageApi.error('Error updating task status');
                }
              }}
            >
              {columns.map((column: any) => (
                <Select.Option key={column.id} value={column.id}>
                  {column.label}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div>
            <Text>Assignee</Text>
            <br />
            {/* <div style={{ textAlign: 'center' }}> */}
            <QuickChangeAssignee
              avatarSize={35}
              currentAssigneeId={assigneeId}
              projectKey={taskToEdit.projectKey}
              taskId={taskToEdit.id}
              showName={true}
              tooltipName={assigneeFullName}
              disabled={!!taskToEdit.archivedAt || !!taskToEdit.completedAt}
              onChangeAssignee={async () => {
                // await refetch();
                messageApi.success('Assignee Changed');
              }}
            />
            {/* </div> */}
          </div>

          <div>
            <Text>Type</Text>
            <Select
              placeholder=' Type'
              style={{ width: '100%' }}
              value={taskTypeId}
              disabled={!!taskToEdit.archivedAt || !!taskToEdit.completedAt}
              variant={inputVariant}
              options={taskTypes.map((taskType: any) => ({
                value: taskType.id,
                label: taskType.label,
                title: taskType.description,
              }))}
              labelRender={(option: any) => (
                <>
                  {getTaskTypeIcon(option.label?.toLowerCase() as any)}{' '}
                  {option.label}
                </>
              )}
              optionRender={(option: any) => (
                <div style={{ display: 'flex', gap: '.3rem' }}>
                  {getTaskTypeIcon(option.label?.toLowerCase() as any)}{' '}
                  {option.label}
                </div>
              )}
              onChange={async (value) => {
                if (value === taskTypeId) {
                  return;
                }
                try {
                  await api.put(`/api/tasks/${taskToEdit.id}`, {
                    taskTypeId: value,
                  });
                  queryClient.setQueryData(
                    ['task', taskKey],
                    (oldData: any) => ({
                      ...oldData,
                      taskTypeId: value,
                    })
                  );
                  messageApi.success('Task type updated');
                  if (onUpdateTask) {
                    onUpdateTask();
                  }
                } catch (error) {
                  messageApi.error('Error updating task type');
                }
              }}
            />
          </div>
          <div>
            <Text>Priority</Text>
            <Select
              placeholder='Priority'
              disabled={!!taskToEdit.archivedAt || !!taskToEdit.completedAt}
              style={{ width: '100%' }}
              value={priorityId}
              variant={inputVariant}
              labelRender={(value: any) => {
                return (
                  <>
                    {getTaskPriorityIcon(value.label?.toLowerCase() as any)}{' '}
                    {value.label}
                  </>
                );
              }}
              optionRender={(option: any) => (
                <div style={{ display: 'flex', gap: '.3rem' }}>
                  {getTaskPriorityIcon(option.label?.toLowerCase() as any)}{' '}
                  {option.label}
                </div>
              )}
              options={priorities.map((priority: any) => ({
                value: priority.id,
                label: priority.label,
              }))}
              onChange={async (value) => {
                if (value === priorityId) {
                  return;
                }
                try {
                  await api.put(`/api/tasks/${taskToEdit.id}`, {
                    priorityId: value,
                  });
                  queryClient.setQueryData(
                    ['task', taskKey],
                    (oldData: any) => ({
                      ...oldData,
                      priorityId: value,
                    })
                  );
                  messageApi.success('Task priority updated');
                  if (onUpdateTask) {
                    onUpdateTask();
                  }
                } catch (error) {
                  messageApi.error('Error updating task type');
                }
              }}
            />
          </div>

          <div style={{ flex: 1 }}>
            <Text>Quest</Text>
            <Select
              placeholder='Quest'
              disabled={!!taskToEdit.archivedAt || !!taskToEdit.completedAt}
              style={{ width: '100%' }}
              value={questId}
              allowClear
              showSearch
              variant={inputVariant}
              filterOption={(input, option: any) => {
                return (
                  option?.label
                    .toLowerCase()
                    .indexOf(input.toLowerCase().trim()) >= 0
                );
              }}
              options={quests.map((priority: any) => ({
                value: priority.id,
                label: priority.label,
              }))}
              onChange={async (value) => {
                if (value === questId) {
                  return;
                }
                try {
                  await api.put(`/api/tasks/${taskToEdit.id}`, {
                    questId: value === undefined ? 'remove' : value,
                  });
                  queryClient.setQueryData(
                    ['task', taskKey],
                    (oldData: any) => ({
                      ...oldData,
                      questId: value,
                    })
                  );
                  messageApi.success('Quest updated');
                  if (onUpdateTask) {
                    onUpdateTask();
                  }
                } catch (error) {
                  messageApi.error('Error updating task quest');
                }
              }}
            />
          </div>
          <div>
            <Text>Due Date</Text>
            <div>
              <DatePicker
                style={{ width: '100%' }}
                variant={inputVariant}
                format={'DD MMM YYYY'}
                value={
                  data?.dueDate
                    ? dayjs(data.dueDate.replace('Z', ''))
                    : undefined
                }
                onChange={(value) => {
                  try {
                    api.put(`/api/tasks/${taskToEdit.id}`, {
                      dueDate: value ? value.format('YYYY-MM-DD') : null,
                    });
                    queryClient.setQueryData(
                      ['task', taskKey],
                      (oldData: any) => ({
                        ...oldData,
                        dueDate: value ? value.format('YYYY-MM-DD') : null,
                      })
                    );
                    messageApi.success('Due date updated');
                    if (onUpdateTask) {
                      onUpdateTask();
                    }
                  } catch (error) {
                    messageApi.error('Error updating due date');
                  }
                }}
                showNow={false}
                presets={[
                  {
                    label: 'Today',
                    value: dayjs(),
                  },
                  {
                    label: 'Tomorrow',
                    value: dayjs().add(1, 'day'),
                  },
                  {
                    label: 'Next week',
                    value: dayjs().add(1, 'week'),
                  },
                  {
                    label: '2 weeks',
                    value: dayjs().add(2, 'week'),
                  },
                  {
                    label: '1 month',
                    value: dayjs().add(1, 'month'),
                  },
                ]}
              />
              {data?.dueDate && (
                // show relative time
                <Text type='secondary'>
                  Due in{' '}
                  {dayjs(
                    dayjs(data.dueDate.replace('Z', '')).endOf('day')
                  ).diff(dayjs(), 'day') > 0
                    ? dayjs(
                        dayjs(data.dueDate.replace('Z', '')).endOf('day')
                      ).diff(dayjs(), 'day') + ' days'
                    : 'Today'}
                </Text>
              )}
            </div>
          </div>
          <Divider style={{ margin: '.5rem 0' }} />
          <Card className='time'>
            <div>
              {taskToEdit.creatorFullName}
              <br />
              <Text type='secondary'>
                created{' '}
                {
                  <RelativeTime
                    date={taskToEdit.createdAt}
                    showHoursAndMinutes
                  />
                }{' '}
              </Text>
              {taskToEdit.updatedAt && (
                <>
                  <Divider style={{ margin: '.2rem 0' }} />
                  {taskToEdit.updaterFullName}
                  <br />
                  <Text type='secondary'>
                    updated{' '}
                    <RelativeTime
                      date={taskToEdit.updatedAt}
                      showHoursAndMinutes
                    />{' '}
                  </Text>
                </>
              )}
            </div>
          </Card>
        </div>

        {/* <div>
          <p>Complexity / Points</p>
          <div style={{ width: 700, marginLeft: '.8rem' }}>
            <Slider
              value={taskPoints}
              disabled={!!taskToEdit.archivedAt || !!taskToEdit.completedAt}
              onChange={(value) => {
                setTaskPoints(value);
              }}
              onChangeComplete={async (value) => {
                if (value === taskToEdit.points) {
                  return;
                }
                await api.put(`/api/tasks/${taskToEdit.id}`, {
                  points: value,
                });
                await refetch();
                messageApi.success('Task points updated');
                if (onUpdateTask) {
                  onUpdateTask();
                }
              }}
              max={150}
              step={10}
              marks={{
                0: 'None',
                20: 'Easy',
                50: 'Normal',
                100: 'Hard',
                150: 'Expert',
              }}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}
