import { EventSource } from 'extended-eventsource';
const apiUrl = import.meta.env.VITE_API_URL as string;

// export const eventSource = new EventSource(`${apiUrl}/sse/events`, {
//   headers: {
//     Authorization: `Bearer ${localStorage.getItem('token')}`,
//   },
// });

// eventSource.onerror = (err) => {
//   console.log(err, 'error: event source');
//   eventSource.close();
// };

// eventSource.onmessage = (event) => {
//   console.log(event, 'hejho');
// };

// sseClient.js
class SSEClient {
  url: string;
  options: { [key: string]: any };
  eventSource: EventSource | null;

  constructor(url: string, options: { [key: string]: any }) {
    this.url = url;
    this.options = options || {};
    this.eventSource = null;
  }

  connect() {
    if (!this.eventSource) {
      this.eventSource = new EventSource(this.url, this.options);

      // // Example: set up event listeners
      // this.eventSource.onmessage = (event) => {
      //   if(event.data === '') {
      //     return;
      //   }
      //   const data = JSON.parse(event.data);
      //   console.log('Default onmessage:', data);
      // };

      this.eventSource.onerror = (error) => {
        this.close();
      };
    }
    return this.eventSource;
  }

  close() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }

  addEventListener(eventName: string, handler: (event: MessageEvent) => void) {
    if (this.eventSource) {
      this.eventSource.addEventListener(eventName, handler);
    }
  }
  removeEventListener(
    eventName: string,
    handler: (event: MessageEvent) => void
  ) {
    if (this.eventSource) {
      this.eventSource.removeEventListener(eventName, handler);
    }
  }
}

export const sseClient = new SSEClient(`${apiUrl}/sse/events`, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  },
}).connect();
// Export a singleton or the class
