import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Layout,
  Table,
  Select,
  Alert,
  Skeleton,
  TabsProps,
  Tabs,
  Tooltip,
  Button,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isToday from 'dayjs/plugin/isToday';
import week from 'dayjs/plugin/weekOfYear';

import Text from 'antd/es/typography/Text';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { api } from '@/api';
import QuestModal from '@/modals/QuestModal';
import { ColumnType } from 'antd/es/table';
import Quests from '@/pages/quests';
// Replace react-draggable with react-rnd
import { Rnd } from 'react-rnd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';

dayjs.extend(minMax);
dayjs.extend(quarterOfYear);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(isToday);
dayjs.extend(week);

const { Option } = Select;

function App() {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'List',
      children: <Quests />,
    },
    {
      key: '2',
      label: 'Timeline',
      destroyInactiveTabPane: true,
      children: (
        <Layout>
          <GanttChart />
        </Layout>
      ),
    },
  ];

  return <Tabs defaultActiveKey='1' items={items} />;
}

const GanttChart = () => {
  const [timeUnit, setTimeUnit] = useState<any>('month'); // Default time unit
  const { projectKey } = useParams();
  const [questModalVisible, setQuestModalVisible] = useState<boolean>(false);
  const [selectedQuest, setSelectedQuest] = useState<any>(null);
  const [sortBy, setSortBy] = useState<any>('normal');

  const handleTimeUnitChange = (value: string) => {
    setTimeUnit(value);
  };

  const {
    data: mainTasks,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['questsGantt', projectKey],
    queryFn: async () => {
      const { data } = await api.get(`/api/projects/${projectKey}/quests/gant`);
      return data;
    },
  });

  const dateRange = useMemo(
    () => (mainTasks?.length ? getDateRange(mainTasks) : []),
    [mainTasks, isFetching]
  );

  const queryClient = useQueryClient();

  // Adjust dayWidth based on time unit
  const dayWidth = useMemo(() => {
    switch (timeUnit) {
      case 'day':
        return 25;
      case 'week':
        return 15; // Adjusted width for better visibility
      case 'month':
        return 5;
      case 'quarter':
        return 2;
      case 'year':
        return 1;
      default:
        return 25;
    }
  }, [timeUnit]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const handleTaskUpdate = async (
    task: any,
    newStartDate: any,
    newEndDate: any
  ) => {
    queryClient.setQueryData(['questsGantt', projectKey], (data: any) =>
      data.map((_task: any) =>
        _task.id === task.id
          ? {
              ..._task,
              start: newStartDate.toISOString(),
              end: newEndDate.toISOString(),
            }
          : _task
      )
    );

    // Optionally, send the update to the server
    try {
      // Uncomment and modify the API endpoint as needed
      api.put(`/api/quests/${task.id}`, {
        label: task.name,
        description: task.description,
        startDate: dayjs(newStartDate).format('YYYY-MM-DD'),
        endDate: dayjs(newEndDate).format('YYYY-MM-DD'),
      });
      // Optionally refetch tasks from the server
      // await refetch();
    } catch (error) {
      console.error('Failed to update task:', error);
      // Handle the error, possibly revert the state change
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }

  const EXTRA_COLUMN_WIDTH = 180;

  const totalTimelineWidth = dateRange.length * dayWidth + EXTRA_COLUMN_WIDTH;

  // Calculate the offset position for today's date
  const today = dayjs().startOf('day');
  const minDate = dateRange[0];
  const todayOffset = today.diff(minDate, 'day') * dayWidth;

  const columns: ColumnType[] = [
    {
      title: 'Quest',
      dataIndex: 'name',
      key: 'name',
      width: 140,
      fixed: 'left',
      render: (text: any, record: any) => {
        return (
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Button
                  onClick={() => {
                    setSelectedQuest(record);
                    setQuestModalVisible(true);
                  }}
                  size='small'
                  type='link'
                >
                  <>{record.name}</>
                </Button>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Due In',
      width: 60,
      fixed: 'left',
      render: (text: any, record: any) => {
        return <>{record.dueIn}d</>;
      },
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',
      width: 120,
      fixed: 'left',
      render: (text: any, record: any) => {
        return (
          <>
            <Text>
              {record.completed}/{record.allTasks}
            </Text>{' '}
            <Text type='secondary'>
              ({record.progress}%)
              <br />
            </Text>
          </>
        );
      },
    },
    {
      title: renderTimelineHeader(dateRange, dayWidth, timeUnit, todayOffset),
      dataIndex: 'timeline',
      key: 'timeline',
      render: (text: any, record: any) => (
        <div style={{ position: 'relative' }}>
          <TimelineBar
            task={record}
            dateRange={dateRange}
            dayWidth={dayWidth}
            timeUnit={timeUnit}
            todayOffset={todayOffset}
            scrollRef={scrollRef}
            onTaskUpdate={handleTaskUpdate} // Pass the function here
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
          <div>
            View by{' '}
            <Select
              value={timeUnit}
              onChange={handleTimeUnitChange}
              style={{ width: 100 }}
              size='small'
            >
              <Option value='day'>Day</Option>
              <Option value='week'>Week</Option>
              <Option value='month'>Month</Option>
              <Option value='quarter'>Quarter</Option>
              <Option value='year'>Year</Option>
            </Select>
          </div>
          <div>
            Sort by{' '}
            <Select
              value={sortBy}
              onChange={setSortBy}
              style={{ width: 120 }}
              size='small'
            >
              <Option value='normal'>No Sort</Option>
              <Option value='start'>Start Date</Option>
              <Option value='end'>End Date</Option>
            </Select>
          </div>
          <div>
            <Button
              size='small'
              type='text'
              onClick={() => {
                if (scrollRef.current) {
                  scrollRef.current.scrollIntoView({
                    behavior: 'instant',
                    block: 'center',
                    inline: 'center',
                  });
                }
              }}
            >
              Go To Today
            </Button>
          </div>
        </div>
      </div>

      <Table
        columns={columns}
        virtual
        dataSource={mainTasks?.sort((a: any, b: any) => {
          if (sortBy === 'start') {
            return dayjs(a.start).diff(dayjs(b.start));
          } else if (sortBy === 'endDate') {
            return dayjs(a.end).diff(dayjs(b.end));
          } else {
            return dayjs(a.createdAt).diff(dayjs(b.createdAt));
          }
        })}
        size='small'
        rowKey='id'
        pagination={false}
        scroll={{ x: totalTimelineWidth + 250, y: '65vh' }} // Include task column width
        bordered
      />

      {selectedQuest && (
        <QuestModal
          open={questModalVisible}
          quest={selectedQuest}
          setOpen={setQuestModalVisible}
          onClose={async () => {
            await refetch();
            setSelectedQuest(null);
          }}
        />
      )}
    </div>
  );
};

// Always use days as the base time unit
const getDateRange = (tasks: any[]) => {
  const startDates: any = [];
  const endDates: any = [];

  tasks.forEach((task, i) => {
    startDates.push(dayjs(task.start));
    endDates.push(dayjs(task.end));
  });

  const rangeStart = dayjs.min(startDates).startOf('year');
  const rangeEnd = dayjs.max(endDates).endOf('year');

  const dates = [];
  let currentDate = rangeStart;

  while (
    currentDate.isBefore(rangeEnd) ||
    currentDate.isSame(rangeEnd, 'day')
  ) {
    dates.push(currentDate);
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
};

// Render timeline header with vertical line for today
const renderTimelineHeader = (
  dateRange: any[],
  dayWidth: any,
  timeUnit: any,
  todayOffset: any
) => {
  const today = dayjs();

  if (timeUnit === 'day') {
    // For 'day' time unit, display weeks and days
    // Group dates by week
    const weeks: any[] = [];
    let currentWeek: any = null;

    dateRange.forEach((date: Dayjs) => {
      const weekNumber = date.isoWeek();
      const weekYear = date.isoWeekYear();

      const weekKey = `${weekYear}-W${weekNumber}`;
      const weekMonth = date.format('MMM');

      if (!currentWeek || currentWeek.key !== weekKey) {
        currentWeek = {
          key: weekKey,
          weekNumber,
          weekYear,
          date: date,
          weekMonth,
          dates: [],
          isCurrentWeek:
            date.isoWeek() === today.isoWeek() && date.year() === today.year(),
        };
        weeks.push(currentWeek);
      }
      currentWeek.dates.push(date);
    });

    return (
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        {/* Top header: Weeks */}
        <div style={{ display: 'flex' }}>
          {weeks.map((week) => (
            <div
              key={week.key}
              style={{
                width: `${week.dates.length * dayWidth}px`,
                textAlign: 'center',
                backgroundColor: week.isCurrentWeek ? '#505050' : 'transparent',
                borderRight: '1px solid #f0f0f0',
                borderBottom: '1px solid #f0f0f0',
              }}
            >
              <Text
                style={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: week.isCurrentWeek ? '#fff' : 'inherit',
                }}
              >
                {week.weekMonth} '{week.date.format('YY')}
              </Text>
            </div>
          ))}
        </div>
        {/* Bottom header: Days */}
        <div style={{ display: 'flex' }}>
          {dateRange.map((date: any) => (
            <div
              key={date.format('YYYY-MM-DD')}
              style={{
                width: `${dayWidth}px`,
                textAlign: 'center',
                borderRight: '1px solid #f0f0f0',
                backgroundColor: date.isSame(today, 'day')
                  ? '#505050'
                  : 'transparent',
              }}
            >
              <Text
                style={{
                  fontSize: '10px',
                  color: date.isSame(today, 'day') ? '#fff' : 'inherit',
                }}
              >
                {date.format('DD')}
              </Text>
            </div>
          ))}
        </div>
      </div>
    );
  } else if (timeUnit === 'week') {
    // For 'week' time unit, display weeks with date ranges
    const weeks = groupDatesByTimeUnit(dateRange, 'week');

    return (
      <div style={{ display: 'flex', position: 'relative' }}>
        {weeks.map((week: any) => (
          <div
            key={week.key}
            style={{
              width: `${week.dates.length * dayWidth}px`,
              textAlign: 'center',
              borderRight: '1px solid #f0f0f0',
              borderBottom: '1px solid #f0f0f0',
              position: 'relative',
              backgroundColor: week.isCurrentWeek ? '#505050' : 'transparent',
            }}
          >
            <Text
              style={{
                fontSize: '10px',
                fontWeight: 'bold',
                color: week.isCurrentWeek ? '#fff' : 'inherit',
              }}
            >
              {week.label}
            </Text>
          </div>
        ))}
        {/* Vertical line for today */}
        <div
          style={{
            position: 'absolute',
            left: `${todayOffset}px`,
            top: 30,
            bottom: -10,
            width: '3px',
            backgroundColor: 'darkred',
            pointerEvents: 'none',
          }}
        ></div>
      </div>
    );
  } else {
    // For other time units, display single-level header
    const groups = groupDatesByTimeUnit(dateRange, timeUnit);

    return (
      <div style={{ display: 'flex', position: 'relative' }}>
        {groups.map((group: any) => (
          <div
            key={group.key}
            style={{
              width: `${group.dates.length * dayWidth}px`,
              textAlign: 'center',
              borderRight: '1px solid #f0f0f0',
              borderBottom: '1px solid #f0f0f0',
              position: 'relative',
            }}
          >
            <Text style={{ fontSize: '12px', fontWeight: 'bold' }}>
              {group.label}
            </Text>
          </div>
        ))}
        {/* Vertical line for today */}
        <div
          style={{
            position: 'absolute',
            left: `${todayOffset}px`,
            top: 30,
            bottom: -10,
            width: '3px',
            backgroundColor: 'darkred',
            pointerEvents: 'none',
          }}
        ></div>
      </div>
    );
  }
};

// Helper function to group dates with original labels
const groupDatesByTimeUnit = (dates: any[], timeUnit: string) => {
  const today = dayjs();
  const groups: any[] = [];
  let currentGroup: any = null;

  dates.forEach((date) => {
    let groupKey;
    let groupLabel;

    switch (timeUnit) {
      case 'week':
        groupKey = `${date.isoWeekYear()}-W${date.isoWeek()}`;
        groupLabel = `${date.startOf('isoWeek').format('D MMM')} - ${date
          .endOf('isoWeek')
          .format('D MMM YYYY')}`;
        break;
      case 'month':
        groupKey = date.format('YYYY-MM');
        groupLabel = date.format('MMM YYYY');
        break;
      case 'quarter':
        groupKey = `${date.year()}-Q${date.quarter()}`;
        groupLabel = `Q${date.quarter()} ${date.year()}`;
        break;
      case 'year':
        groupKey = date.year();
        groupLabel = date.year();
        break;
      default:
        groupKey = date.format('YYYY-MM-DD');
        groupLabel = date.format('D MMM YYYY');
    }

    const isCurrentGroup =
      (timeUnit === 'week' &&
        date.isoWeek() === today.isoWeek() &&
        date.year() === today.year()) ||
      (timeUnit === 'month' &&
        date.month() === today.month() &&
        date.year() === today.year()) ||
      (timeUnit === 'quarter' &&
        date.quarter() === today.quarter() &&
        date.year() === today.year()) ||
      (timeUnit === 'year' && date.year() === today.year());

    if (!currentGroup || currentGroup.key !== groupKey) {
      currentGroup = {
        key: groupKey,
        label: groupLabel,
        dates: [],
        isCurrentGroup: isCurrentGroup,
      };
      groups.push(currentGroup);
    }
    currentGroup.dates.push(date);
  });

  return groups;
};

// TimelineBar component with drag-and-drop and resizing functionality
const TimelineBar = ({
  task,
  dateRange,
  dayWidth,
  timeUnit,
  todayOffset,
  scrollRef,
  onTaskUpdate,
}: any) => {
  const minDate = dateRange[0];
  const [showToolTip, setShowToolTip] = useState(false);

  const taskStart = dayjs(task.start).startOf('day');
  const taskEnd = dayjs(task.end).endOf('day');

  const startOffset = taskStart.diff(minDate, 'day');
  const taskDuration = taskEnd.diff(taskStart, 'day') + 1;

  const initialLeft = startOffset * dayWidth;
  const initialWidth = taskDuration * dayWidth;

  const [dynamicTooltipDates, setDynamicToolTipDates]: any = useState({
    start: dayjs(task.start),
    end: dayjs(task.end),
    totalDuration: dayjs(task.end).diff(dayjs(task.start), 'day') + 1,
  });

  // State to manage the controlled position and size of the task bar
  const [controlledPosition, setControlledPosition] = useState({
    x: initialLeft,
    y: 0,
  });
  const [width, setWidth] = useState(initialWidth);

  // Update position and width when task dates change
  useEffect(() => {
    const newTaskStart = dayjs(task.start).startOf('day');
    const newTaskEnd = dayjs(task.end).endOf('day');

    const newStartOffset = newTaskStart.diff(minDate, 'day');
    const newTaskDuration = newTaskEnd.diff(newTaskStart, 'day') + 1;

    setControlledPosition({ x: newStartOffset * dayWidth, y: 0 });
    setWidth(newTaskDuration * dayWidth);
  }, [task.start, task.end, minDate, dayWidth]);

  const onDragStop = (e: any, d: any) => {
    e.stopPropagation();
    const deltaDays = Math.round(d.x / dayWidth) - startOffset;

    if (deltaDays === 0) {
      return;
    }

    const newStartDate = taskStart.add(deltaDays, 'day');
    const newEndDate = taskEnd.add(deltaDays, 'day');

    setDynamicToolTipDates({
      start: newStartDate,
      end: newEndDate,
      totalDuration: newEndDate.diff(newStartDate, 'day') + 1,
    });

    onTaskUpdate(task, newStartDate, newEndDate);

    // Update the position state
    setControlledPosition({ x: d.x, y: 0 });
  };

  const onResizeStop = (
    e: any,
    direction: any,
    ref: any,
    delta: any,
    position: any
  ) => {
    e.stopPropagation();
    const deltaWidth = delta.width;
    const deltaDays = Math.round(deltaWidth / dayWidth);

    if (deltaDays === 0) {
      return;
    }

    let newStartDate = taskStart;
    let newEndDate = taskEnd;

    if (direction === 'left') {
      // Resizing from the left handle
      newStartDate = taskStart.add(-deltaDays, 'day');

      // Prevent resizing to less than one day
      if (newEndDate.diff(newStartDate, 'day') < 1) {
        newStartDate = newEndDate.add(-1, 'day');
      }
      //update position
    } else if (direction === 'right') {
      // Resizing from the right handle
      newEndDate = taskEnd.add(deltaDays, 'day');

      // Prevent resizing to less than one day
      if (newEndDate.diff(newStartDate, 'day') < 1) {
        newEndDate = newStartDate.add(1, 'day');
      }
      //update position

      setDynamicToolTipDates({
        start: newStartDate,
        end: newEndDate,
        totalDuration: newEndDate.diff(newStartDate, 'day') + 1,
      });
    }

    onTaskUpdate(task, newStartDate, newEndDate);

    // Update position and width
    setControlledPosition({ x: position.x, y: 0 });
    setWidth(ref.offsetWidth);
  };

  return (
    <div style={{ position: 'relative', height: '40px' }}>
      {/* Vertical line for today */}
      <div
        id='today'
        ref={scrollRef}
        style={{
          position: 'absolute',
          left: `${todayOffset}px`,
          top: -20,
          bottom: -5,
          width: '3px',
          backgroundColor: 'darkred',
          pointerEvents: 'none',
          zIndex: 1,
        }}
      ></div>
      <div
        style={{
          position: 'absolute',
          left: 0,
          top: '10px',
          width: `${dateRange.length * dayWidth}px`,
          height: '20px',
        }}
      >
        {/* Task Bar with Rnd */}
        <Tooltip
          open={showToolTip}
          title={
            <div>
              <div>{task.name}</div>
              <hr />
              {dynamicTooltipDates.start.format('DD MMM YYYY')} -{' '}
              {dynamicTooltipDates.end.format('DD MMM YYYY')}
              <hr />
              <div style={{ textAlign: 'center' }}>
                Starts in: {dayjs().diff(dynamicTooltipDates.start, 'day') * -1}
                d
              </div>
              <div style={{ textAlign: 'center' }}>
                Duration: {dynamicTooltipDates.totalDuration}d
              </div>
            </div>
          }
        >
          <Rnd
            size={{ width: width, height: 25 }}
            position={controlledPosition}
            onDragStop={onDragStop}
            onResizeStop={onResizeStop}
            bounds='parent'
            resizeHandleStyles={{
              left: { paddingRight: '1rem' },
            }}
            onResize={(e, direction, ref, delta, position) => {
              const deltaWidth = delta.width;
              const deltaDays = Math.round(deltaWidth / dayWidth);

              let newStartDate = taskStart;
              let newEndDate = taskEnd;

              if (deltaDays === 0) {
                return;
              }

              if (direction === 'left') {
                // Resizing from the left handle
                newStartDate = taskStart.add(-deltaDays, 'day');

                // Prevent resizing to less than one day
                if (newEndDate.diff(newStartDate, 'day') < 1) {
                  newStartDate = newEndDate.add(-1, 'day');
                }
                //update position
                setControlledPosition({ x: position.x, y: position.y });
              } else if (direction === 'right') {
                // Resizing from the right handle
                newEndDate = taskEnd.add(deltaDays, 'day');

                // Prevent resizing to less than one day
                if (newEndDate.diff(newStartDate, 'day') < 1) {
                  newEndDate = newStartDate.add(1, 'day');
                }
                //update position
                setDynamicToolTipDates({
                  start: newStartDate,
                  end: newEndDate,
                  totalDuration: newEndDate.diff(newStartDate, 'day') + 1,
                });
              }
            }}
            onDrag={(e, d) => {
              const deltaDays = Math.round(d.x / dayWidth) - startOffset;
              if (deltaDays === 0) {
                return;
              }
              const newStartDate = taskStart.add(deltaDays, 'day');
              const newEndDate = taskEnd.add(deltaDays, 'day');

              setDynamicToolTipDates({
                start: newStartDate,
                end: newEndDate,
                totalDuration: newEndDate.diff(newStartDate, 'day') + 1,
              });
            }}
            enableResizing={{
              left: true,
              right: true,
              top: false,
              bottom: false,
              topLeft: false,
              topRight: false,
              bottomLeft: false,
              bottomRight: false,
            }}
            dragAxis='x'
            resizeGrid={[dayWidth, dayWidth]}
            dragGrid={[dayWidth, dayWidth]}
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
            style={{ zIndex: 1 }}
            minWidth={dayWidth} // Prevent resizing to less than one day
          >
            <div
              style={{
                width: '100%',
                height: '80%',
                backgroundColor: 'gray',
                borderRadius: '8px',
                overflow: 'hidden',
                cursor: 'move',
                position: 'relative',
              }}
            >
              {/* Progress Bar */}
              <div
                style={{
                  width: `${task.progress}%`,
                  height: '100%',
                  backgroundColor: '#1890ff',
                }}
              ></div>
              {/* Optional: Display progress percentage as text */}
              {width > 30 && (
                <Text
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: '#fff',
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  {`${task.progress}%`}
                </Text>
              )}
            </div>
          </Rnd>
        </Tooltip>
      </div>
    </div>
  );
};

export default App;
