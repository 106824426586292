import { Alert, Button, Card, Collapse, Popconfirm, Spin } from 'antd';
import Text from 'antd/es/typography/Text';
import { api } from '../../../../api';
import { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import { useQuery } from '@tanstack/react-query';

function SettingsTab() {
  const getSlackInfo = async () => {
    const response = await api.get('/integrations/slack/details');
    return response.data;
  };

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['slack'],
    queryFn: getSlackInfo,
  });

  const removeSlack = async () => {
    try {
      await api.delete('/integrations/slack');
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Collapse>
        <Collapse.Panel header='Integrations' key='1'>
          {isLoading && <Spin />}
          {!isLoading && (
            <div>
              <Title level={4} style={{ marginTop: '.1rem' }}>
                Slack
              </Title>
              {data.enabled && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Text>"<strong>{data.slackTeamName}</strong>" is connected to this workspace.</Text>
                  </div>
                  <div>
                    <Popconfirm
                      title='Are you sure you want to disconnect Slack?'
                      onConfirm={removeSlack}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button type='primary' danger>
                        Disconnect
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              )}
              {!data.enabled && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div>
                    <Text>Connect to Slack</Text>
                  </div>
                  <div>
                    <Button
                      onClick={async () => {
                        try {
                          const response = await api.get(
                            `/integrations/slack/install/tempId`
                          );
                          window.open(
                            `${import.meta.env.VITE_API_URL}/integrations/slack/install?token=${response.data}`,
                            '_blank',
                            'noopener,noreferrer'
                          );
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      type='primary'
                    >
                      Connect to Slack
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Collapse.Panel>
      </Collapse>
      <br />
      <Collapse>
        <Collapse.Panel header='Danger Zone' key='1'>
          <Button type='primary' danger>
            Delete Organization
          </Button>
          <div style={{ marginTop: '.5rem' }}></div>
          <Alert type='warning' message='This action is NOT reversible' />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default SettingsTab;
