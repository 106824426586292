import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined, InfoCircleTwoTone } from '@ant-design/icons';

const InfoTooltip = ({
  text,
  color,
}: {
  text: string;
  color: 'orange' | 'red' | 'green' | 'blue' | 'gray';
}) => {
  return (
    <Tooltip title={text} color={color}>
      <InfoCircleTwoTone twoToneColor={color} />
    </Tooltip>
  );
};

export default InfoTooltip;
