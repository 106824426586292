import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Tooltip,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { useState } from 'react';
import { api } from '../../api';
import Text from 'antd/es/typography/Text';
import SlackChannelsDropdown from '@/components/SlackChannelsDropdown';
import { useForm } from 'antd/es/form/Form';
import AccessTree from '@/components/Access';

export default function CreateProjectModal({
  open,
  handleOk,
  handleCancel,
}: {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}) {
  const [userIds, setUserIds] = useState<string[]>([]);
  const [slackChannelId, setSlackChannelId] = useState<string | null>(null);

  const [keyAlreadyExists, setKeyAlreadyExists] = useState(false);

  const [form] = useForm();

  const checkIfKeyIsUnique = async (key: string) => {
    setKeyAlreadyExists(false);
    // Make a request to the backend to check if the key is unique
    // If it is not unique, show an alert

    try {
      await api.post(`/api/projects/${key}/check`, {
        key,
      });
    } catch (error) {
      setKeyAlreadyExists(true);
    }
  };

  const createProject = async (data: any) => {
    if (keyAlreadyExists) {
      alert('Change the key');
      return;
    }

    const { label, description = '', key, isPublic, slackChannelId } = data;

    if (!isPublic && !userIds.length) {
      alert('Please select at least one user');
      return;
    }

    await api.post('/api/projects', {
      label,
      description,
      key,
      isPublic,
      slackChannelId,
      userIds,
    });

    handleOk();
  };
  const validateInput = (_: any, value: any) => {
    const regex = /^(?!-)[a-zA-Z0-9-]+(?<!-)$/;
    if (value && !regex.test(value)) {
      return Promise.reject(
        new Error(
          'Key must include letters and/or numbers, can include hyphens, but cannot start or end with hyphens.'
        )
      );
    }
    return Promise.resolve();
  };

  return (
    <Modal
      open={open}
      title='Create Project'
      okText='Create'
      style={{top: 20}}
      cancelText='Cancel'
      onOk={createProject}
      onCancel={handleCancel}
      footer={(_, { OkBtn, CancelBtn }) => <></>}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={(data) => {
          createProject(data);
        }}
      >
        <Form.Item
          label='Label'
          name={'label'}
          rules={[{ required: true, message: 'Please input your label' }]}
        >
          <Input placeholder='Project Name' />
        </Form.Item>
        <Form.Item label='Description' name={'description'}>
          <Input placeholder='Description' />
        </Form.Item>
        <Form.Item
          label='Key'
          name={'key'}
          rules={[
            { required: true, message: 'Please input your key!' },
            { validator: validateInput },
          ]}
        >
          <Input
            onBlur={async (e) => {
              if (e.target.value.trim() === '') return;
              await checkIfKeyIsUnique(e.target.value);
            }}
          />
          {keyAlreadyExists && (
            <Text style={{ color: 'red' }} strong>
              Key already in use, please pick another one.
            </Text>
          )}
        </Form.Item>
        <Title level={5}>Access</Title>
        <Tooltip title='All users within organization have access to the project'>
          <Form.Item
            name={'isPublic'}
            valuePropName='checked'
            initialValue={true}
          >
            <Checkbox>Public</Checkbox>
          </Form.Item>
        </Tooltip>
        <div style={{ marginTop: '.5rem' }}></div>
        {!Form.useWatch('isPublic', form) && (
          <AccessTree
            onSelected={(selectedValues: any) => {
              setUserIds(selectedValues);
            }}
            userIds={userIds}
          />
        )}
        <br />
        <SlackChannelsDropdown
          onSelectChange={(channelId: string) => {
            setSlackChannelId(channelId);
          }}
          defaultChannel={slackChannelId}
        />
        <br />
        <div style={{ textAlign: 'right' }}>
          <Button onClick={handleCancel} style={{ marginRight: '.5rem' }}>
            <Text>Cancel</Text>
          </Button>
          <Button type='primary' onClick={form.submit}>
            <Text>Create</Text>
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
