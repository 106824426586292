import { Card, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import RelativeTime from 'react-relative-time';

export default function Watched() {
  const navigate = useNavigate();

  const getPointlog = async () => {
    const tasks = await api.get('/api/me/watched-tasks');

    return tasks.data.tasks;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['watchedTasks'],
    queryFn: getPointlog,
  });

  return (
    <Table
      loading={isLoading}
      size='small'
      // style={{ width: '100%' }}
      pagination={false}
      bordered
      scroll={{ y: '75vh' }}
      title={() => <Text strong>Watched tasks</Text>}
      columns={[
        {
          title: 'Key',
          dataIndex: 'taskKey',
          key: 'taskKey',
          sorter: (a: any, b: any) => a.taskKey.localeCompare(b.taskKey),
        },
        {
          title: 'Label',
          dataIndex: 'taskLabel',
          key: 'taskLabel',
        },

        {
          title: 'Status',
          dataIndex: 'columnLabel',
          key: 'columnLabel',
          sorter: (a: any, b: any) =>
            a.columnLabel.localeCompare(b.columnLabel),
        },
      ]}
      onRow={(record: any, rowIndex) => {
        return {
          onClick: (event) => {
            navigate(`?openTask=${record.taskKey}`);
          },
        };
      }}
      dataSource={data}
    />
  );
}
