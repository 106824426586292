import { Card, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { RelativeTime } from '@/components/RelativeTime';

export default function Pointlog() {
  const navigate = useNavigate();

  const getPointlog = async () => {
    const tasks = await api.get('/api/me/pointlog');

    return tasks.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['pointlog'],
    queryFn: getPointlog,
  });

  return (
    <Table
      loading={isLoading}
      size='small'
      style={{ width: '100%' }}
      pagination={false}
      scroll={{ y: '80vh' }}
      title={() => <Text strong>Point Log</Text>}
      columns={[
        {
          title: 'Key',
          dataIndex: 'taskKey',
          key: 'taskKey',
        },
        {
          title: 'Label',
          dataIndex: 'taskLabel',
          key: 'taskLabel',
        },
        {
          title: 'XP',
          dataIndex: 'points',
          key: 'points',
        },
        {
          title: 'Received',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render(value, record, index) {
            return <RelativeTime date={value} />;
          },
        },
      ]}
      onRow={(record: any, rowIndex) => {
        return {
          onClick: (event) => {
            navigate(`?openTask=${record.taskKey}`);
          },
        };
      }}
      dataSource={data}
    />
  );
}
