import { api } from '@/api';

function dataURLtoFile(dataurl: any, filename: any) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const uploadBase64AndGetKey = async (
  file: string,
  fileName: string = 'image'
) => {
  const fileToUpload = dataURLtoFile(file, fileName);

  const formData = new FormData();
  formData.append('file', fileToUpload);

  const response = await api.post('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data.fileKey;
};

export const mutateContent = async (content: any) => {
  const ct = JSON.parse(content);
  for (const node of ct.content) {
    if (node.type === 'AuthImage' && !node.attrs.imageKey) {
      node.attrs.imageKey = await uploadBase64AndGetKey(node.attrs.base64);
      delete node.attrs.base64;
    } else if (node.type === 'AuthFile' && !node.attrs.fileKey) {
      node.attrs.fileKey = await uploadBase64AndGetKey(
        node.attrs.base64,
        node.attrs.fileName
      );
      delete node.attrs.base64;
    }
  }

  return JSON.stringify(ct);
};
