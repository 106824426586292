import { Button, Card, DatePicker, Popconfirm, Select, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { api } from '../../../../api';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { getTaskPriorityIcon, getTaskTypeIcon } from '@/utils';
import dayjs from 'dayjs';

export default function BulkUpdateTask({
  taskIds,
  projectKey,
  boardKey,
  selectedTasksCount,
  onCompleted,
}: {
  taskIds: string[];
  projectKey: string;
  boardKey: string | null;
  selectedTasksCount: number;
  onCompleted: () => void;
}) {
  // i want to update the assignee, column, type

  const [selectedAssignee, setSelectedAssignee] = useState<string | null>(
    'no-user'
  );
  const [selectedColumn, setSelectedColumn] = useState<string | null>(
    'no-column'
  );
  const [selectedType, setSelectedType] = useState<string | null>('no-type');

  const [selectedPriority, setSelectedPriority] = useState<string | null>(
    'no-priority'
  );

  const [dueDate, setDueDate] = useState<string | null | undefined>(undefined);
  const [dueDateClear, setDueDateClear] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [assignees, setAssignees] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [taskTypes, setTaskTypes] = useState<any[]>([]);
  const [priorities, setPriorities] = useState<any[]>([]);

  async function getAssignees() {
    const response = await api.get(`/api/projects/${projectKey}/users`);
    setAssignees([
      { id: 'no-user', label: '/' },
      { id: null, label: 'Unassign' },
      ...response.data.map((user: any) => ({
        id: user.id,
        label: user.fullName,
      })),
    ]);
  }

  async function getColumns() {
    if (!boardKey) return;
    const response = await api.get(
      `/api/projects/${projectKey}/boards/${boardKey}/columns?withTasks=false`
    );
    setColumns([
      { id: 'no-column', label: '/' },
      ...response.data.map((column: any) => ({
        id: column.id,
        label: column.label,
      })),
    ]);
  }

  async function getTaskTypes() {
    const response = await api.get(`/api/task-types`);
    setTaskTypes([
      { id: 'no-type', label: '/' },
      ...response.data.map((taskType: any) => ({
        id: taskType.id,
        label: taskType.label,
        description: taskType.description,
      })),
    ]);
  }

  async function getPriorities() {
    const response = await api.get(`/api/priority`);

    setPriorities([
      { id: 'no-priority', label: '/' },
      ...response.data.map((priority: any) => ({
        id: priority.id,
        label: priority.label,
        description: priority.description,
      })),
    ]);
  }

  async function updateTasks() {
    setLoading(true);

    const dataToSend: any = {
      taskIds,
      changes: {},
    };

    if (selectedAssignee !== 'no-user') {
      dataToSend.changes['assigneeId'] = selectedAssignee;
    }

    if (selectedColumn !== 'no-column') {
      dataToSend.changes['columnId'] = selectedColumn;
    }

    if (selectedType !== 'no-type') {
      dataToSend.changes['taskTypeId'] = selectedType;
    }

    if (selectedPriority !== 'no-priority') {
      dataToSend.changes['priorityId'] = selectedPriority;
    }

    if (dueDate !== undefined && !dueDateClear) {
      dataToSend.changes['dueDate'] = dueDate;
    }

    if (dueDateClear) {
      dataToSend.changes['dueDate'] = null;
    }

    await api.put(`/api/bulk-update-tasks`, dataToSend);

    onCompleted();

    setLoading(false);
  }

  useEffect(() => {
    getAssignees();
    getColumns();
    getTaskTypes();
    getPriorities();
  }, [boardKey, projectKey]);

  return (
    <Card>
      <Title level={5}>Bulk Update Tasks</Title>
      <Text type='secondary' style={{ marginBottom: '.3rem' }}>
        {selectedTasksCount} tasks selected
      </Text>

      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          marginBottom: '1rem',
        }}
      >
        <div>
          <Text>Assignee</Text>
          <br />
          <Select
            value={selectedAssignee}
            style={{ minWidth: '150px' }}
            onChange={(value) => setSelectedAssignee(value)}
          >
            {assignees.map((assignee) => (
              <Select.Option key={assignee.id} value={assignee.id}>
                {assignee.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        {boardKey && (
          <div>
            <Text>Column</Text>
            <br />
            <Select
              value={selectedColumn}
              style={{ minWidth: '150px' }}
              onChange={(value) => setSelectedColumn(value)}
            >
              {columns.map((column) => (
                <Select.Option key={column.id} value={column.id}>
                  {column.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}

        <div>
          <Text>Priority</Text>
          <br />
          <Select
            value={selectedPriority}
            style={{ minWidth: '150px' }}
            onChange={(value) => setSelectedPriority(value)}
            labelRender={(value: any) => {
              return (
                <>
                  {getTaskPriorityIcon(value.label?.toLowerCase() as any)}{' '}
                  {value.label}
                </>
              );
            }}
            optionRender={(option: any) => (
              <div style={{ display: 'flex', gap: '.3rem' }}>
                {getTaskPriorityIcon(option.label?.toLowerCase() as any)}{' '}
                {option.label}
              </div>
            )}
          >
            {priorities.map((priority) => (
              <Select.Option key={priority.id} value={priority.id}>
                {priority.label}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div>
          <Text>Type</Text>
          <br />
          <Select
            value={selectedType}
            style={{ minWidth: '150px' }}
            onChange={(value) => setSelectedType(value)}
            labelRender={(option: any) => (
              <>
                {getTaskTypeIcon(option.label?.toLowerCase() as any)}{' '}
                {option.label}
              </>
            )}
            optionRender={(option: any) => (
              <div style={{ display: 'flex', gap: '.3rem' }}>
                {getTaskTypeIcon(option.label?.toLowerCase() as any)}{' '}
                {option.label}
              </div>
            )}
          >
            {taskTypes.map((taskType) => (
              <Select.Option key={taskType.id} value={taskType.id}>
                {taskType.label}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div>
          <Text>Due Date</Text>
          <br />
          <div style={{ display: 'flex', gap: '.5rem' }}>
            <div>
              <DatePicker
                style={{ width: '100%' }}
                format={'DD MMM YYYY'}
                value={dueDate ? dayjs(dueDate) : null}
                disabled={dueDateClear}
                onChange={async (value) => {
                  setDueDate(value ? value.toISOString() : null);
                }}
                showNow={false}
                presets={[
                  {
                    label: 'Today',
                    value: dayjs(),
                  },
                  {
                    label: 'Tomorrow',
                    value: dayjs().add(1, 'day'),
                  },
                  {
                    label: 'Next week',
                    value: dayjs().add(1, 'week'),
                  },
                  {
                    label: '2 weeks',
                    value: dayjs().add(2, 'week'),
                  },
                  {
                    label: '1 month',
                    value: dayjs().add(1, 'month'),
                  },
                ]}
              />
            </div>
            <div>
              Clear Due Date
              <Switch
                title='Clear Due Date'
                value={dueDateClear}
                onChange={(value) => {
                  setDueDate(undefined);
                  setDueDateClear(value);
                }}
              />
            </div>
          </div>
        </div>
        <div>
          <Text> </Text>
          <br />
          <Popconfirm
            title='Are you sure?'
            onConfirm={updateTasks}
            okText='Yes'
            cancelText='No'
            disabled={
              selectedAssignee === 'no-user' &&
              selectedColumn === 'no-column' &&
              selectedType === 'no-type' &&
              selectedPriority === 'no-priority' &&
              dueDate === undefined &&
              !dueDateClear
            }
          >
            <Button
              disabled={
                selectedAssignee === 'no-user' &&
                selectedColumn === 'no-column' &&
                selectedType === 'no-type' &&
                selectedPriority === 'no-priority' &&
                dueDate === undefined &&
                !dueDateClear
              }
            >
              Bulk Update
            </Button>
          </Popconfirm>
        </div>
      </div>
    </Card>
  );
}
