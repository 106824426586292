import {
  Badge,
  Button,
  Drawer,
  Dropdown,
  Popconfirm,
  Tag,
  Tooltip,
  message,
} from 'antd';

import Text from 'antd/es/typography/Text';
import {
  CheckCircleOutlined,
  DatabaseOutlined,
  DeleteOutlined,
  NodeIndexOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Droppable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { api } from '../../../../../api';
import { useBoardStore } from '../../../../../state/boardState';
import { useProjectStore } from '../../../../../state/projectState';
import CreateTaskModal from '../../../../../modals/createTaskModal';
import EditColumnModal from './EditCoulmn';
import TaskDetailsModal2 from '../../../../../modals/taskModal';
import TaskCard from './Task';
import { useUserStore } from '@/state/userState';
import { useMyTheme } from '@/hooks/useTheme';
import { useThemeModeStore } from '@/state/themeState';
import TaskDetailsMain from '@/components/TaskDetailsMain';
import { useOptionStore } from '@/state/options';

export const Column = ({
  column,
  assigneeFilter,
  priorityFilter,
  filterType,
  searchTerm,
  refetch,
  realTime,
  width,
  handle,
}: {
  column: any;
  assigneeFilter: any;
  priorityFilter: any;
  filterType: any;
  searchTerm: any;
  refetch: any;
  realTime: boolean;
  width: string;
  handle: any;
}) => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [defaultColumnId, setDefaultColumnId] = useState('');

  const [showAutomations, setShowAutomations] = useState(false);

  const [showUpdateColumn, setShowUpdateColumn] = useState(false);

  const [createTaskModalOpen, setCreateTaskModalOpen] = useState(false);
  const [showTaskDetailsModal, setShowTaskDetailsModal] = useState(false);

  const { projectKey, boardKey, taskId } = useParams();

  const userStore = useUserStore();

  const optionStore = useOptionStore();

  useEffect(() => {
    if (projectKey && boardKey && taskId) {
      column.items.forEach((item: any) => {
        if (item.key === taskId) {
          navigate(`/project/${projectKey}/board/${boardKey}/task/${taskId}`);
          if (optionStore.options.openTaskInDrawer) {
            optionStore.setTaskOpen(true);
          }
          setShowTaskDetailsModal(true);
        }
      });
    }
  }, [taskId]);

  const themeStore = useThemeModeStore();

  return (
    <div key={column.id}>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.3rem',
          }}
        >
          <Text
            strong
            style={{ fontSize: 14 }}
            {...(userStore.user?.isAdmin || userStore.user?.isManager
              ? handle
              : {})}
          >
            {column.label}{' '}
          </Text>
          <Badge
            color={column.type === 'done' ? 'green' : 'blue'}
            style={{
              color: 'white',
            }}
            count={
              column.items
              .sort((a: any, b: any) => a?.orderKey?.localeCompare(b.orderKey))
                .filter((i: any) => {
                  //assignee filter
                  if (assigneeFilter.length > 0) {
                    return assigneeFilter.includes(i.assigneeId);
                  }
                  return true;
                })
                .filter((i: any) => {
                  if (filterType === 'all') return true;
                  return i.taskTypeId === filterType;
                })
                .filter((i: any) => {
                  if (searchTerm === '') return true;
                  return (
                    i.label
                      .toLowerCase()
                      .includes(searchTerm.toLocaleLowerCase()) ||
                    i.key
                      .toLowerCase()
                      .includes(searchTerm.toLocaleLowerCase()) ||
                    i.assignee
                      ?.toLowerCase()
                      .includes(searchTerm.toLocaleLowerCase()) ||
                    i.description
                      .toLowerCase()
                      .includes(searchTerm.toLocaleLowerCase())
                  );
                }).length
            }
            showZero
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.3rem',
          }}
        >
          {column.hasAutomation && (
            <Tooltip title='Has automation rules'>
              <Tag
                onClick={() => {
                  setShowAutomations(true);
                  setShowUpdateColumn(true);
                }}
                color='geekblue-inverse'
                bordered={false}
              >
                A
              </Tag>
            </Tooltip>
          )}
          <Button
            onClick={async () => {
              setCreateTaskModalOpen(true);
              setDefaultColumnId(column.id);
            }}
            type='link'
            icon={<PlusOutlined />}
          />
          {(userStore.user?.isAdmin || userStore.user?.isManager) && (
            <Dropdown
              menu={{
                onClick: (e) => {
                  e.domEvent.stopPropagation();
                },
                items: [
                  {
                    label: (
                      <Text>
                        <div
                          style={{
                            display: 'flex',
                            gap: '0.5rem',
                          }}
                        >
                          <SettingOutlined />
                          Edit
                        </div>
                      </Text>
                    ),
                    onClick: async () => {
                      setShowAutomations(false);
                      setShowUpdateColumn(true);
                    },
                    key: '1',
                  },
                  {
                    type: 'divider',
                  },
                  column.type === 'done'
                    ? {
                        label: (
                          <Popconfirm
                            title='Are you sure?'
                            onConfirm={async () => {
                              try {
                                await api.put(
                                  `/api/columns/${column.id}/complete`
                                );

                                // await refetch();
                                messageApi.success('Tasks Completed!');
                              } catch (error) {
                                console.log(error, ':(');
                              }
                            }}
                          >
                            <Text
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: '0.5rem',
                                }}
                              >
                                <CheckCircleOutlined
                                  style={{ color: 'green' }}
                                />
                                Complete Tasks & Award Points
                              </div>
                            </Text>
                          </Popconfirm>
                        ),
                        key: '4',
                        onClick: async () => {},
                      }
                    : {
                        type: 'group',
                      },
                  column.type !== 'done'
                    ? {
                        label: (
                          <Popconfirm
                            title='Are you sure?'
                            onConfirm={async () => {
                              try {
                                await api.delete(`/api/columns/${column.id}`);

                                // await refetch();
                                messageApi.success('Column Deleted!');
                              } catch (error) {
                                console.log(error, ':(');
                              }
                            }}
                          >
                            <Text
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: '0.5rem',
                                }}
                              >
                                <DeleteOutlined />
                                Delete
                              </div>
                            </Text>
                          </Popconfirm>
                        ),
                        danger: true,
                        key: '2',
                        onClick: async () => {},
                      }
                    : {
                        type: 'group',
                      },
                ],
              }}
              trigger={['click']}
            >
              <Button
                onClick={(e) => e.stopPropagation()}
                type='link'
                size='small'
              >
                <SettingOutlined />
              </Button>
            </Dropdown>
          )}
        </div>
      </div>

      <Droppable droppableId={column.id}>
        {(provided, snapshot) => (
          <div
            className={`column ${
              snapshot.isDraggingOver ? 'draggingOver' : ''
            }`}
            ref={provided.innerRef}
            style={{
              background: snapshot.isDraggingOver
                ? column.type === 'done'
                  ? 'darkseagreen'
                  : undefined
                : undefined,
              width,
              minWidth: 150,
              maxWidth: 340,
              padding: 0,
              border: `1px solid ${
                themeStore.theme === 'dark' ? '#444' : 'lightgray'
              }`,
            }}
            {...provided.droppableProps}
          >
            {column.items
              .filter((i: any) => {
                //assignee filter
                if (assigneeFilter.length > 0 || priorityFilter.length > 0) {
                  return (
                    assigneeFilter.includes(i.assigneeId) ||
                    priorityFilter.includes(i.priorityId)
                  );
                }
                return true;
              })
              .filter((i: any) => {
                if (filterType === 'all') return true;
                return i.taskTypeId === filterType;
              })
              .filter((i: any) => {
                if (searchTerm === '') return true;
                return (
                  i.label
                    .toLowerCase()
                    .includes(searchTerm.toLocaleLowerCase()) ||
                  i.key
                    .toLowerCase()
                    .includes(searchTerm.toLocaleLowerCase()) ||
                  i.assignee
                    ?.toLowerCase()
                    .includes(searchTerm.toLocaleLowerCase()) ||
                  i.description
                    .toLowerCase()
                    .includes(searchTerm.toLocaleLowerCase())
                );
              })
              .map((item: any, index: number) => (
                <TaskCard
                  item={item}
                  index={index}
                  key={item.key}
                  setShowTaskDetailsModal={setShowTaskDetailsModal}
                  refetch={refetch}
                  messageApi={messageApi}
                  column={column}
                />
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {createTaskModalOpen && (
        <CreateTaskModal
          setShowNewTaskForm={setCreateTaskModalOpen}
          showNewTaskForm={createTaskModalOpen}
          defaultColumnId={defaultColumnId}
          onCreateTask={async () => {
            await refetch();
            setDefaultColumnId('');
            messageApi.success('Task Created');
          }}
        />
      )}
      {showTaskDetailsModal &&
        taskId &&
        optionStore.options.openTaskInDrawer && (
          <Drawer
            open={showTaskDetailsModal}
            width={'850px'}
            mask={false}
            // getContainer={false}
            onClose={() => {
              setShowTaskDetailsModal(false);
              optionStore.setTaskOpen(false);
              navigate(`/project/${projectKey}/board/${boardKey}`);
            }}
          >
            <TaskDetailsMain
              taskKey={taskId}
              onUpdateTask={async () => {
                if (!realTime) {
                  await refetch();
                }
              }}
            />
          </Drawer>
        )}
      {showTaskDetailsModal &&
        taskId &&
        !optionStore.options.openTaskInDrawer && (
          <TaskDetailsModal2
            taskKey={taskId ?? ''}
            showTaskDetailsModal={showTaskDetailsModal}
            setShowTaskDetailsModal={setShowTaskDetailsModal}
            onUpdateTask={async () => {
              if (!realTime) {
                await refetch();
              }
            }}
            onCancel={() => {
              setShowTaskDetailsModal(false);
              navigate(`/project/${projectKey}/board/${boardKey}`);
            }}
          />
        )}
      {showUpdateColumn && (
        <EditColumnModal
          column={column}
          onClose={() => {
            setShowUpdateColumn(false);
            setShowAutomations(false);
          }}
          refetch={refetch}
          setShowNewColumnForm={setShowUpdateColumn}
          showNewColumnForm={showUpdateColumn}
          showOnlyAutomations={showAutomations}
        />
      )}
    </div>
  );
};
