import { Button, Divider, Input, Select, Spin } from 'antd';
import { api } from '../../api';
import TipTapEditor from '../Editor/index3';
import { Skeleton, message } from 'antd';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import Comment from './comment';
import { mutateContent, uploadBase64AndGetKey } from '@/utils/uploadUtils';
import Text from 'antd/es/typography/Text';
import { sseClient } from '@/api/realtime';

function TaskComments({
  taskId,
  projectKey,
}: {
  taskId: string;
  projectKey: string;
}) {
  const queryClient = useQueryClient();
  const [viewAllComments, setViewAllComments] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const {
    data: comments,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['comments', taskId],
    queryFn: () =>
      api.get(`/api/tasks/${taskId}/comments`).then((res) => res.data),
  });

  // useEffect(() => {
  //   const jwt = localStorage.getItem('token');
  //   const eventSource = new EventSource(
  //     `${import.meta.env.VITE_API_URL}/sse/task/${taskId}/comments`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${jwt}`,
  //       },
  //     }
  //   );
  //   eventSource.onmessage = (event) => {
  //     if (event.data === '') {
  //       return;
  //     }
  //     const newEvent = JSON.parse(JSON.parse(event.data));
  //     if (newEvent.action === 'comment_updated') {
  //       const updatedComment = newEvent.payload;
  //       queryClient.setQueryData(['comments', taskId], (comments: any[]) =>
  //         comments.map((comment) =>
  //           comment.id === updatedComment.id
  //             ? { ...comment, ...updatedComment }
  //             : comment
  //         )
  //       );
  //     }
  //     if (newEvent.action === 'comment_created') {
  //       const newComment = newEvent.payload;
  //       console.log(newComment, 'new comment');
  //       queryClient.setQueryData(['comments', taskId], (comments: any[]) => [
  //         ...comments,
  //         newComment,
  //       ]);
  //     }
  //     if (newEvent.action === 'comment_deleted') {
  //       const deletedComment = newEvent.payload;
  //       queryClient.setQueryData(['comments', taskId], (comments: any[]) =>
  //         comments.filter((comment) => comment.id !== deletedComment.id)
  //       );
  //     }
  //   };
  //   eventSource.onerror = (err) => {
  //     console.log(err, 'error: event source');
  //     eventSource.close();
  //   };
  //   return () => {
  //     eventSource.close();
  //   };
  // }, [taskId]);

  const handleCommentCreated = async (event: any) => {
    const newEvent = JSON.parse(JSON.parse(event.data));

    const newComment = newEvent.payload;

    const taskId = newEvent.payload.taskId;

    queryClient.setQueryData(['comments', taskId], (comments: any[]) => [
      ...comments,
      newComment,
    ]);
  };

  const handleCommentUpdated = (event: any) => {
    const newEvent = JSON.parse(JSON.parse(event.data));
    const taskId = newEvent.payload.taskId;
    const updatedComment = newEvent.payload;
    queryClient.setQueryData(['comments', taskId], (comments: any[]) =>
      comments.map((comment) =>
        comment.id === updatedComment.id
          ? { ...comment, ...updatedComment }
          : comment
      )
    );
  };

  const handleUpdateReaction = (event: any) => {
    const newEvent = JSON.parse(JSON.parse(event.data));
    const taskId = newEvent.payload.taskId;
    const updatedComment = newEvent.payload;
    queryClient.setQueryData(['comments', taskId], (comments: any[]) =>
      comments.map((comment) =>
        comment.id === updatedComment.id ? updatedComment : comment
      )
    );
  };

  const handleCommentDeleted = (event: any) => {
    const newEvent = JSON.parse(JSON.parse(event.data));
    const taskId = newEvent.payload.taskId;
    const deletedComment = newEvent.payload;
    queryClient.setQueryData(['comments', taskId], (comments: any[]) =>
      comments.filter((comment) => comment.id !== deletedComment.commentId)
    );
  };

  useEffect(() => {
    sseClient.addEventListener('comment_created', handleCommentCreated);

    sseClient.addEventListener('comment_updated', handleCommentUpdated);

    sseClient.addEventListener('comment_deleted', handleCommentDeleted);

    sseClient.addEventListener('reaction_added', handleUpdateReaction);

    sseClient.addEventListener('reaction_removed', handleUpdateReaction);

    return () => {
      sseClient.removeEventListener('comment_created', handleCommentCreated);
      sseClient.removeEventListener('comment_updated', handleCommentUpdated);
      sseClient.removeEventListener('comment_deleted', handleCommentDeleted);
      sseClient.removeEventListener('reaction_added', handleUpdateReaction);
      sseClient.removeEventListener('reaction_removed', handleUpdateReaction);
    };
  }, []);

  const handleAddComment = async (content: any) => {
    try {
      setIsCreating(true);
      await api.post(`/api/tasks/${taskId}/comments`, {
        content: await mutateContent(content),
      });
      setIsCreating(false);
      // refetch();
    } catch (error) {
      setIsCreating(false);
      console.error(error);
    }
  };

  const handleDeleteComment = (commentId: string) => {
    queryClient.setQueryData(['comments', taskId], (comments: any[]) =>
      comments.filter((comment: any) => comment.id !== commentId)
    );
  };

  // all or last 3 comments
  const presentedComments = viewAllComments ? comments : comments?.slice(-3);

  return (
    <div>
      {isLoading ? (
        <Skeleton active />
      ) : presentedComments.length === 0 ? (
        <div style={{ margin: '1rem 0' }}>
          <Text>No comments</Text>
        </div>
      ) : (
        <div>
          {comments.length > 3 && (
            <div style={{ margin: '1rem 0' }}>
              <Button
                type='link'
                onClick={() => setViewAllComments(!viewAllComments)}
              >
                {viewAllComments
                  ? 'Hide'
                  : `View more comments (${comments.length - 3})`}
              </Button>
            </div>
          )}
          {presentedComments.map((comment: any) => (
            <Comment
              key={comment.id}
              comment={comment}
              onDelete={() => handleDeleteComment(comment.id)}
              projectKey={projectKey}
            />
          ))}
        </div>
      )}
      <Spin spinning={isCreating}>
        <TipTapEditor
          saveText='Add Comment'
          clearOnSave
          onSave={handleAddComment}
          projectKey={projectKey}
          defaultContent={null}
        />
      </Spin>
    </div>
  );
}

export default TaskComments;
