import { api } from '../../api';

import { Modal } from 'antd';
import TaskDetailsMain from '@/components/TaskDetailsMain';

export default function TaskDetailsModal2({
  taskKey,
  setShowTaskDetailsModal,
  showTaskDetailsModal,
  onUpdateTask,
  onCancel,
}: {
  taskKey: string;
  setShowTaskDetailsModal: any;
  showTaskDetailsModal: any;
  onUpdateTask: any;
  onCancel: any;
}) {
  return (
    <Modal
      width={'75vw'}
      open={showTaskDetailsModal}
      // closable={false}
      style={{ top: 10 }}
      destroyOnClose
      onCancel={() => {
        setShowTaskDetailsModal(false);
        onCancel();
      }}
      footer={() => {
        return <div></div>;
      }}
    >
      <TaskDetailsMain taskKey={taskKey} onUpdateTask={onUpdateTask} />
    </Modal>
  );
}
