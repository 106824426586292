import { api } from '@/api';
import Editor from '@/components/Editor/index3';
import QuickChangeAssignee from '@/components/QuickChangeAssignee';
import AddOrCreateTask from '@/pages/quests/addOrCreateTask';
import { useUserStore } from '@/state/userState';
import {
  CheckCircleOutlined,
  DeleteOutlined,
  MoreOutlined,
  PlusCircleFilled,
} from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tag,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function QuestModal({
  quest,
  open,
  setOpen,
  onClose,
}: {
  quest: {
    id: string;
    name: string;
    description: string;
    start: string;
    end: string;
  };
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
}) {
  const [selectedQuestId, setSelectedQuestId] = useState<string | null>(null);
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate]: any = useState();
  const [endDate, setEndDate]: any = useState();
  const [questTasks, setQuestTasks] = useState([] as any[]);
  const [showAddTask, setShowAddTask] = useState(false);

  const { projectKey } = useParams();

  const [editorOpen, setEditorOpen] = useState(false);

  const updateQuest = async () => {
    await api.put(`/api/quests/${selectedQuestId}`, {
      label,
      description,
      startDate: startDate ? startDate.format('YYYY-MM-DD') : undefined,
      endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
    });
  };

  const navigate = useNavigate();

  const getQuestTasks = async (questId: string) => {
    const { data } = await api.get(`/api/quests/${questId}/tasks`);
    setQuestTasks(data);
  };

  const queryClient = useQueryClient();

  const { user } = useUserStore();

  useEffect(() => {
    if (quest) {
      setSelectedQuestId(quest.id);
      setLabel(quest.name);
      setDescription(quest.description);
      if (quest.start) {
        setStartDate(dayjs(quest.start, 'YYYY-MM-DD'));
      }
      if (quest.end) {
        setEndDate(dayjs(quest.end, 'YYYY-MM-DD'));
      }
      getQuestTasks(quest.id);
    } else {
      setSelectedQuestId(null);
      setLabel('');
      setDescription('');
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [quest]);

  const hasPerms = user?.isAdmin || user?.isManager;

  const modalLabel = selectedQuestId ? 'Update quest' : 'Create new quest';

  return (
    <Modal
      open={open}
      width={'60vw'}
      style={{ top: 20 }}
      title={modalLabel}
      onCancel={() => {
        setLabel('');
        setDescription('');
        setStartDate(undefined);
        setEndDate(undefined);
        setQuestTasks([]);
        setOpen(false);
        setSelectedQuestId(null);
        onClose();
      }}
      onClose={() => {
        setLabel('');
        setDescription('');
        setStartDate(undefined);
        setEndDate(undefined);
        setQuestTasks([]);
        setOpen(false);
        onClose();
      }}
      onOk={
        hasPerms
          ? async () => {
              await updateQuest();
              onClose();
            }
          : () => {
              onClose();
            }
      }
    >
      <div style={{ marginTop: '1rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div></div>
          <div>
            {selectedQuestId && hasPerms && (
              <Space>
                <Popconfirm
                  title='Are you sure you want to complete this quest?'
                  onConfirm={async () => {
                    await api.put(`/api/quests/${selectedQuestId}/complete`);
                    onClose();
                    setLabel('');
                    setDescription('');
                    setStartDate(undefined);
                    setEndDate(undefined);
                    setQuestTasks([]);
                    setSelectedQuestId(null);
                  }}
                >
                  <Button size='small'>
                    <CheckCircleOutlined />
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title='Are you sure you want to delete this quest?'
                  description="This action can't be undone"
                  onConfirm={async () => {
                    await api.delete(`/api/quests/${selectedQuestId}`);
                    onClose();
                    setLabel('');
                    setDescription('');
                    setStartDate(undefined);
                    setEndDate(undefined);
                    setQuestTasks([]);
                    setSelectedQuestId(null);
                  }}
                >
                  <Button danger size='small'>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Space>
            )}
          </div>
        </div>
        <div>
          <Input
            value={label}
            disabled={!hasPerms}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            placeholder='Quest name'
          />
        </div>
        <br />
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div>
            <DatePicker
              format={{
                format: 'DD.MM.YYYY',
                type: 'mask',
              }}
              value={startDate ? dayjs(startDate) : null}
              disabled={!hasPerms}
              placeholder='Start Date'
              onChange={(date) => {
                setStartDate(date);
              }}
              maxDate={endDate ? endDate : undefined}
            />
          </div>
          <div>
            <DatePicker
              format={{
                format: 'DD.MM.YYYY',
                type: 'mask',
              }}
              minDate={startDate ? startDate : undefined}
              value={endDate ? dayjs(endDate) : null}
              disabled={!hasPerms}
              placeholder='End Date'
              onChange={(date) => {
                setEndDate(date);
              }}
            />
          </div>
        </div>

        <br />
        <p>Summary</p>
        <div
          onClick={() => {
            setEditorOpen(true);
          }}
          onBlur={() => {
            setEditorOpen(false);
          }}
          style={{
            border: '1px solid #d9d9d9',
            borderRadius: '8px',
            padding: '5px',
          }}
        >
          <Editor
            defaultContent={description}
            onChange={setDescription}
            editable={editorOpen && hasPerms}
            disableMentions
          />
        </div>
        <br />
        {hasPerms && (
          <Button
            disabled={!hasPerms}
            onClick={() => {
              setShowAddTask(!showAddTask);
            }}
            icon={<PlusCircleFilled />}
          >
            Add Task
          </Button>
        )}
        {showAddTask && hasPerms && (
          <>
            <br />
            <br />
            <AddOrCreateTask
              questId={selectedQuestId ?? ''}
              refetch={() => {
                getQuestTasks(selectedQuestId ?? '');
              }}
            />
            <br />
          </>
        )}
        <br />
        {selectedQuestId && (
          <Table
            size='small'
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(`?openTask=${record.taskKey}`);
                },
              };
            }}
            columns={[
              { title: '', dataIndex: 'taskKey', key: 'taskKey' },
              { title: 'Task', dataIndex: 'taskLabel', key: 'taskLabel' },
              { title: 'Board', dataIndex: 'boardLabel', key: 'description' },
              {
                title: 'Status',
                dataIndex: 'columnLabel',
                key: 'dueDate',
                render(value, record, index) {
                  return (
                    <>
                      <Tag>{record.columnType}</Tag>
                      {value}
                    </>
                  );
                },
              },
              {
                title: 'Asignee',
                dataIndex: 'dueDate',
                key: 'dueDate',
                render(value, record, index) {
                  return (
                    <div style={{ textAlign: 'center' }}>
                      <QuickChangeAssignee
                        taskId={record.taskId}
                        currentAssigneeId={record.assigneeId}
                        projectKey={projectKey ?? ''}
                        avatarSize={33}
                        disabled
                        showToolTip={true}
                      />
                    </div>
                  );
                },
              },
              {
                title: '',
                dataIndex: 'id',
                key: 'id',
                render(value, record, index) {
                  return (
                    <Popconfirm
                      title='Are you sure you want to detach this task?'
                      onConfirm={async (e) => {
                        e?.stopPropagation();
                        await api.put(`/api/tasks/${record.taskId}`, {
                          questId: 'remove',
                        });
                        await getQuestTasks(selectedQuestId);
                        await queryClient.invalidateQueries({
                          queryKey: ['availableTasks', selectedQuestId],
                        });
                      }}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        type='link'
                        danger
                      >
                        Detach
                      </Button>
                    </Popconfirm>
                  );
                },
              },
            ]}
            dataSource={questTasks}
          />
        )}
      </div>
    </Modal>
  );
}
