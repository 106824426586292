import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import Component from './Component.jsx';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    authFile: {
      setAuthFile: (
        fileKey: string | null,
        fileName: string,
        base64: any
      ) => ReturnType;
    };
  }
}

export const AuthFile = Node.create({
  name: 'AuthFile',

  group: 'block',

  atom: true,

  addAttributes() {
    return {
      fileKey: {
        default: 'no-image',
      },
      fileName: {
        default: 'no-image',
      },
      base64: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'auth-file',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['auth-file', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  addCommands(): Partial<any> {
    return {
      setAuthFile:
        (fileKey: string | null, fileName: string, base64: any) =>
        ({ commands }: any) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              fileKey,
              fileName,
              base64,
            },
          });
        },
    };
  },
});
