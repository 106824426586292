import { useQuery } from '@tanstack/react-query';
import { AutoComplete, List, Select, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { api } from '../../api';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';

export default function SlackChannelsDropdown({
  defaultChannel,
  onSelectChange,
  hideLabel,
}: {
  defaultChannel: string | null;
  onSelectChange: (channelId: string) => void;
  hideLabel?: boolean;
}) {
  const [slackEnabled, setSlackEnabled] = useState(false);

  const getChannels = async () => {
    const response = await api.get('/integrations/slack/channels');

    return response.data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['slackChannels'],
    queryFn: getChannels,
  });

  useEffect(() => {
    if (data?.slackEnabled) {
      setSlackEnabled(true);
    }
  }, [data]);

  return (
    <div>
      {slackEnabled && data && (
        <div>
          {!hideLabel && <Text>Slack Channel</Text>}
          <Select
            showSearch
            size='small'
            options={[
              {
                label: 'No Channel',
                value: null,
              },
              ...data?.channels,
            ]}
            style={{ width: '100%' }}
            defaultValue={defaultChannel ?? null}
            optionRender={(option) => {
              if (option.value === null) {
                return (
                  <div>
                    <Text>No Channel</Text>
                  </div>
                );
              }
              return (
                <div>
                  <Text>{option.label}</Text>
                </div>
              );
            }}
            filterOption={(inputValue, option) =>
              option?.label?.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            onSelect={(value, option: any) => {
              onSelectChange(value);
            }}
          />
        </div>
      )}
    </div>
  );
}
