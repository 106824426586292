import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import Component from './Component.jsx';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    authImage: {
      setAuthImage: (imageKey: string | null, base64: any) => ReturnType;
    };
  }
}

export const AuthImage = Node.create({
  name: 'AuthImage',
  group: 'block',
  atom: true,
  addAttributes() {
    return {
      imageKey: {
        default: 'no-image',
      },
      base64: {
        default: null,
      },
      width: {
        default: null,
      },
      height: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'auth-image',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['auth-image', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },

  addCommands(): Partial<any> {
    return {
      setAuthImage:
        (imageKey: string | null, base64: any) =>
        ({ commands }: any) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              imageKey,
              base64,
            },
          });
        },
    };
  },
});
