import { create } from 'zustand';

interface Options {
  openTaskInDrawer: boolean;
  fontSize: number;
}

interface OptionsStore {
  options: Options;
  taskOpen: boolean;
  setOption: (user: Options) => void;
  setTaskOpen: (taskOpen: boolean) => void;
}

export const useOptionStore = create<OptionsStore>()((set) => ({
  options: { openTaskInDrawer: false, fontSize: 13 },
  taskOpen: false,
  setOption: (options: Options) => set(() => ({ options })),
  setTaskOpen: (taskOpen: boolean) => set(() => ({ taskOpen })),
  setFontSize: (fontSize: number) => set((o) => ({ ...o, fontSize })),
}));
