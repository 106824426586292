import { api } from '@/api';
import { getTaskPriorityIcon, getTaskTypeIcon } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import { DatePicker, Divider, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export default function TableHeader({ onFilterChange }: any) {
  const [selectedProjects, setSelectedProjects]: any = useState([]);
  const [selectedStatuses, setSelectedStatuses]: any = useState([]);
  const [selectedType, setSelectedType]: any = useState([]);
  const [selectedPriorities, setSelectedPriorities]: any = useState([]);

  const [dueDateFilter, setDueDateFilter] = useState(null) as any;
  const [dueDateValue, setDueDateValue] = useState<string | null>(null);
  const [customDate, setCustomDate] = useState(null) as any;

  const { data: myProjects, isLoading: loadingMyProjects } = useQuery({
    queryKey: ['myProjects'],
    queryFn: async () => {
      const projects = await api.get('/api/projects');
      return projects.data;
    },
  });

  useEffect(() => {
    onFilterChange({
      projectIds: selectedProjects.length ? selectedProjects : undefined,
      statusIds: selectedStatuses.length ? selectedStatuses : undefined,
      typeIds: selectedType.length ? selectedType : undefined,
      priorityIds: selectedPriorities.length ? selectedPriorities : undefined,
      dueDate: dueDateFilter,
    });
  }, [
    selectedProjects,
    selectedStatuses,
    selectedType,
    selectedPriorities,
    dueDateFilter,
  ]);

  return (
    <div style={{ marginTop: '.5rem' }}>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div>
          <Select
            style={{ width: 200 }}
            placeholder='Filter by Project'
            mode='multiple'
            onChange={(value: any) => setSelectedProjects(value)}
            loading={loadingMyProjects}
            allowClear
            options={
              myProjects?.map((project: any) => ({
                label: project.label,
                value: project.id,
              })) || []
            }
          />
        </div>
        <div>
          <Select
            style={{ width: 200 }}
            placeholder='Filter by Status'
            mode='multiple'
            onChange={(value: any) => setSelectedStatuses(value)}
            allowClear
            options={[
              {
                label: 'To Do',
                value: 'j82senyrwaaza0m8omtn6jgu',
              },
              {
                label: 'In Progress',
                value: 'gt2i39u4ierremke4g926x0x',
              },
              {
                label: 'Done',
                value: 'b7p0jiayd4tjnp5zkw5qo06w',
              },
            ]}
          />
        </div>
        <div>
          <Select
            style={{ width: 200 }}
            placeholder='Filter by Priority'
            mode='multiple'
            onChange={(value: any) => setSelectedPriorities(value)}
            allowClear
            labelRender={(value: any) => {
              return (
                <>
                  {getTaskPriorityIcon(value.label?.toLowerCase() as any)}{' '}
                  {value.label}
                </>
              );
            }}
            optionRender={(option: any) => (
              <div>
                {getTaskPriorityIcon(option.label?.toLowerCase() as any)}{' '}
                {option.label}
              </div>
            )}
            options={[
              {
                label: 'Lowest',
                value: 'dvxt57fk0jdot8hap0e8ypx2',
              },
              {
                label: 'Low',
                value: 'a7szfdlji72zq2cxi5wyu31h',
              },
              {
                label: 'Normal',
                value: 'tdkcw9in2xgbq1lrpd4phsmk',
              },
              {
                label: 'High',
                value: 'mdlejnloz445oinelehbnx90',
              },
              {
                label: 'Highest',
                value: 'xsuqk8z7qx3uoykyih8l2wzz',
              },
            ]}
          />
        </div>
        <div>
          <Select
            style={{ width: 200 }}
            placeholder='Filter by Type'
            mode='multiple'
            allowClear
            labelRender={(value: any) => {
              return (
                <>
                  {getTaskTypeIcon(value.value?.toLowerCase() as any)}{' '}
                  {value.label}
                </>
              );
            }}
            optionRender={(option: any) => (
              <div style={{ display: 'flex', gap: '.3rem' }}>
                {getTaskTypeIcon(option.label?.toLowerCase() as any)}{' '}
                {option.label}
              </div>
            )}
            onChange={(value: any) => setSelectedType(value)}
            options={[
              {
                label: 'Task',
                value: 'ttkefwc4qtaaogcxrkyu98x0',
              },
              {
                label: 'Bug',
                value: 'tzz06j7svw51umjdd0ov5ey8',
              },
              {
                label: 'Story',
                value: 'w38qzcuzxm81h4jarp4aog9g',
              },
            ]}
          />
        </div>
        <div>
          <div>
            <Select
              allowClear
              style={{ width: 180 }}
              placeholder='Due Until'
              value={
                customDate
                  ? dayjs(customDate).format('DD MMM YYYY')
                  : dueDateValue
              }
              onChange={(value) => {
                switch (value) {
                  case 'overdue':
                    setDueDateFilter({
                      lte: dayjs().toISOString(),
                    });
                    break;
                  case 'today':
                    setDueDateFilter({
                      gte: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                      lte: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                    });
                    break;
                  case 'tomorrow':
                    setDueDateFilter({
                      gte: dayjs()
                        .add(1, 'day')
                        .startOf('day')
                        .format('YYYY-MM-DD HH:mm:ss'),
                      lte: dayjs()
                        .add(1, 'day')
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    });
                    break;
                  case 'this_week':
                    setDueDateFilter({
                      gte: dayjs()
                        .startOf('isoWeek')
                        .startOf('day')
                        .format('YYYY-MM-DD HH:mm:ss'),
                      lte: dayjs()
                        .endOf('isoWeek')
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    });
                    break;
                  case 'next_week':
                    setDueDateFilter({
                      gte: dayjs()
                        .add(1, 'week')
                        .startOf('isoWeek')
                        .startOf('day')
                        .format('YYYY-MM-DD HH:mm:ss'),
                      lte: dayjs()
                        .add(1, 'week')
                        .endOf('isoWeek')
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    });
                    break;
                  case '14_days':
                    setDueDateFilter({
                      gte: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                      lte: dayjs()
                        .add(2, 'week')
                        .endOf('day')
                        .format('YYYY-MM-DD HH:mm:ss'),
                    });
                    break;
                  case 'custom':
                    if (!value) {
                      setDueDateFilter(null);
                      setDueDateValue(null);
                      setCustomDate(null);
                    }
                    break;
                  default:
                    setDueDateFilter(null);
                    break;
                }
                setDueDateValue(value);
                setCustomDate(null);
              }}
              dropdownRender={(menu) => {
                return (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 0' }} />
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        padding: 8,
                      }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        variant={'borderless'}
                        format={'DD MMM YYYY'}
                        value={customDate}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(value) => {
                          if (!value) {
                            setDueDateFilter(null);
                            setDueDateValue(null);
                            setCustomDate(null);
                            return;
                          }
                          setDueDateFilter({
                            lte: dayjs(value)
                              .endOf('day')
                              .format('YYYY-MM-DD HH:mm:ss'),
                          });
                          setDueDateValue('custom');
                          setCustomDate(value);
                        }}
                        showNow={false}
                      />
                    </div>
                  </div>
                );
              }}
            >
              <Select.Option value='overdue'>Overdue</Select.Option>
              <Select.Option value='today'>Today</Select.Option>
              <Select.Option value='tomorrow'>Tomorrow</Select.Option>
              <Select.Option value='this_week'>This Week</Select.Option>
              <Select.Option value='next_week'>Next Week</Select.Option>
              <Select.Option value='14_days'>Next 14 days</Select.Option>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}
