import { create } from 'zustand';

const currentTheme = (localStorage.getItem('theme') ?? 'light') as
  | 'light'
  | 'dark';

interface ThemeModeStore {
  theme: 'light' | 'dark';
  toggleThemeMode: () => void;
}

export const useThemeModeStore = create<ThemeModeStore>()((set) => ({
  theme: currentTheme,
  toggleThemeMode: () =>
    set((state) => {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newTheme);
      return { theme: newTheme };
    }),
}));
