import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { Button, Card, Descriptions, DescriptionsProps, Tooltip } from 'antd';
import Text from 'antd/es/typography/Text';
import dayjs from 'dayjs';

export default function CurrentPlan({
  data,
  isLoading,
}: {
  data: any;
  isLoading: boolean;
}) {
  const items: DescriptionsProps['items'] = [
    // {
    //   label: 'Price per seat',
    //   children: new Intl.NumberFormat('de-DE', {
    //     style: 'currency',
    //     currency: 'EUR',
    //   }).format(data?.amount / 100),
    // },
    {
      label: 'Plan',
      children: (
        <div>
          {data?.price}{' '}
          {data?.chargeType === 'Free' ? '' : `- ${data?.chargeType}ly`}
        </div>
      ),
    },
    {
      label: 'Billing Started',
      children: data?.subscription.current_period_start,
    },
    {
      label: data?.subscription.cancel_at ? 'Billing Finish' : 'Next Billing',
      children: data?.subscription.cancel_at
        ? data?.subscription.cancel_at
        : data?.subscription.current_period_end,
    },
    {
      label: 'Total Price',
      children: new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(data?.totalPrice / 100),
    },
    {
      label: 'Seats',
      children: (
        <Text>
          {data?.takenSeats} / {data?.seats}{' '}
          {data?.takenSeats === data?.seats && (
            <Tooltip
              title={`
            You have reached the maximum number of seats.
            Please upgrade your plan with more seats to allow for more users.
          `}
            >
              <Text type='danger'> Seats full</Text>
            </Tooltip>
          )}
        </Text>
      ),
    },
    {
      label: 'File storage',
      children: `${data?.uploadedSize} / ${data?.maxUploadSize}`,
    },
  ];

  return (
    <>
      <Card loading={isLoading}>
        <Descriptions
          size='small'
          bordered
          title={
            <div>
              <Text strong>Current Plan</Text>
              {data?.chargeType !== 'Free' && (
                <Button
                  style={{ marginLeft: '1rem' }}
                  onClick={async () => {
                    const response = await api.get('/api/admin/plan/manage');
                    window.open(response.data, '_blank');
                  }}
                  size='small'
                  type='primary'
                >
                  Manage
                </Button>
              )}
            </div>
          }
          items={items}
        />
      </Card>
      <br />
      <Card loading={isLoading}>
        <Descriptions
          size='small'
          bordered
          title={<Text strong>Automation</Text>}
          items={[
            {
              label: 'Period',
              children: `${dayjs(data?.automation?.usageKey).format('MMM YYYY')}`,
            },
            {
              label: 'Action Usage',
              children: `${new Intl.NumberFormat().format(data?.automation?.count)} / ${new Intl.NumberFormat().format(data?.automation?.limit)}`,
            },
          ]}
        />
      </Card>
    </>
  );
}
