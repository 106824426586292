import { Dispatch, SetStateAction, useState } from 'react';

export const useMyTheme = (): [
  theme: string,
  func: (theme: string) => void
] => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') ?? 'light');

  const setThemeHandler = (theme: string) => {
    localStorage.setItem('theme', theme);
    setTheme(theme);
  };

  return [theme, setThemeHandler];
};
