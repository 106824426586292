import React, { useRef, useState } from 'react';
import {
  Button,
  Select,
  Space,
  Tooltip,
  Popover,
  Menu,
  Dropdown,
  Divider,
} from 'antd';
import {
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  UnorderedListOutlined,
  OrderedListOutlined,
  BlockOutlined,
  StrikethroughOutlined,
  CodeOutlined,
  HighlightOutlined,
  FontColorsOutlined,
  BgColorsOutlined,
  LinkOutlined,
  CheckOutlined,
  PictureOutlined,
  TableOutlined, // Import table icon
  InsertRowBelowOutlined,
  InsertRowAboveOutlined,
  InsertRowRightOutlined,
  InsertRowLeftOutlined,
  DeleteRowOutlined,
  DeleteColumnOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { SketchPicker } from 'react-color'; // Importing a color picker component
import { api } from '../../../api';
import useMessage from 'antd/es/message/useMessage';
import { BaseButtonProps } from 'antd/es/button/button';

const { Option } = Select;

const TipTapEditorToolbar = ({
  editor,
  selectedHeading,
  setSelectedHeading,
}: any) => {
  const [color, setColor] = useState('#000000'); // Default font color
  const ref: any = useRef();
  const [messageApi, contextHolder] = useMessage();

  if (!editor) {
    return null;
  }

  const handleHeadingChange = (value: any) => {
    editor.chain().focus().toggleHeading({ level: value }).run();
    setSelectedHeading({ level: value });
  };

  const handleColorChange = (color: { hex: React.SetStateAction<string> }) => {
    setColor(color.hex);
    editor.chain().focus().setColor(color.hex).run();
  };

  const handleFileUpload = async (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async () => {
        const base64 = reader.result;
        const fileType = file.type.split('/')[0];

        if (fileType === 'image') {
          editor?.chain().focus().setAuthImage(null, base64).run();
        } else {
          const fileName = file.name;
          editor?.chain().focus().setAuthFile(null, fileName, base64).run();
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const size = 'small';

  const defaultType: BaseButtonProps['type'] = 'text';

  const activeType: BaseButtonProps['type'] = 'link';

  const VerticalDivider = '';

  const tableMenu = (
    <Menu>
      <Menu.ItemGroup title='Table'>
        <Menu.Item
          key='insertTable'
          icon={<TableOutlined />}
          onClick={() =>
            editor.chain().focus().insertTable({ rows: 3, cols: 3 }).run()
          }
        >
          Insert Table
        </Menu.Item>
        <Menu.Item
          key='deleteTable'
          icon={<DeleteOutlined />}
          danger
          onClick={() => editor.chain().focus().deleteTable().run()}
        >
          Delete Table
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title='Row'>
        <Menu.Item
          key='addRowAbove'
          icon={<InsertRowAboveOutlined />}
          onClick={() => editor.chain().focus().addRowBefore().run()}
        >
          Insert Above
        </Menu.Item>
        <Menu.Item
          key='addRowBelow'
          icon={<InsertRowBelowOutlined />}
          onClick={() => editor.chain().focus().addRowAfter().run()}
        >
          Insert Below
        </Menu.Item>
        <Menu.Item
          key='deleteRow'
          icon={<DeleteRowOutlined />}
          danger
          onClick={() => editor.chain().focus().deleteRow().run()}
        >
          Delete Row
        </Menu.Item>
      </Menu.ItemGroup>

      <Menu.ItemGroup title='Column'>
        <Menu.Item
          key='addColumnLeft'
          icon={<InsertRowLeftOutlined />}
          onClick={() => editor.chain().focus().addColumnBefore().run()}
        >
          Insert Left
        </Menu.Item>
        <Menu.Item
          key='addColumnRight'
          icon={<InsertRowRightOutlined />}
          onClick={() => editor.chain().focus().addColumnAfter().run()}
        >
          Insert Right
        </Menu.Item>
        <Menu.Item
          key='deleteColumn'
          icon={<DeleteColumnOutlined />}
          danger
          onClick={() => editor.chain().focus().deleteColumn().run()}
        >
          Delete Column
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const headingMenu = (
    <Menu
      defaultValue={selectedHeading?.level || null}
      style={{ width: 220, textAlign: 'left' }}
      onChange={handleHeadingChange}
    >
      <Menu.Item key='heading1' onClick={() => handleHeadingChange(1)}>
        <h1
          style={{
            margin: 0,
          }}
        >
          Heading 1
        </h1>
      </Menu.Item>
      <Menu.Item key='heading2' onClick={() => handleHeadingChange(2)}>
        <h2
          style={{
            margin: 0,
            textAlign: 'left',
          }}
        >
          Heading 2
        </h2>
      </Menu.Item>
      <Menu.Item key='heading3' onClick={() => handleHeadingChange(3)}>
        <h3
          style={{
            margin: 0,
          }}
        >
          Heading 3
        </h3>
      </Menu.Item>
      <Menu.Item key='heading4' onClick={() => handleHeadingChange(4)}>
        <h4
          style={{
            margin: 0,
          }}
        >
          Heading 4
        </h4>
      </Menu.Item>
      <Menu.Item key='heading5' onClick={() => handleHeadingChange(5)}>
        <h5
          style={{
            margin: 0,
          }}
        >
          Heading 5
        </h5>
      </Menu.Item>
      <Menu.Item key='heading6' onClick={() => handleHeadingChange(6)}>
        <h6
          style={{
            margin: 0,
          }}
        >
          Heading 6
        </h6>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='editor-toolbar'>
      {contextHolder}
      <Space wrap style={{ gap: '.01rem' }}>
        <Dropdown overlay={headingMenu} trigger={['click']}>
          <Button
            style={{
              width: 80,
            }}
            size={size}
            type='text'
          >
            {selectedHeading?.level
              ? `Heading ${selectedHeading?.level}`
              : 'Normal'}
          </Button>
        </Dropdown>
        {VerticalDivider}
        <Tooltip title='Bold'>
          <Button
            size={size}
            icon={<BoldOutlined />}
            onClick={() => editor.chain().focus().toggleBold().run()}
            type={editor.isActive('bold') ? activeType : defaultType}
            variant='text'
          />
        </Tooltip>
        <Tooltip title='Italic'>
          <Button
            size={size}
            icon={<ItalicOutlined />}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            type={editor.isActive('italic') ? activeType : defaultType}
          />
        </Tooltip>
        <Tooltip title='Underline'>
          <Button
            size={size}
            icon={<UnderlineOutlined />}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            type={editor.isActive('underline') ? activeType : defaultType}
          />
        </Tooltip>
        <Tooltip title='Strikethrough'>
          <Button
            size={size}
            icon={<StrikethroughOutlined />}
            onClick={() => editor.chain().focus().toggleStrike().run()}
            type={editor.isActive('strike') ? activeType : defaultType}
          />
        </Tooltip>
        {/* {' | '} */}
        {VerticalDivider}
        <Tooltip title='Code'>
          <Button
            size={size}
            icon={<CodeOutlined />}
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            type={editor.isActive('code') ? activeType : defaultType}
          />
        </Tooltip>
        <Tooltip title='Highlight'>
          <Button
            size={size}
            icon={<HighlightOutlined />}
            onClick={() => editor.chain().focus().toggleHighlight().run()}
            type={editor.isActive('highlight') ? activeType : defaultType}
          />
        </Tooltip>
        {/* <Tooltip title='Superscript'>
          <Button
            size={size}
            onClick={() => editor.chain().focus().toggleSuperscript().run()}
            type={editor.isActive('superscript') ? activeType : defaultType}
          >
            S+
          </Button>
        </Tooltip>
        <Tooltip title='Subscript'>
          <Button
            size={size}
            onClick={() => editor.chain().focus().toggleSubscript().run()}
            type={editor.isActive('subscript') ? activeType : defaultType}
          >
            S-
          </Button>
        </Tooltip> */}
        {VerticalDivider}
        <Tooltip title='Bullet List'>
          <Button
            size={size}
            icon={<UnorderedListOutlined />}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            type={editor.isActive('bulletList') ? activeType : defaultType}
          />
        </Tooltip>
        <Tooltip title='Ordered List'>
          <Button
            size={size}
            icon={<OrderedListOutlined />}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            type={editor.isActive('orderedList') ? activeType : defaultType}
          />
        </Tooltip>
        {VerticalDivider}
        {/* <Tooltip title='Blockquote'>
          <Button
            size={size}
            icon={<BlockOutlined />}
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            type={editor.isActive('blockquote') ? activeType : defaultType}
          />
        </Tooltip> */}
        <Tooltip title='Text Color'>
          <Popover
            content={
              <SketchPicker
                color={color}
                onChangeComplete={handleColorChange}
              />
            }
            title='Pick a color'
            trigger='click'
          >
            <Button
              size={size}
              icon={<FontColorsOutlined />}
              type={
                editor.isActive('textStyle', { color })
                  ? activeType
                  : defaultType
              }
            />
          </Popover>
        </Tooltip>
        {/* <Tooltip title='Background Color'>
          <Button
            size={size}
            icon={<BgColorsOutlined />}
            onClick={() =>
              editor.chain().focus().setHighlight({ color: '#ffff00' }).run()
            }
            type={
              editor.isActive('highlight', { color: '#ffff00' })
                ? activeType
                : defaultType
            }
          />
        </Tooltip> */}
        {VerticalDivider}
        <Tooltip title='Link'>
          <Button
            size={size}
            icon={<LinkOutlined />}
            onClick={() => {
              const url = prompt('Enter the URL');
              if (url) {
                editor.chain().focus().setLink({ href: url }).run();
              }
            }}
            type={editor.isActive('link') ? activeType : defaultType}
          />
        </Tooltip>
        <Tooltip title='Task List'>
          <Button
            size={size}
            icon={<CheckOutlined />}
            onClick={() => editor.chain().focus().toggleTaskList().run()}
            type={editor.isActive('taskList') ? activeType : defaultType}
          />
        </Tooltip>
        <Tooltip title='File Upload'>
          <Button
            size={size}
            icon={<PictureOutlined />}
            type='text'
            onClick={() => {
              const input: any = ref.current;
              if (input) {
                input.click();
              }
            }}
          />
          <input
            ref={ref}
            type='file'
            id='file-upload'
            style={{ display: 'none' }}
            onChange={handleFileUpload}
          />
        </Tooltip>
        {VerticalDivider}
        <Tooltip title='Table'>
          <Dropdown overlay={tableMenu} trigger={['click']}>
            <Button size={size} icon={<TableOutlined />} type='text' />
          </Dropdown>
        </Tooltip>
      </Space>
    </div>
  );
};

export default TipTapEditorToolbar;
