import { Button, Card, Dropdown, Popconfirm, Tag, Tooltip } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import Text from 'antd/es/typography/Text';
import {
  DatabaseOutlined,
  DeleteOutlined,
  EyeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { api } from '@/api';
import { useNavigate } from 'react-router-dom';
import { useProjectStore } from '@/state/projectState';
import { useBoardStore } from '@/state/boardState';
import QuickChangeAssignee from '@/components/QuickChangeAssignee';
import { useState } from 'react';
import { useUserStore } from '@/state/userState';
import { getTaskPriorityIcon, getTaskTypeIcon } from '@/utils';
import dayjs from 'dayjs';

export default function TaskCard({
  item,
  index,
  refetch,
  messageApi,
  column,
  setShowTaskDetailsModal,
}: {
  item: any;
  index: number;
  refetch: any;
  messageApi: any;
  column: any;
  setShowTaskDetailsModal: any;
}) {
  const navigate = useNavigate();

  const projectStore = useProjectStore();
  const boardStore = useBoardStore();
  const userStore = useUserStore();
  const [showOptions, setShowOptions] = useState(false);

  const [watching, setWatching] = useState(item.isWatching === 1);

  const dropdownItems: any = [
    {
      label: (
        <Text>
          <div
            style={{
              display: 'flex',
              gap: '0.5rem',
            }}
          >
            <EyeOutlined type={watching ? 'primary' : 'defualt'} />
            {watching ? 'Unwatch' : 'Watch'}
          </div>
        </Text>
      ),
      onClick: async () => {
        try {
          await api.put(`/api/tasks/${item.id}/watch`);

          setWatching(!watching);
          messageApi.success(watching ? 'Unwatched Task' : 'Watched Task');
        } catch (error) {
          console.log(error, ':(');
        }
      },
      key: '2',
    },
    {
      label: (
        <Popconfirm
          title='Are you sure?'
          onConfirm={async () => {
            try {
              await api.put(`/api/tasks/${item.id}/toggle-archived`);

              // await refetch();
              messageApi.success('Task Archived!');
            } catch (error) {
              console.log(error, ':(');
            }
          }}
        >
          <Text
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: '0.5rem',
              }}
            >
              <DatabaseOutlined />
              Archive
            </div>
          </Text>
        </Popconfirm>
      ),
      danger: true,
      key: '3',
      onClick: async () => {},
    },
    userStore.user?.isAdmin || userStore.user?.isManager
      ? {
          type: 'divider',
        }
      : undefined,
    userStore.user?.isAdmin || userStore.user?.isManager
      ? {
          label: (
            <Popconfirm
              title='Are you sure?'
              description='This action cannot be undone.'
              onConfirm={async () => {
                try {
                  await api.delete(`/api/tasks/${item.id}`);

                  // await refetch();
                  messageApi.success('Task Deleted!');
                } catch (error) {
                  console.log(error, ':(');
                }
              }}
            >
              <Text
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                  }}
                >
                  <DeleteOutlined />
                  Delete
                </div>
              </Text>
            </Popconfirm>
          ),
          danger: true,
          key: '4',
          onClick: async () => {},
        }
      : undefined,
  ];

  const getDueDateColor = (dueDays: number) => {
    if (dueDays <= 1) {
      return 'red';
    } else if (dueDays <= 3) {
      return 'orange';
    } else {
      return 'green';
    }
  };

  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        return (
          <Dropdown
            trigger={['contextMenu']}
            menu={{
              onClick: (e) => {
                e.domEvent.stopPropagation();
              },
              items: dropdownItems,
            }}
          >
            <Card
              bordered={false}
              ref={provided.innerRef}
              onMouseEnter={() => setShowOptions(true)}
              onMouseLeave={() => setShowOptions(false)}
              onClick={() => {
                setShowTaskDetailsModal(true);
                navigate(
                  `/project/${projectStore.project?.key}/board/${boardStore.board?.key}/task/${item.key}`
                );
              }}
              styles={{
                body: {
                  padding: '5px',
                },
              }}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={`item ${snapshot.isDragging ? 'dragging' : ''}`}
            >
              <div>
                <div style={{ marginBottom: '.5rem' }}>
                  <Text>{item.label}</Text>
                </div>

                <div style={{ textAlign: 'right' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        {getTaskTypeIcon(item.taskTypeKey)}
                        {getTaskPriorityIcon(item.priorityKey)}
                        <Tooltip title={item.key}>
                          <Tag
                            bordered={false}
                            style={{
                              color: 'gray',
                            }}
                          >
                            {item.key}
                          </Tag>
                        </Tooltip>
                      </div>
                      {watching && (
                        <Tooltip title='Watching'>
                          <Button
                            type='primary'
                            icon={<EyeOutlined />}
                            size='small'
                            onClick={async (e) => {
                              e.stopPropagation();
                              try {
                                await api.put(`/api/tasks/${item.id}/watch`);
                                setWatching(!watching);
                                messageApi.success('Unwatched Task');
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          />
                        </Tooltip>
                      )}

                      {showOptions && (
                        <Dropdown
                          menu={{
                            onClick: (e) => {
                              e.domEvent.stopPropagation();
                            },
                            items: dropdownItems,
                          }}
                          trigger={['click']}
                        >
                          <Button
                            onClick={(e) => e.stopPropagation()}
                            size='small'
                            type='text'
                          >
                            <SettingOutlined color='blue' />
                          </Button>
                        </Dropdown>
                      )}
                    </div>

                    <QuickChangeAssignee
                      projectKey={projectStore.project?.key ?? ''}
                      taskId={item.id}
                      currentAssigneeId={item.assigneeId}
                      tooltipName={item.assignee ?? 'Unassigned'}
                      avatarSize={25}
                      showToolTip={true}
                      onChangeAssignee={async () => {
                        // await refetch();
                        messageApi.success('Assignee Changed');
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    marginTop: '.5rem',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    {item.questLabel && (
                      <Tooltip title='Quest'>
                        <Tag bordered={false} color={'geekblue'}>
                          {item.questLabel}
                        </Tag>
                      </Tooltip>
                    )}
                  </div>
                  {item.dueDate && (
                    <div>
                      <Tooltip
                        title={
                          dayjs(item.dueDate).diff(dayjs(), 'day') > 0
                            ? dayjs(item.dueDate).format('DD MMM YYYY')
                            : dayjs(item.dueDate).endOf('day').fromNow()
                        }
                      >
                        <Tag
                          bordered={false}
                          color={getDueDateColor(
                            dayjs(item.dueDate).diff(dayjs(), 'day')
                          )}
                        >
                          {dayjs(item.dueDate).diff(dayjs(), 'day') > 0
                            ? dayjs(item.dueDate).endOf('day').fromNow()
                            : `${dayjs(item.dueDate).fromNow()}`}
                        </Tag>
                      </Tooltip>
                    </div>
                  )}
                  {/* {item.points > 0 && (
                    <div>
                      <Tag bordered={false}>{item.points} XP</Tag>
                    </div>
                  )} */}
                </div>
              </div>
            </Card>
          </Dropdown>
        );
      }}
    </Draggable>
  );
}
