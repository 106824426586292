import { NodeViewWrapper } from '@tiptap/react';
import React, { useEffect } from 'react';
import { api } from '../../../../api';
import { Button, Popover, Spin, Tooltip } from 'antd';
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  FileTwoTone,
} from '@ant-design/icons';

export default (props: any) => {
  const [blob, setBlob]: any = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [contentType, setContentType] = React.useState('');

  async function getBlob() {
    try {
      if (props.node.attrs.base64) {
        const fileResponse = await fetch(props.node.attrs.base64);
        if (!fileResponse) {
          setBlob(null);
          setError(true);
          setLoading(false);
          return;
        }

        if (fileResponse?.headers?.get('content-type')?.startsWith('video/')) {
          setContentType('video');
        }
        setBlob(
          URL.createObjectURL(
            new Blob([await (await fetch(props.node.attrs.base64)).blob()])
          )
        );
        setLoading(false);
      } else {
        const response = await api.get(
          `${import.meta.env.VITE_API_URL}/media/${props.node.attrs.fileKey}`,
          {
            responseType: 'blob',
          }
        );

        if (response.status === 404) {
          setBlob(null);
          setError(true);
          setLoading(false);
          return;
        }

        const url = URL.createObjectURL(new Blob([response.data]));
        setBlob(url);
        setLoading(false);
        if (response.headers['content-type'].startsWith('video/')) {
          setContentType('video');
        }
      }
    } catch (error: any) {
      if (error?.response?.status === 404) {
        setBlob(null);
        setError(true);
        setLoading(false);
        return;
      }
      console.log(error);
    }
  }

  useEffect(() => {
    if (!blob) {
      getBlob();
    }
  }, []);

  return (
    <NodeViewWrapper className='auth-file'>
      {loading && !error && <Spin />}
      {error && (
        <div style={{ color: 'orange' }}>
          <ExclamationCircleOutlined /> File not found
        </div>
      )}
      {blob && !loading && !error && (
        <>
          {contentType === 'video' ? (
            <video
              controls
              width={400}
              style={{ borderRadius: '8px', margin: '.5rem 0' }}
              src={blob}
              onLoad={() => {
                URL.revokeObjectURL(blob);
              }}
            ></video>
          ) : (
            <Popover
              trigger={'click'}
              content={
                <div style={{ display: 'flex', gap: '.5rem' }}>
                  <Tooltip title='Download File'>
                    <Button
                      size='small'
                      type='primary'
                      title='Download'
                      onClick={(e) => {
                        e.stopPropagation();
                        const link = document.createElement('a');
                        link.href = blob;
                        link.download = `file-${props.node.attrs.fileName}`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }}
                    >
                      <DownloadOutlined />
                    </Button>
                  </Tooltip>
                </div>
              }
            >
              <div
                style={{
                  display: 'flex',
                  gap: '.2rem',
                  width: 'fit-content',
                  margin: '.5rem 0',
                  border: '1px solid gray',
                  borderRadius: '8px',
                  padding: '.3rem',
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <FileTwoTone />
                <Button type='link' size='small'>
                  {props.node.attrs.fileName}
                </Button>
              </div>
            </Popover>
          )}
        </>
      )}
    </NodeViewWrapper>
  );
};
