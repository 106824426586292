import { useProjectStore } from '@/state/projectState';
import { Activity } from './Activity';
import { Card, Col, Divider, Row } from 'antd';
import { Created } from './Created';
import { Completed } from './Completed';
import { Updated } from './Updated';

export default function Summary() {
  const project = useProjectStore((state) => state.project);
  if (!project) return null;

  return (
    <div style={{ overflow: 'auto', height: '80vh' }}>
      <Row gutter={25} wrap>
        <Col span={8}>
          <Completed projectId={project.id} />
        </Col>
        <Col span={8}>
          <Created projectId={project.id} />
        </Col>
        <Col span={8}>
          <Updated projectId={project.id} />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Activity projectId={project?.id} projectKey={project.key} />
        </Col>
      </Row>
    </div>
  );
}
