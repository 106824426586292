import React from 'react';
import type { FormProps } from 'antd';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { api } from '../../../api';

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const Security: React.FC<any> = ({ fullName }: { fullName: string }) => {
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      await api.put('/api/me', { password: values.password });
      messageApi.success('Password updated successfully');
    } catch (error) {
      messageApi.error('Failed to update password');
    }
  };

  return (
    <div>
      {contextHolder}
      <Form
        name='basic'
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete='off'
        layout='vertical'
      >
        <div>
          <Form.Item
            label='Password'
            name={'password'}
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label='Confirm Password'
            name={'confirm-password'}
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
              {
                validator: (rule, value, callback) => {
                  if (value !== form.getFieldValue('password')) {
                    return Promise.reject(
                      'The two passwords that you entered do not match!'
                    );
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </div>

        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Security;
