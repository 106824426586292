import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { api } from '../../../api';
import { useBoardStore } from '../../../state/boardState';
import {
  Button,
  Divider,
  Input,
  Modal,
  Select,
  Skeleton,
  message,
  notification,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Text from 'antd/es/typography/Text';

import { BoardHeader } from './components/BoardHeader';
import { Column } from './components/Column';
import { useUserStore } from '@/state/userState';
import { useOptionStore } from '@/state/options';
import { sseClient } from '@/api/realtime';

function Board() {
  // const [currentColumns, setCurrentColumns] = useState<any>([]);
  const [showNewColumnForm, setShowNewColumnForm] = useState(false);
  const [newColumnLabel, setNewColumnLabel] = useState('');
  const [newColumnCategoryId, setNewColumnCategoryId] = useState(
    'j82senyrwaaza0m8omtn6jgu'
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [realTime, setRealTime] = useState(true);
  const [assigneeFilter, setAssigneeFilter] = useState<any>([]);
  const [priorityFilter, setPriorityFilter] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const { projectKey, boardKey } = useParams();
  const boardStore = useBoardStore();
  const [notificationApi, notificationContextHolder] =
    notification.useNotification();

  const [eventSource, setEventSource] = useState<EventSource | null>(null);

  const { user } = useUserStore();

  async function getPriorities() {
    const response = await api.get(`/api/priority`);
    return response.data;
  }

  const { data: priorities } = useQuery({
    queryKey: ['priority'],
    queryFn: getPriorities,
    staleTime: Infinity,
  });

  async function getColumns() {
    const response = await api.get(
      `/api/projects/${projectKey}/boards/${boardKey}/columns`
    );
    // setCurrentColumns(response.data);
    return response.data;
  }

  const {
    data: currentColumns,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['columns', projectKey, boardKey],
    queryFn: getColumns,
    // staleTime: 1000 * 60 * 1,
  });

  async function getProjectUsers() {
    const response = await api.get(`/api/projects/${projectKey}/users`);
    return response.data;
  }

  const optionStore = useOptionStore();

  const { data: users, isLoading: usersIsLoading } = useQuery({
    queryKey: [projectKey, 'users'],
    queryFn: getProjectUsers,
    // staleTime: 1000 * 60 * 1,
  });

  // useEffect(() => {
  //   setCurrentColumns(data);
  // }, [data]);

  const queryClient = useQueryClient();

  const handleTaskUpdated = (event: any) => {
    const newEvent = JSON.parse(JSON.parse(event.data));

    const task = newEvent.payload;
    // find the column where the task is and if old and new column are same, just replace it otherwise remove from old column and add to new column
    const currentColumn = currentColumns.find(
      (col: any) => col.id === task.oldColumnId
    );

    const newColumn = currentColumns.find(
      (col: any) => col.id === task.columnId
    );

    if (currentColumn.id === newColumn.id) {
      const newItems = Array.from(
        currentColumn.items.map((item: any) => {
          return item.id === task.id ? task : item;
        })
      );

      queryClient.setQueryData(
        ['columns', projectKey, boardKey],
        ...[
          currentColumns.map((col: any) =>
            col.id === currentColumn.id
              ? { ...currentColumn, items: newItems }
              : col
          ),
        ]
      );
    } else {
      const oldColumn = currentColumns.find(
        (col: any) => col.id === task.oldColumnId
      );

      // check for optimisitc update

      // const itemExists = newColumn.items.find(
      //   (item: any) => item.id === task.id
      // );
      // if (itemExists) {
      //   return;
      // }

      const newItems = Array.from(newColumn.items.concat(task));

      const oldItems = Array.from(
        oldColumn.items.filter((item: any) => item.id !== task.id)
      );

      queryClient.setQueryData(
        ['columns', projectKey, boardKey],
        Array.from(
          currentColumns.map((col: any) =>
            col.id === oldColumn.id
              ? {
                  ...oldColumn,
                  items: oldItems,
                }
              : col.id === newColumn.id
              ? {
                  ...newColumn,
                  items: newItems,
                }
              : col
          )
        )
      );
    }
  };

  const handleTaskCreated = (event: any) => {
    const newEvent = JSON.parse(JSON.parse(event.data));

    const task = newEvent.payload;
    const newColumn = currentColumns.find(
      (col: any) => col.id === task.columnId
    );
    const newItems = Array.from(newColumn.items.concat(task));
    queryClient.setQueryData(
      ['columns', projectKey, boardKey],
      Array.from(
        currentColumns.map((col: any) =>
          col.id === newColumn.id
            ? {
                ...newColumn,
                items: newItems,
              }
            : col
        )
      )
    );
  };

  const handleTaskDeleted = (event: any) => {
    const newEvent = JSON.parse(JSON.parse(event.data));

    const task = newEvent.payload;
    const column = currentColumns.find((col: any) => col.id === task.columnId);
    const newItems = column.items.filter((item: any) => item.id !== task.id);
    queryClient.setQueryData(
      ['columns', projectKey, boardKey],
      Array.from(
        currentColumns.map((col: any) =>
          col.id === column.id
            ? {
                ...column,
                items: newItems,
              }
            : col
        )
      )
    );
  };

  useEffect(() => {
    if (!currentColumns) return;

    sseClient.addEventListener('board_task_updated', handleTaskUpdated);
    sseClient.addEventListener('task_created', handleTaskCreated);
    sseClient.addEventListener('task_deleted', handleTaskDeleted);

    return () => {
      sseClient.removeEventListener('board_task_updated', handleTaskUpdated);
      sseClient.removeEventListener('task_created', handleTaskCreated);
      sseClient.removeEventListener('task_deleted', handleTaskDeleted);
    };
  }, [currentColumns]);

  const onDragEnd = async (result: any) => {
    const { source, destination, type, draggableId } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (type === 'column') {
      const newColumnOrder = Array.from(currentColumns);
      const [movedColumn] = newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, movedColumn);

      const oldColumns = [...currentColumns];
      // setCurrentColumns(newColumnOrder);
      queryClient.setQueryData(
        ['columns', projectKey, boardKey],
        newColumnOrder
      );

      try {
        await api.put(`/api/columns/${draggableId}/reorder`, {
          position: destination.index + 1,
        });
      } catch (error) {
        // setCurrentColumns(oldColumns);
        queryClient.setQueryData(['columns', projectKey, boardKey], oldColumns);
      }

      return;
    }

    const startColumn = currentColumns.find(
      (col: any) => col.id === source.droppableId
    );
    const finishColumn = currentColumns.find(
      (col: any) => col.id === destination.droppableId
    );

    if (startColumn === finishColumn) {
      const newItems: any = startColumn.items;
      const [relocatedItem] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, relocatedItem);

      const dataToSend = {
        newColumnId: destination.droppableId,
        beforeId: newItems[destination.index + 1]?.id || null,
        afterId: newItems[destination.index - 1]?.id || null,
      };

      const oldColumns = [...currentColumns];

      try {
        await api.post(`/api/tasks/${draggableId}/move`, dataToSend);
      } catch (error) {
        queryClient.setQueryData(['columns', projectKey, boardKey], oldColumns);
      }
    } else {
      // update view

      // const newStartItems = startColumn.items;
      // const [movedItem] = newStartItems.splice(source.index, 1);
      // const newFinishItems = finishColumn.items;
      // newFinishItems.splice(destination.index, 0, movedItem);

      let dataToSend: any = {
        newColumnId: destination.droppableId,
      };

      const afterItem: any = finishColumn.items[destination.index - 1];
      const beforeItem: any = finishColumn.items[destination.index];

      if (afterItem) {
        dataToSend.afterId = afterItem?.id;
      }
      if (beforeItem) {
        dataToSend.beforeId = beforeItem?.id;
      }

      try {
        await api.post(`/api/tasks/${draggableId}/move`, dataToSend);
      } catch (error) {
        queryClient.setQueryData(
          ['columns', projectKey, boardKey],
          currentColumns
        );
      }
    }
  };

  if (isLoading) {
    return (
      <div>
        <div style={{ display: 'flex', gap: '6rem' }}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
        <div style={{ display: 'flex', gap: '6rem' }}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
        <div style={{ display: 'flex', gap: '6rem' }}>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      </div>
    );
  }

  return (
    <div>
      {contextHolder}
      {notificationContextHolder}

      <BoardHeader
        assigneeFilter={assigneeFilter}
        setAssigneeFilter={setAssigneeFilter}
        filterType={filterType}
        setFilterType={setFilterType}
        realTime={realTime}
        setRealTime={setRealTime}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        users={users}
        usersIsLoading={usersIsLoading}
        refetch={refetch}
        currentPriorities={priorities}
        priorityFilter={priorityFilter}
        setPriorityFilter={setPriorityFilter}
        currentColumns={currentColumns}
        isFetching={isFetching}
        key={boardKey}
      />
      <div
        className='kanban'
        style={{
          marginTop: '1rem',
        }}
      >
        <DragDropContext
          onDragEnd={async (result) => {
            const { source, destination, draggableId } = result;
            if (
              source.droppableId === destination?.droppableId &&
              source.index === destination?.index
            ) {
              return;
            }
            await onDragEnd(result);
            // await refetch();
          }}
        >
          <Droppable
            droppableId='all-columns'
            direction='horizontal'
            type='column'
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ display: 'flex', gap: '.5rem' }}
              >
                {currentColumns.map((column: any, index: number) => (
                  <Draggable
                    key={column.id}
                    draggableId={column.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        <Column
                          key={column.id}
                          column={column}
                          assigneeFilter={assigneeFilter}
                          priorityFilter={priorityFilter}
                          filterType={filterType}
                          searchTerm={searchTerm}
                          refetch={refetch}
                          realTime={realTime}
                          width={`${80 / currentColumns.length}vw`}
                          handle={provided.dragHandleProps}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div>
          {(user?.isAdmin || user?.isManager) && (
            <Button
              onClick={() => {
                setShowNewColumnForm(true);
              }}
              type='link'
            >
              + Add Column{' '}
            </Button>
          )}
        </div>
      </div>
      {showNewColumnForm && (
        <Modal
          style={{ top: 20 }}
          open={showNewColumnForm}
          title='Create New Column'
          onCancel={() => {
            setShowNewColumnForm(false);
          }}
          onOk={async () => {
            try {
              await api.post(`/api/boards/${boardStore.board?.id}/columns`, {
                label: newColumnLabel,
                categoryId: newColumnCategoryId,
              });
              await refetch();
              setNewColumnLabel('');
              setShowNewColumnForm(false);
            } catch (error) {
              console.log(error);
              notificationApi.error({
                message: 'Error',
                description: 'Failed to create column',
              });
            }
          }}
          okText='Create'
        >
          <div>
            <Text>Label</Text>
            <Input
              autoFocus
              value={newColumnLabel}
              onChange={(e) => setNewColumnLabel(e.target.value)}
            />
            <Text>Type</Text>
            <Select
              style={{ width: '100%' }}
              defaultValue={'j82senyrwaaza0m8omtn6jgu'}
              options={[
                {
                  label: 'Todo',
                  value: 'j82senyrwaaza0m8omtn6jgu',
                },
                {
                  label: 'In Progress',
                  value: 'gt2i39u4ierremke4g926x0x',
                },
              ]}
              placeholder='Select a category'
              onChange={(value) => {
                setNewColumnCategoryId(value);
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Board;
