import { createBrowserRouter, Link, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './globals.css';
import './api';

import { BoardWrapper } from './pages/BoardWrapper';
import Login from './pages/login';
import Register from './pages/register';
import Wrapper2 from './pages/wrapper2';
import ProjectOverview from './pages/projectOverview';
import { Button, Result } from 'antd';
import ProjectPages from './pages/projectPages';
import { Invite } from './pages/Invite';
import Me from './pages/me';
import Pointlog from './pages/me/pointlog';
import Timeline from './pages/projectOverview/Timeline/timeline2';
import Timesheets from './pages/me/Timesheets';
import AdminPage from './pages/Admin';
import AdminProjects from './pages/Admin/Projects';
import AdminTimesheets from './pages/Admin/Timesheets';
import TaskPage from './pages/TaskPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Wrapper2 />,
    children: [
      {
        path: '/',
        element: <BoardWrapper />,
      },
      {
        path: '/project/:projectKey/overview',
        element: <ProjectOverview />,
      },
      {
        path: '/project/:projectKey/overview/:taskKey',
        element: <ProjectOverview />,
      },
      {
        path: '/project/:projectKey/board/:boardKey',
        element: <BoardWrapper />,
      },
      {
        path: '/project/:projectKey/board/:boardKey/task/:taskId',
        element: <BoardWrapper />,
      },
      {
        path: '/project/:projectKey/pages',
        element: <ProjectPages />,
      },
      {
        path: '/project/:projectKey/quests',
        element: <Timeline />,
      },
      {
        path: '/project/:projectKey/pages/:pageId',
        element: <ProjectPages />,
      },
      {
        path: '/task/:taskKey',
        element: <TaskPage />,
      },
      {
        path: '/me',
        element: <Me />,
      },
      {
        path: '/me/points',
        element: <Pointlog />,
      },
      {
        path: '/me/timesheet',
        element: <Timesheets />,
      },
      {
        path: '/admin',
        element: <AdminPage />,
      },
      {
        path: '/admin/projects',
        element: <AdminProjects />,
      },
      {
        path: '/admin/timesheets',
        element: <AdminTimesheets />,
      },
    ],
    errorElement: (
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={
          <Link to='../'>
            <Button size='small' type='primary'>
              Back Home
            </Button>
          </Link>
        }
      />
    ),
  },

  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/invite/:inviteId',
    element: <Invite />,
  },
]);

// Create a client
const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={router} />
  </QueryClientProvider>
);
