import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Dropdown, Menu } from 'antd';

const MentionList = forwardRef((props: any, ref: any) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (selectedItem: any) => {
    const item = props.items.find((item: any) => item.id === selectedItem.id);

    if (item) {
      props.command({ id: item.id, label: item.label });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(props.items[selectedIndex]);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: any) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  const menuItems = props.items.map((item: any, index: any) => ({
    key: index,
    onClick: () => selectItem(item),
    label: item.label,
  }));

  return (
    <Dropdown
      menu={{
        items: menuItems,
        selectable: true,
        selectedKeys: [`${selectedIndex}`],
      }}
      open
    >
      <div></div>
    </Dropdown>
  );
});

export default MentionList;
