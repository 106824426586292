import { Link } from "@tiptap/extension-link";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import { saveAs } from "file-saver";

const CustomLink = Link.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("download-link-handler"),
        props: {
          handleClick(view, pos, event: any) {
            event.stopImmediatePropagation();
            event.stopPropagation();
            event.preventDefault();
            const isAnchorElement = event.target instanceof HTMLAnchorElement;
            const classNames = event.target.getAttribute("class");
            if (isAnchorElement && classNames?.includes("download-file-link")) {
              const link = event.target.getAttribute("data-href");
              const fileName = event.target.getAttribute("data-filename");
              fetch(link).then((response) => {
                response.blob().then((blob) => {
                  saveAs(blob, fileName);
                });
              });
            }
          },
        },
      }),
    ];
  },
});
export default CustomLink;
