import React from 'react';
import {
  Button,
  Card,
  Dropdown,
  Input,
  Modal,
  Popconfirm,
  Skeleton,
  Space,
  Tree,
  message,
} from 'antd';
import type { TreeDataNode, TreeProps } from 'antd';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../../api';
import { useProjectStore } from '../../../../state/projectState';
import Text from 'antd/es/typography/Text';
import { DeleteOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStore } from '@/state/userState';
import { CustomIcon } from '@/components/Icon';

const ProjectPagesTree = ({
  setSelectedPage,
  treeData,
  isLoading,
  data,
  refetchTree,
}: // addToTree,
// removeFromTree,
{
  setSelectedPage: any;
  selectedPage: any;
  treeData: TreeDataNode[];
  isLoading: boolean;
  data: any;
  refetchTree: any;
  // addToTree: any;
  // removeFromTree: any;
}) => {
  const projectStore = useProjectStore();
  const [createPageModalVisible, setCreatePageModalVisible] =
    React.useState(false);

  const navigate = useNavigate();

  const { pageId } = useParams();

  const [label, setLabel] = React.useState('');
  const [parentId, setParentId] = React.useState(null);

  const [messageApi, contextHolder] = message.useMessage();

  const { user } = useUserStore();

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    setSelectedPage(info.node);
  };

  if (isLoading) return <Skeleton style={{ minWidth: 220 }} active />;

  if (!isLoading && data && !data.length)
    return (
      <div style={{ minWidth: 220 }}>
        <Button
          onClick={() => {
            setCreatePageModalVisible(true);
          }}
          type='link'
        >
          + Create Page
        </Button>
        <Card>No pages</Card>

        <Modal
          open={createPageModalVisible}
          footer={null}
          style={{ top: 20 }}
          onCancel={() => {
            setCreatePageModalVisible(false);
          }}
          onClose={() => {
            setCreatePageModalVisible(false);
          }}
        >
          <div>Create Page</div>
          <br />
          <Text>Label</Text>
          <Input
            value={label}
            placeholder='Page label'
            onChange={(e) => {
              setLabel(e.target.value);
            }}
          />
          <br />
          <br />
          <Button
            onClick={async () => {
              const response = await api.post(
                `/api/projects/${projectStore.project?.id}/pages`,
                {
                  label,
                }
              );
              // queryClient.setQueryData(
              //   ['pages', projectStore.project?.id],
              //   [...data, response.data]
              // );
              setLabel('');
              setCreatePageModalVisible(false);
            }}
            type='primary'
          >
            Create
          </Button>
        </Modal>
      </div>
    );

  return (
    <div>
      {contextHolder}

      <div style={{ textAlign: 'right', marginBottom:".5rem" }}>
        <Button
          onClick={() => {
            setCreatePageModalVisible(true);
          }}
          // type='link'
          size='small'
        >
          <PlusOutlined /> Add page
        </Button>
      </div>
      <Tree
        style={{ minWidth: 250, padding: '.5rem' }}
        onSelect={onSelect}
        treeData={treeData}
        blockNode
        defaultSelectedKeys={pageId ? [pageId] : undefined}
        defaultExpandedKeys={pageId ? [pageId] : undefined}
        titleRender={(nodeData: any) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              onClick={() => {
                setSelectedPage(nodeData);
                navigate(
                  `/project/${projectStore.project?.key}/pages/${nodeData.key}`
                );
              }}
            >
              <Text>{nodeData.title ?? ''}</Text>
              <Dropdown
                trigger={['click']}
                menu={{
                  onClick: (e) => {
                    e.domEvent.stopPropagation();
                  },
                  items: [
                    {
                      key: 'edit',
                      label: 'Add child page',
                      icon: <PlusOutlined />,
                      onClick: () => {
                        setParentId(nodeData.key);
                        setCreatePageModalVisible(true);
                      },
                    },
                    user?.isAdmin || user?.isManager
                      ? {
                          label: (
                            <Popconfirm
                              title='Are you sure? all child pages will be deleted too.'
                              onConfirm={async () => {
                                try {
                                  await api.delete(
                                    `/api/pages/${nodeData.key}`
                                  );

                                  // queryClient.setQueryData(
                                  //   ['pages', projectStore.project?.id],
                                  //   (oldData: any) => {
                                  //     return removeFromTree(oldData, nodeData.key);
                                  //   }
                                  // );

                                  refetchTree();

                                  messageApi.success('Page Deleted!');
                                } catch (error) {
                                  console.log(error, ':(');
                                }
                              }}
                              okText='Yes'
                              cancelText='No'
                              okType='danger'
                            >
                              <Text
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                  }}
                                >
                                  <DeleteOutlined />
                                  Delete
                                </div>
                              </Text>
                            </Popconfirm>
                          ),
                          danger: true,
                          key: '3',

                          onClick: async () => {},
                        }
                      : {
                          type: 'divider',
                        },
                  ],
                }}
              >
                <Button
                  type='text'
                  size='small'
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <CustomIcon icon='material-symbols:more-horiz' />
                </Button>
              </Dropdown>
            </div>
          );
        }}
      />

      <Modal
        open={createPageModalVisible}
        footer={null}
        style={{ top: 20 }}
        onCancel={() => {
          setCreatePageModalVisible(false);
        }}
        onClose={() => {
          setCreatePageModalVisible(false);
        }}
      >
        <div>Create {parentId ? 'child' : ''} page</div>
        <br />
        <Text>Label</Text>
        <Input
          value={label}
          placeholder='Page label'
          onChange={(e) => {
            setLabel(e.target.value);
          }}
        />
        <br />
        <br />
        <Button
          onClick={async () => {
            const response = await api.post(
              `/api/projects/${projectStore.project?.id}/pages`,
              {
                label,
                parentId,
              }
            );

            messageApi.success('Page Created!');
            // if (parentId) {
            //   queryClient.setQueryData(
            //     ['pages', projectStore.project?.id],
            //     (oldData: any) => {
            //       return addToTree(oldData, parentId, response.data);
            //     }
            //   );
            // } else {
            //   queryClient.setQueryData(
            //     ['pages', projectStore.project?.id],
            //     [...data, response.data]
            //   );
            // }

            refetchTree();
            setLabel('');
            setParentId(null);
            setCreatePageModalVisible(false);
          }}
          type='primary'
        >
          Create
        </Button>
      </Modal>
    </div>
  );
};

export default ProjectPagesTree;
