import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { useEffect, useState } from 'react';

export default function AddOrCreateTask({
  questId,
  refetch,
}: {
  questId: string;
  refetch: (questId: string) => void;
}) {
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null);
  const getAvailableTasks = async () => {
    const { data } = await api.get(`/api/quests/${questId}/available-tasks`);
    return data;
  };

  const {
    data,
    isLoading,
    refetch: refetchAvailableTasks,
  } = useQuery({
    queryKey: ['availableTasks', questId],
    queryFn: getAvailableTasks,
  });

  const attachTask = async (taskId: string) => {
    try {
      await api.put(`api/tasks/${taskId}`, {
        questId,
      });
      setSelectedTaskId(null);
      await refetchAvailableTasks();
      await refetch(questId);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (selectedTaskId) {
      attachTask(selectedTaskId);
    }
  }, [selectedTaskId]);

  return (
    <>
      <Select
        showSearch
        loading={isLoading}
        style={{ width: '100%' }}
        value={selectedTaskId}
        options={data?.map((task: any) => ({
          ...task,
          value: task.id,
          label: `${task.key} - ${task.label}`,
        }))}
        placeholder='Select existing task'
        optionFilterProp='children'
        onChange={(value) => setSelectedTaskId(value)}
        filterOption={(input, option: any) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
    </>
  );
}
