import {
  Node,
  mergeAttributes,
  NodeViewProps,
  NodeViewRenderer,
} from '@tiptap/core';

const DownloadNode = Node.create({
  name: 'download',

  group: 'inline',

  inline: true,

  selectable: true,

  atom: true,

  addAttributes() {
    return {
      href: {
        default: 'javascript:void(0);',
      },
      filename: {
        default: 'link',
      },
      class: {
        default: 'download-file-link',
      },
      target: {
        default: '_self',
      },
      'data-href': {
        default: null,
      },
      title: {
        default: 'Download file',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'a',
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      'a',
      mergeAttributes({
        href: 'javascript:void(0);',
        class: node.attrs.class,
        target: node.attrs.target,
        'data-href': node.attrs['data-href'],
        filename: node.attrs.filename,
        'data-filename': node.attrs.filename,
        title: 'Download file',
      }),
      node.attrs.filename,
    ];
  },

  addNodeView(): any {
    return ({ node }: NodeViewProps) => {
      const a = document.createElement('a');
      a.textContent = node.attrs.filename;
      a.href = 'javascript:void(0);';
      a.target = '_self';
      a.className = 'download-file-link';
      a.setAttribute('data-filename', node.attrs.filename);
      a.setAttribute('data-href', node.attrs['data-href']);
      a.title = 'Download file';

      return {
        dom: a,
        contentDOM: null,
      };
    };
  },

  addCommands() {
    return {
      setDownload:
        ({ href, filename }: { href: string; filename: string }) =>
        ({ commands }: any) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              href: 'javascript:void(0);',
              class: 'download-file-link',
              target: '_self',
              filename,
              ['data-filename']: filename,
              ['data-href']: href,
              title: 'Download file',
            },
          });
        },
    } as any;
  },
});

export default DownloadNode;
