import { api } from '@/api';
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  TimePicker,
} from 'antd';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import Text from 'antd/es/typography/Text';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { DeleteOutlined } from '@ant-design/icons';

export default function AddTimesheet({
  defaultDate,
  setVisible,
  refetch,
  selectedTask,
  selectedTime,
  onCleanup,
}: {
  defaultDate: string;
  setVisible: any;
  refetch: () => any;
  selectedTask: any;
  selectedTime: any;
  onCleanup: () => any;
}) {
  const getAvailableTasks = async () => {
    const response = await api.get('/api/me/tasks-in-progress');
    return response.data;
  };

  const { data: tasks, isLoading } = useQuery({
    queryKey: ['tasks'],
    queryFn: getAvailableTasks,
  });

  const onFinish = async (values: any) => {
    const date = dayjs(values.date).format('YYYY-MM-DD');
    const hoursInMinutes =
      dayjs(values.hours).hour() * 60 + dayjs(values.hours).minute();
    const taskId =
      typeof values.task !== 'string' ? values.task.id : values.task;

    await api.post('/api/timesheet', {
      taskId,
      date,
      minutes: hoursInMinutes,
      isOvertime: false,
    });
    await refetch();
    setVisible(false);
  };

  const [form] = useForm();

  const nameValue = Form.useWatch('hours', form);

  useEffect(() => {
    if (selectedTask) {
      if (selectedTime) {
        form.setFieldsValue({ hours: dayjs(selectedTime, 'HH:mm') });
      }
      form.setFieldsValue({
        task: {
          value: selectedTask.key,
          label: `${selectedTask.taskKey} - ${selectedTask.task}`,
          id: selectedTask.key,
        },
      });
    }
  }, [selectedTask]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '1rem',
        }}
      >
        <div>
          <Title level={4}>{selectedTask ? 'Update' : 'Add'} timesheet</Title>
        </div>
        <div>
          {selectedTask && (
            <Popconfirm
              title='Are you sure you want to remove selected timesheet?'
              onConfirm={async () => {
                await api.post('/api/timesheet', {
                  taskId: selectedTask.key,
                  date: dayjs(defaultDate).format('YYYY-MM-DD'),
                  minutes: 0,
                  isOvertime: false,
                });
                onCleanup();
                await refetch();
                setVisible(false);
              }}
              okText='Yes'
              cancelText='No'
              okType='danger'
            >
              <DeleteOutlined style={{ color: 'red' }} />
            </Popconfirm>
          )}
        </div>
      </div>

      <br />
      <Form
        layout='vertical'
        onFinish={onFinish}
        form={form}
        initialValues={{ date: dayjs(defaultDate) }}
      >
        <Form.Item
          label='Task'
          name='task'
          rules={[{ required: true, message: 'Please select a task' }]}
        >
          <Select
            placeholder={'Search Tasks'}
            disabled={!!selectedTask}
            style={{ width: '100%' }}
            defaultValue={selectedTask ? selectedTask : undefined}
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            onClear={() => {
              form.setFieldsValue({ task: undefined });
            }}
            allowClear
            options={[
              {
                label: 'Currently in progress',
                options: tasks?.inProgress?.map((task: any) => ({
                  value: task.taskId,
                  label: `${task.taskKey} - ${task.taskLabel}`,
                  id: task.taskId,
                })),
              },
              {
                label: 'All',
                options: tasks?.all?.map((task: any) => ({
                  value: task.taskId,
                  label: `${task.taskKey} - ${task.taskLabel}`,
                  id: task.taskId,
                })),
              },
            ]}
            onSelect={(value) => {
              form.setFieldsValue({ task: value });
            }}
            value={form.getFieldValue('task')?.label}
          />
        </Form.Item>

        <Form.Item
          label='Date'
          name='date'
          rules={[{ required: true, message: 'Please select a date' }]}
        >
          <DatePicker format={'DD.MM.YYYY'} disabled={!!selectedTask} />
        </Form.Item>
        <Form.Item
          label='Hours Worked'
          name='hours'
          rules={[{ required: true, message: 'Please enter hours' }]}
        >
          <TimePicker
            minuteStep={15}
            showSecond={false}
            showNow={false}
            value={form.getFieldValue('hours')}
            minDate={dayjs().hour(1).minute(0)}
            defaultValue={dayjs().hour(1).minute(0)}
            allowClear={false}
            needConfirm={false}
          />
          <br />
          <br />

          <Space>
            <Button
              size='small'
              onClick={() => {
                form.setFieldValue('hours', dayjs().hour(1).minute(0));
              }}
            >
              1h
            </Button>
            <Button
              size='small'
              onClick={() => {
                form.setFieldsValue({ hours: dayjs().hour(2).minute(0) });
              }}
            >
              2h
            </Button>

            <Button
              size='small'
              onClick={() => {
                form.setFieldsValue({ hours: dayjs().hour(3).minute(0) });
              }}
            >
              3h
            </Button>

            <Button
              size='small'
              onClick={() => {
                form.setFieldsValue({ hours: dayjs().hour(4).minute(0) });
              }}
            >
              4h
            </Button>

            <Button
              size='small'
              onClick={() => {
                form.setFieldsValue({ hours: dayjs().hour(5).minute(0) });
              }}
            >
              5h
            </Button>

            <Button
              size='small'
              onClick={() => {
                form.setFieldsValue({ hours: dayjs().hour(6).minute(0) });
              }}
            >
              6h
            </Button>

            <Button
              size='small'
              onClick={() => {
                form.setFieldsValue({ hours: dayjs().hour(7).minute(0) });
              }}
            >
              7h
            </Button>

            <Button
              size='small'
              onClick={() => {
                form.setFieldsValue({ hours: dayjs().hour(8).minute(0) });
              }}
            >
              8h
            </Button>
          </Space>
        </Form.Item>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}
        >
          <Button
            onClick={() => {
              onCleanup();
              setVisible(false);
            }}
          >
            Cancel
          </Button>
          <Button type='primary' htmlType='submit'>
            Add
          </Button>
        </div>
      </Form>
    </div>
  );
}
