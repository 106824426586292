import { create } from 'zustand';

interface Project {
  id: string;
  label: string;
  key: string;
}

interface ProjectStore {
  project: Project | null;
  hasProjects: boolean;
  setProject: (project: Project) => void;
  setHasProject: (hasProjects: boolean) => void;
}

export const useProjectStore = create<ProjectStore>()((set) => ({
  project: null,
  hasProjects: true,
  setProject: (selectedProject: Project) =>
    set(() => ({ project: selectedProject })),
  setHasProject: (hasProjects: boolean) =>
    set(() => ({ hasProjects: hasProjects })),
}));
