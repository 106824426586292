import { create } from 'zustand';

interface Board {
  id: string;
  label: string;
  description: string;
  key: number;
}

interface BoardStore {
  board: Board | null;
  setBoard: (board: Board | null) => void;
}

export const useBoardStore = create<BoardStore>()((set) => ({
  board: null,
  setBoard: (selectedBoard: Board | null) =>
    set(() => ({ board: selectedBoard })),
}));
