import useMessage from 'antd/es/message/useMessage';
import { api } from '../../../../api';
import {
  Button,
  Input,
  Modal,
  Popconfirm,
  Select,
  Tree,
  TreeDataNode,
} from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';

import { useEffect, useState } from 'react';

export default function DepartmentsTab() {
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [manageUsersModal, setManageUsersModal] = useState(false);
  const [manageGroupsModal, setManageGroupsModal] = useState(false);

  const [messageApi, contextHolder] = useMessage();

  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState<any[]>([]);

  const [newGroupName, setNewGroupName] = useState('');

  const [data, setData] = useState<any[]>([]);

  async function getDepartments() {
    const { data } = await api.get('/api/hierarchy');
    setData(data);
  }

  async function getUsers() {
    const { data } = await api.get('/api/users');

    setAllUsers(
      data.map((user: any) => ({
        label: user.fullName,
        value: user.id,
      }))
    );
  }

  useEffect(() => {
    getDepartments();
    getUsers();
  }, []);

  return (
    <div>
      <Text>
        Departments are used to quickly assign project access to multiple users
        at once.
      </Text>
      <div style={{ marginBottom: '.5rem' }}></div>
      <div style={{ display: 'flex', gap: '1rem' }}>
        {data.length === 0 && (
          <div>
            <Text>No departments </Text>
          </div>
        )}
        {data.length > 0 && (
          <Tree
            style={{ padding: '.5rem', minWidth: '12vw' }}
            treeData={data}
            onSelect={async (keys, info) => {
              if (info.selected) {
                setSelectedNode(info.node);
                setSelectedUsers(
                  info.node.children
                    .filter((n: any) => n.isUser)
                    .map((n: any) => n.id)
                );
                return;
              }
              setSelectedNode(null);
              setSelectedUsers([]);
            }}
          />
        )}
        {selectedNode && (
          <div>
            <Title
              editable={
                !selectedNode.isUser && {
                  async onChange(value) {
                    await api.put(`/api/hierarchy/${selectedNode.id}`, {
                      label: value,
                    });
                    setSelectedNode({ ...selectedNode, title: value });
                    messageApi.success('Department name updated');
                    getDepartments();
                  },
                }
              }
              level={4}
            >
              {selectedNode.title}
            </Title>
            <div style={{ display: 'flex', gap: '1rem' }}>
              {selectedNode.isUser && (
                <Popconfirm
                  title={`Remove "${selectedNode.title}"?`}
                  okButtonProps={{ danger: true }}
                  description={`Are you sure?\nThis action is NOT reversible`}
                  onConfirm={async () => {
                    await api.delete(
                      `/api/hierarchy/${selectedNode.hierarchyId}/users/${selectedNode.id}`
                    );
                    await getDepartments();
                    messageApi.success('User removed');
                    setSelectedNode(null);
                  }}
                  okText='Remove'
                  cancelText='No'
                >
                  <Button danger type='primary' size='small'>
                    Remove from group
                  </Button>
                </Popconfirm>
              )}
              {!selectedNode.isUser && (
                <>
                  <Popconfirm
                    title={`Delete ${selectedNode.title} group?`}
                    okButtonProps={{ danger: true }}
                    description={`Are you sure?\nThis action is NOT reversible`}
                    onConfirm={async () => {
                      await api.delete(`/api/hierarchy/${selectedNode.id}`);
                      await getDepartments();
                      setSelectedNode(null);
                      messageApi.success('Group deleted');
                    }}
                    okText='Delete'
                    cancelText='No'
                  >
                    <Button danger type='primary' size='small'>
                      Delete
                    </Button>
                  </Popconfirm>

                  <Button
                    type='primary'
                    size='small'
                    onClick={() => {
                      setManageUsersModal(true);
                    }}
                  >
                    Manage users
                  </Button>
                </>
              )}
              {selectedNode.pos.length <= 5 && !selectedNode.isUser && (
                <Button
                  type='primary'
                  size='small'
                  onClick={() => {
                    setNewGroupName('');
                    setManageGroupsModal(true);
                  }}
                >
                  + Add group
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: '1rem' }}></div>
      <Button
        onClick={() => {
          setSelectedNode(null);
          setManageGroupsModal(true);
        }}
        type='primary'
      >
        + Add department
      </Button>
      {selectedNode && (
        <>
          <Modal
            open={manageUsersModal}
            style={{top: 20}}
            okText='Assign'
            onOk={async () => {
              await api.post(`/api/hierarchy/${selectedNode.id}/users`, {
                users: selectedUsers,
              });
              messageApi.success('Users assigned');
              await getDepartments();
              setManageUsersModal(false);
            }}
            onCancel={() => setManageUsersModal(false)}
          >
            <div>
              <h3>Assign users to "{selectedNode.title}"</h3>
              <Select
                mode='multiple'
                style={{ width: '100%' }}
                value={selectedUsers}
                placeholder='Select users'
                onChange={(value) => {
                  setSelectedUsers(value);
                }}
                options={allUsers}
              />
            </div>
          </Modal>
        </>
      )}
      <Modal
        open={manageGroupsModal}
        style={{top: 20}}
        onCancel={() => setManageGroupsModal(false)}
        onOk={async () => {
          // Make a request to the backend to create a new group
          // with the name newGroupName

          if (selectedNode) {
            await api.post('/api/hierarchy', {
              label: newGroupName,
              parentId: selectedNode.id,
            });

            await getDepartments();
            messageApi.success('Group created');
          } else {
            const response = await api.post('/api/hierarchy', {
              label: newGroupName,
              parentId: null,
            });
            messageApi.success('Department created');
            // Then, update the data
            getDepartments();
          }

          setNewGroupName('');
          setManageGroupsModal(false);
        }}
      >
        <div>
          {selectedNode && <h3>Add group to "{selectedNode.title}"</h3>}
          {!selectedNode && <h3>Create department</h3>}

          <Input
            placeholder='Name'
            required
            minLength={2}
            value={newGroupName}
            onChange={(e) => {
              setNewGroupName(e.target.value);
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
