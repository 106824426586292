import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { Button, Calendar, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import SpecificAdminTimesheet from './specificTimesheet';

export default function AdminTimesheets() {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [showSpecificModal, setShowSpecificModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState('');
  const [selectedUserStart, setSelectedUserStart] = useState('');
  const [selectedUserEnd, setSelectedUserEnd] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');

  // remove weekends
  const getMonthTarget = () => {
    // from current month get weekdays and calculate this month's hours
    const weekdays = [];
    const month = startDate.month();
    const year = startDate.year();
    const daysInMonth = startDate.daysInMonth();
    for (let i = 1; i <= daysInMonth; i++) {
      const date = dayjs(`${year}-${month + 1}-${i}`);
      if (date.day() !== 0 && date.day() !== 6) {
        weekdays.push(date);
      }
    }
    const hours = weekdays.length * 8;

    return hours;
  };

  async function getTimesheets() {
    const response = await api.get(
      `/api/admin/timesheets?startDate=${startDate
        .startOf('month')
        .format('YYYY-MM-DD')}&endDate=${endDate
        .endOf('month')
        .format('YYYY-MM-DD')}`
    );
    return response.data;
  }

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['timesheets', 'admin', startDate, endDate],
    queryFn: getTimesheets,
  });

  const nextMonth = async () => {
    resetSpecific();
    setStartDate(startDate.add(1, 'month'));
    setEndDate(endDate.add(1, 'month'));
    await refetch();
  };

  const prevMonth = async () => {
    resetSpecific();
    setStartDate(startDate.subtract(1, 'month'));
    setEndDate(endDate.subtract(1, 'month'));
    await refetch();
  };

  const resetSpecific = () => {
    setShowSpecificModal(false);
    setSelectedUser('');
    setSelectedUserStart('');
    setSelectedUserEnd('');
    setSelectedUserName('');
  };

  const columns = [
    {
      title: 'User',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Hours ',
      dataIndex: 'hoursWorked',
      key: 'hoursWorked',
      render: (value: string) =>
        `${value?.endsWith(':0h') ? value.replace('0h', '00h') : value}`,
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          alignContent: 'center',
        }}
      >
        <Button onClick={() => prevMonth()}>Previous Month</Button>

        <Title level={4} style={{ marginTop: '.5rem' }}>
          {startDate.startOf('month').format('DD MMM YYYY')} -{' '}
          {endDate.endOf('month').format('DD MMM YYYY')}{' '}
        </Title>
        <Button onClick={() => nextMonth()}>Next Month</Button>
      </div>
      <Title level={5}>Month workable Hours: {getMonthTarget()}h</Title>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div style={{ flex: 1 }}>
          <Table
            dataSource={data}
            columns={columns}
            rowKey='id'
            loading={isLoading}
            pagination={false}
            onRow={(record: any) => {
              return {
                onClick: () => {
                  setShowSpecificModal(true);
                  setSelectedUser(record.userId);
                  setSelectedUserName(record.fullName);
                  setSelectedUserStart(
                    startDate.startOf('month').format('YYYY-MM-DD')
                  );
                  setSelectedUserEnd(
                    endDate.endOf('month').format('YYYY-MM-DD')
                  );
                },
              };
            }}
          />
        </div>
        <div style={{ flex: 2 }}>
          {showSpecificModal && (
            <SpecificAdminTimesheet
              userId={selectedUser}
              start={selectedUserStart}
              end={selectedUserEnd}
              selectedUserName={selectedUserName}
            />
          )}
        </div>
      </div>
    </div>
  );
}
