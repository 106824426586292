import { api } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { Collapse, CollapseProps, Select, Skeleton, Tag, Timeline } from 'antd';

import Text from 'antd/es/typography/Text';
import { ArrowRightOutlined } from '@ant-design/icons';
import { generateHTML } from '@tiptap/html';
import Editor, { getExtensions } from '@/components/Editor/index3';
import { useState } from 'react';
import { RelativeTime } from '@/components/RelativeTime';

export default function TaskActivity({
  taskId,
  projectKey,
}: {
  taskId: string;
  projectKey: string;
}) {
  const [reverse, setReverse] = useState(false);
  const [byField, setByField] = useState('none');

  const fetchTaskActivity = async () => {
    const { data } = await api.get(`/api/tasks/${taskId}/activity`);

    return data;
  };

  const { data, isLoading } = useQuery({
    queryKey: ['taskActivity', taskId],
    queryFn: fetchTaskActivity,
  });

  if (isLoading) return <Skeleton active />;

  const getFieldChangeTag = (
    field:
      | 'label'
      | 'description'
      | 'type'
      | 'priority'
      | 'assignee'
      | 'status'
      | 'quest',
    from: any,
    to: any
  ) => {
    switch (field) {
      case 'label':
        return (
          <div>
            <Text strong>{from}</Text> <ArrowRightOutlined size={15} />{' '}
            <Text strong>{to}</Text>
          </div>
        );
      case 'description': {
        const items: CollapseProps['items'] = [
          {
            key: '1',
            label: 'Show Changes',
            children: (
              <div style={{ display: 'flex', gap: '.3rem' }}>
                <div
                  style={{
                    border: '1px solid gray',
                    borderRadius: 8,
                    padding: 5,
                    minWidth: '45%',
                  }}
                >
                  <Editor
                    defaultContent={from}
                    editable={false}
                    projectKey={projectKey}
                  />
                </div>
                <ArrowRightOutlined size={15} />{' '}
                <div
                  style={{
                    border: '1px solid gray',
                    borderRadius: 8,
                    padding: 5,
                    minWidth: '45%',
                  }}
                >
                  <Editor
                    defaultContent={to}
                    editable={false}
                    projectKey={projectKey}
                  />
                </div>
              </div>
            ),
          },
        ];

        return (
          <Collapse
            size='small'
            items={items}
            style={{ marginTop: '.5rem' }}
            bordered={false}
          />
        );
      }
      default:
        return (
          <div>
            <Tag color='gray' style={{ marginRight: 3 }} bordered={false}>
              {from}
            </Tag>
            <ArrowRightOutlined size={20} />
            <Tag color='blue' style={{ marginLeft: 3 }} bordered={false}>
              {to}
            </Tag>
          </div>
        );
    }
  };

  const items = data
    .filter((item: any) => byField === 'none' || item.field === byField)
    .map((item: any) => {
      return {
        children: (
          <div>
            <Text>
              <strong>{item.userName}</strong> {item.action}{' '}
              {item.field && (
                <Tag>
                  <Text>{item.field}</Text>
                </Tag>
              )}
            </Text>
            <br />
            {item.action !== 'created' && (
              <>
                {item.from && item.to && (
                  <div style={{ margin: '.3rem 0' }}>
                    {getFieldChangeTag(item.field, item.from, item.to)}
                  </div>
                )}
              </>
            )}
            <Text type='secondary'>
              <RelativeTime date={item.createdAt} showHoursAndMinutes />
            </Text>
          </div>
        ),
      };
    });

  return (
    <div>
      <div style={{ display: 'flex', gap: '1rem' }}>
        <div style={{ marginBottom: '1rem' }}>
          <Text>Sort by</Text>{' '}
          <Select
            style={{ width: 120 }}
            value={reverse ? 'Oldest' : 'Newest'}
            size='small'
            onChange={(value) => setReverse(value === 'Oldest')}
            options={[
              { value: 'Oldest', label: 'Oldest' },
              { value: 'Newest', label: 'Newest' },
            ]}
          />
        </div>
        <div style={{ marginBottom: '1rem' }}>
          <Text>Filter by</Text>{' '}
          <Select
            style={{ width: 120 }}
            value={byField}
            size='small'
            onChange={(value) => setByField(value)}
            options={[
              { value: 'none', label: 'none' },
              { value: 'label', label: 'label' },
              { value: 'description', label: 'description' },
              { value: 'type', label: 'type' },
              { value: 'priority', label: 'priority' },
              { value: 'assignee', label: 'assignee' },
              { value: 'status', label: 'status' },
              { value: 'quest', label: 'quest' },
            ]}
          />
        </div>
      </div>
      <Timeline reverse={reverse} mode='left' items={items} />
    </div>
  );
}
