import React from 'react';
import type { FormProps } from 'antd';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { api } from '../../../api';
import { useQueryClient } from '@tanstack/react-query';

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

const BasicInfo: React.FC<any> = ({ fullName }: { fullName: string }) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const queryClient = useQueryClient();

  const onFinish: FormProps['onFinish'] = async (values) => {
    try {
      await api.put('/api/me', { fullName: values.fullName });
      messageApi.success('Full Name updated successfully');
      queryClient.setQueryData(['me'], { fullName: values.fullName });
    } catch (error) {
      messageApi.error('Failed to update Full Name');
    }
  };

  return (
    <div>
      {contextHolder}
      <Form
        name='basic'
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        layout='vertical'
        autoComplete='off'
      >
        <Form.Item
          label='Full Name'
          name='fullName'
          initialValue={fullName}
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit'>
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default BasicInfo;
