import * as React from 'react';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api';
import { Button, ConfigProvider, Input, Space, theme } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

export default function Login() {
  // const userStore = useUserStore();
  const navigate = useNavigate();

  const [loginInProgress, setLoginInProgress] = React.useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoginInProgress(true);
    const data = new FormData(event.currentTarget);

    const email = data.get('email') as string;
    const password = data.get('password') as string;

    try {
      const token = await api.post(
        '/api/login',
        { email, password },
        { withCredentials: true }
      );

      localStorage.setItem('token', token.data);
      return navigate('/');
    } catch (error: any) {
      messageApi.error(error.response.data.message);
      console.log(error, error.response);
    }
    setLoginInProgress(false);
  };

  const savedTheme = localStorage.getItem('theme');

  let themeToSelect = theme.defaultAlgorithm;

  switch (savedTheme) {
    case 'dark': {
      themeToSelect = theme.darkAlgorithm;
      break;
    }
    default: {
      themeToSelect = theme.defaultAlgorithm;
    }
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: [theme.defaultAlgorithm],
      }}
    >
      {contextHolder}
      <Space
        style={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Title level={1}>Taskiro</Title>

        <Title level={3}>Log in</Title>
        <form onSubmit={handleSubmit}>
          <Input name='email' required placeholder='Email' />
          <div style={{ marginTop: '1rem' }}></div>

          <Input.Password
            name='password'
            required
            placeholder='Password'
            type='password'
          />

          <div style={{ marginTop: '1rem' }}></div>

          <div style={{ textAlign: 'center' }}>
            <Button
              loading={loginInProgress}
              htmlType='submit'
              type='primary'
              disabled={loginInProgress}
            >
              Log In
            </Button>

            <div style={{ marginTop: '1rem' }}></div>
            {/* <div>
              <Text>Not a member?</Text>
              <Link to='/register'>
                <Button disabled={loginInProgress} type='link'>
                  Register
                </Button>
              </Link>
            </div> */}
            {/* <div style={{ marginTop: '1rem' }}></div> */}
            <div>
              <Text>Forgot password?</Text>
              <Button disabled={loginInProgress} href='#' type='link'>
                Reset
              </Button>
            </div>
          </div>
        </form>
      </Space>
    </ConfigProvider>
  );
}
