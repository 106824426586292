import { Collapse, Tag } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

const levels: any = [
  {
    label: 'Novice',
    levels: {
      1: { from: 0, to: 100 },
      2: { from: 100, to: 206 },
      3: { from: 206, to: 318 },
      4: { from: 318, to: 437 },
      5: { from: 437, to: 563 },
      6: { from: 563, to: 697 },
      7: { from: 697, to: 839 },
      8: { from: 839, to: 989 },
      9: { from: 989, to: 1148 },
      10: { from: 1148, to: 1317 },
    },
  },
  {
    label: 'Apprentice',
    levels: {
      1: { from: 1317, to: 1496 },
      2: { from: 1496, to: 1686 },
      3: { from: 1686, to: 1887 },
      4: { from: 1887, to: 2100 },
      5: { from: 2100, to: 2326 },
      6: { from: 2326, to: 2566 },
      7: { from: 2566, to: 2820 },
      8: { from: 2820, to: 3089 },
      9: { from: 3089, to: 3374 },
      10: { from: 3374, to: 3676 },
    },
  },
  {
    label: 'Journeyman',
    levels: {
      1: { from: 3676, to: 3997 },
      2: { from: 3997, to: 4337 },
      3: { from: 4337, to: 4697 },
      4: { from: 4697, to: 5079 },
      5: { from: 5079, to: 5484 },
      6: { from: 5484, to: 5913 },
      7: { from: 5913, to: 6368 },
      8: { from: 6368, to: 6850 },
      9: { from: 6850, to: 7361 },
      10: { from: 7361, to: 7903 },
    },
  },
  {
    label: 'Specialist',
    levels: {
      1: { from: 7903, to: 8477 },
      2: { from: 8477, to: 9066 },
      3: { from: 9066, to: 9731 },
      4: { from: 9731, to: 10415 },
      5: { from: 10415, to: 11140 },
      6: { from: 11140, to: 11908 },
      7: { from: 11908, to: 12722 },
      8: { from: 12722, to: 13585 },
      9: { from: 13585, to: 14500 },
      10: { from: 14500, to: 15470 },
    },
  },
  {
    label: 'Expert',
    levels: {
      1: { from: 15470, to: 16498 },
      2: { from: 16498, to: 17588 },
      3: { from: 17588, to: 18743 },
      4: { from: 18743, to: 19968 },
      5: { from: 19968, to: 21266 },
      6: { from: 21266, to: 22642 },
      7: { from: 22642, to: 24101 },
      8: { from: 24101, to: 25647 },
      9: { from: 25647, to: 27286 },
      10: { from: 27286, to: 29023 },
    },
  },
  {
    label: 'Master',
    levels: {
      1: { from: 29023, to: 30864 },
      2: { from: 30864, to: 32816 },
      3: { from: 32816, to: 34885 },
      4: { from: 34885, to: 37078 },
      5: { from: 37078, to: 39403 },
      6: { from: 39403, to: 41867 },
      7: { from: 41867, to: 44479 },
      8: { from: 44479, to: 47248 },
      9: { from: 47248, to: 50183 },
      10: { from: 50183, to: 53294 },
    },
  },
  {
    label: 'Grandmaster',
    levels: {
      1: { from: 53294, to: 56592 },
      2: { from: 56592, to: 60088 },
      3: { from: 60088, to: 63793 },
      4: { from: 63793, to: 67721 },
      5: { from: 67721, to: 71884 },
      6: { from: 71884, to: 76297 },
      7: { from: 76297, to: 80975 },
      8: { from: 80975, to: 85934 },
      9: { from: 85934, to: 91190 },
      10: { from: 91190, to: 96761 },
    },
  },
  {
    label: 'Legend',
    levels: {
      1: { from: 96761, to: 102667 },
      2: { from: 102667, to: 108927 },
      3: { from: 108927, to: 115563 },
      4: { from: 115563, to: 122597 },
      5: { from: 122597, to: 130053 },
      6: { from: 130053, to: 137956 },
      7: { from: 137956, to: 146333 },
      8: { from: 146333, to: 155213 },
      9: { from: 155213, to: 164626 },
      10: { from: 164626, to: 174604 },
    },
  },
  {
    label: 'Ascendant',
    levels: {
      1: { from: 174604, to: 185180 },
      2: { from: 185180, to: 196391 },
      3: { from: 196391, to: 208274 },
      4: { from: 208274, to: 220870 },
      5: { from: 220870, to: 234222 },
      6: { from: 234222, to: 248375 },
      7: { from: 248375, to: 263378 },
      8: { from: 263378, to: 279281 },
      9: { from: 279281, to: 296138 },
      10: { from: 296138, to: 314006 },
    },
  },
];

export default function LevelMap() {
  return (
    <div>
      <Title level={3}>Level map</Title>
      <Text>
        This is a map of all the levels and their experience requirements
      </Text>
      <br />
      <br />
      {levels.map((level: any) => (
        <div style={{ marginBottom: '1rem' }} key={level.label}>
          <Collapse
            items={[
              {
                key: level.label,
                label: `${level.label}`,
                children: (
                  <div>
                    {Object.keys(level.levels).map((key) => (
                      <div style={{ marginBottom: '.5rem' }} key={key}>
                        <Tag>
                          {level.label} {key}
                        </Tag>
                        {new Intl.NumberFormat().format(level.levels[key].from)}{' '}
                        - {new Intl.NumberFormat().format(level.levels[key].to)}{' '}
                        XP
                      </div>
                    ))}
                    <br />
                    <Text>
                      Level up after{' '}
                      {new Intl.NumberFormat().format(
                        Math.round(level.levels[10].to / 50)
                      )}{' '}
                      tasks completed (50XP per task)
                    </Text>
                  </div>
                ),
              },
            ]}
          />
        </div>
      ))}
    </div>
  );
}
