import { api } from '../api';
import { useProjectStore } from '../state/projectState';
import {
  Button,
  Collapse,
  CollapseProps,
  Input,
  Modal,
  Popconfirm,
} from 'antd';
import Text from 'antd/es/typography/Text';
import { useState } from 'react';

export default function CreateBoardModal({
  board,
  open,
  handleOk,
  handleCancel,
}: {
  board: any;
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
}) {
  const [label, setLabel] = useState(board?.label || '');
  const [description, setDescription] = useState(board?.description || '');
  const project = useProjectStore((state) => state.project);

  const createBoard = async () => {
    if (!project) return;

    if (board) {
      await api.put(`/api/boards/${board.id}`, {
        label,
        description,
      });
      handleOk();
      return;
    }

    await api.post(`/api/projects/${project.id}/boards`, {
      label,
      description,
    });
    setLabel('');
    setDescription('');
    handleOk();
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'More Settings',
      children: (
        <div>
          <div>
            <Popconfirm
              title='Are you sure?'
              okText='Yes'
              okButtonProps={{ type: 'primary', danger: true }}
              onConfirm={async () => {
                await api.delete(`/api/boards/${board.id}`);
                handleOk();
              }}
            >
              <Button type='primary' danger>
                Delete board
              </Button>
            </Popconfirm>
          </div>
          <Text type='danger'>*This action is not reversible*</Text>
        </div>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      style={{top: 20}}
      title={`${board ? 'Edit' : 'Create'} Board`}
      onOk={() => createBoard()}
      okText={board ? 'Save' : 'Create'}
      onCancel={handleCancel}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      )}
    >
      <Text>Label</Text>
      <Input value={label} onChange={(e) => setLabel(e.target.value)} />
      <div style={{ marginTop: '1rem' }}></div>
      <Text>Description</Text>
      <Input
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      {board && (
        <div style={{ marginTop: '1rem' }}>
          <Collapse size='small' items={items} />
        </div>
      )}
    </Modal>
  );
}
