import { useNavigate, useParams } from 'react-router-dom';
import Board from './Board';
import { useEffect } from 'react';
import { useProjectStore } from '@/state/projectState';
import { Card } from 'antd';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

export function BoardWrapper() {
  const { projectKey, boardKey, taskKey } = useParams();

  const projectStore = useProjectStore();

  if (!projectStore.hasProjects) {
    return (
      <div>
        <Card>
          <Title level={3}>Welcome to Questiro</Title>
          <Title level={4}>
            Create a project to start working on something great
          </Title>
          <Text type='secondary'>
            Click on the + button to create a project
          </Text>
        </Card>
      </div>
    );
  }

  // if (!boardKey) {
  //   return <div></div>;
  // }

  return <Board />;
}
