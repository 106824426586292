import { Tabs, TabsProps } from 'antd';
import ProjectOverview_List from './List';
import Activity from './Activity';
import Summary from './Summary';
import { CustomIcon } from '@/components/Icon';

export default function ProjectOverview() {
  const tabs: TabsProps['items'] = [
    // {
    //   label: 'Summary',
    //   children: <Summary />,
    //   key: '1',
    // },
    {
      label: 'Task List',
      children: <ProjectOverview_List />,
      key: '2',
    },
    {
      label: 'Activity',
      children: <Activity />,
      key: '3',
    },
  ];

  return <Tabs defaultActiveKey='1' items={tabs} />;
}
