import { api } from '@/api';
import Editor from '@/components/Editor/index3';
import { ArrowRightOutlined, SyncOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import RelativeTime from 'react-relative-time';
import {
  Badge,
  Button,
  Card,
  Collapse,
  CollapseProps,
  List,
  Skeleton,
  Tag,
  Timeline,
} from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { Link } from 'react-router-dom';

export const Created = ({ projectId }: { projectId: string }) => {
  const getCreated = async () => {
    const response = await api.get(
      `/api/projects/${projectId}/overview/created`
    );
    return response.data;
  };

  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['created', projectId],
    queryFn: getCreated,
  });

  return (
    <Card size='small'>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text>
          Created in last 7 days{' '}
          <Tag bordered={false} color='blue'>
            {data?.length}
          </Tag>
        </Text>
        <Button
          onClick={() => {
            refetch();
          }}
          title='Refresh'
          size='small'
        >
          <SyncOutlined />
        </Button>
      </div>
      <div
        style={{
          height: '40vh',
          overflow: 'auto',
          padding: '5px 0',
        }}
      >
        <List
          dataSource={data}
          loading={isFetching || isLoading}
          renderItem={(item: any) => (
            <p style={{ marginBottom: '1.3rem' }}>
              <Link to={`?openTask=${item.key}`}>
                {`[${item.key}]`} {item.label}
              </Link>
              <br />
              <Tag bordered={false}>{item.boardName}</Tag>
              <Tag bordered={false}>{item.columnName}</Tag>
            </p>
          )}
        />
      </div>
    </Card>
  );
};
