import React from 'react';
import { Input, Select, Skeleton, Switch, Tree } from 'antd';
import { useProjectStore } from '@/state/projectState';
import { useQuery } from '@tanstack/react-query';

import type { TreeProps } from 'antd';
import { api } from '@/api';

const AccessTree = ({ onSelected, userIds }: any) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [type, setType] = React.useState<'tree' | 'dropdown'>('tree');
  const projectStore = useProjectStore();

  async function getDepartments() {
    const { data } = await api.get('/api/hierarchy');
    return data;
  }
  async function getProjectUsers() {
    const { data } = await api.get(`/api/users`);

    return data;
  }

  const { data: treeData, isLoading: isLoadingTreeData } = useQuery({
    queryKey: ['hierarchy', projectStore.project?.key],
    queryFn: getDepartments,
  });

  const { data: usersData, isLoading: isLoadingUsersData } = useQuery({
    queryKey: ['projectUsers', projectStore.project?.key],
    queryFn: getProjectUsers,
  });

  const [checkedPeople, setCheckedPeople] = React.useState<any[]>([]);

  if (isLoadingTreeData || isLoadingUsersData) return <Skeleton />;

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {};

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    onSelected(
      Array.from(
        new Set(
          info.checkedNodes
            .filter((n: any) => n.isUser)
            .map((node: any) => node.id)
        )
      )
    );

    setCheckedPeople(
      info.checkedNodes.filter((n: any) => n.isUser).map((node) => node.title)
    );
  };

  const filterTreeData = (data: any, term: string) => {
    return data?.map((node: any) => {
      if (node.children) {
        const filteredChildren = filterTreeData(node.children, term);
        if (filteredChildren.length > 0) {
          if (
            filteredChildren.filter(
              (child: any) => child.style && child.style.display === 'none'
            ).length === filteredChildren.length
          ) {
            return {
              ...node,
              children: filteredChildren,
              style: { display: 'none' },
            };
          }
          return { ...node, children: filteredChildren };
        }
      }
      if (node.title.toLowerCase().includes(term.toLowerCase())) {
        return node;
      }
      return { ...node, style: { display: 'none' } };
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {type === 'tree' && (
          <div>
            <Input
              placeholder='Search for a person'
              allowClear
              onChange={(e: any) => {
                setSearchTerm(e.target.value);
              }}
            />
          </div>
        )}
        {type === 'dropdown' && <div></div>}
        <div>
          <Switch
            checkedChildren='Dropdown'
            unCheckedChildren='Tree'
            onChange={(checked) => {
              setType(checked ? 'dropdown' : 'tree');
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: '.5rem' }}></div>
      {type === 'tree' && (
        <Tree
          checkable
          style={{ padding: '8px' }}
          defaultCheckedKeys={userIds}
          onSelect={onSelect}
          onCheck={onCheck}
          treeData={filterTreeData(treeData, searchTerm)}
        />
      )}
      {type === 'dropdown' && (
        <Select
          mode='multiple'
          style={{ width: '100%' }}
          placeholder='Select users'
          defaultValue={userIds}
          options={usersData.map((user: any) => ({
            value: user.id,
            label: user.fullName,
          }))}
          onChange={(values) => {
            onSelected(values);
            setCheckedPeople(
              usersData
                .filter((user: any) => values.includes(user.id))
                .map((user: any) => user.fullName)
            );
          }}
        />
      )}
      {checkedPeople.length > 0 && (
        <div>
          <h3>People with access to this project: {checkedPeople.length}</h3>
          <ul>
            {checkedPeople.map((person) => (
              <li key={person}>{person}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccessTree;
