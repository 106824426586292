import TaskDetailsMain from '@/components/TaskDetailsMain';
import { Button, Result } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';

export default function TaskPage() {
  const { taskKey } = useParams();

  if (!taskKey) {
    return (
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the task you visited does not exist.'
        extra={
          <Link to='../'>
            <Button size='small' type='primary'>
              Go Back
            </Button>
          </Link>
        }
      />
    );
  }

  return (
    <>
      <TaskDetailsMain
        onUpdateTask={() => {}}
        taskKey={taskKey}
        key={taskKey}
      />
    </>
  );
}
