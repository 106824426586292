import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useState } from 'react';

dayjs.extend(relativeTime);

export function RelativeTime({
  date,
  showHoursAndMinutes,
}: {
  date: string;
  showHoursAndMinutes?: boolean;
}) {
  const [showDate, setShowDate] = useState(false);

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    // if date diff is still in minutes, update every second
    if (dayjs().diff(dayjs(date), 'minutes') < 60) {
      const interval = setInterval(() => setTime(Date.now()), 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  return (
    <span
      style={{ cursor: 'pointer' }}
      title={dayjs(new Date(date)).format('DD MMM YYYY HH:mm')}
      onClick={(e) => {
        e.stopPropagation();
        setShowDate(!showDate);
      }}
    >
      {showDate
        ? dayjs(new Date(date)).format(
            `DD MMM YYYY ${showHoursAndMinutes ? 'HH:mm' : ''}`
          )
        : dayjs(date).fromNow()}
    </span>
  );
}
